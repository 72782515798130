import React from "react";
import { Box, Image, Button, Text } from 'grommet'
import { Link } from "react-router-dom";
import Interweave from 'interweave'
import { getPalette } from 'react-palette'
import * as firebase from 'firebase/app'
import 'firebase/firestore'

class RoomCard extends React.Component<any,
    {
        loading:boolean, roomID:string, room:{
            active?:boolean,appFee?:string,biddingSteps?:number[],closingMessage?:string,
            coverImage?:string,currency?:string,description?:string,endDate?:string,
            isAuto?:boolean,isClosed?:boolean,isConfig?:boolean,isFree?:boolean,
            isInverted?:boolean,name?:string,organisations?:string[],roomTotal?:number,
            roomVisibility?:string,shortName?:string,sponsor?:string[],startDate?:string,
            type?:string,visibleWhenClosed?:boolean,uid?:string
        },
        palette:{darkMuted?:string, darkVibrant?:string,lightMuted?:string,lightVibrant?:string,muted?:string,vibrant?:string}
    }
> {
    roomID:string | undefined;

    constructor(props:any) {
        super(props)

        this.state = {
            loading:true,
            roomID:'',
            room:{},
            palette:{}
        }        
    }

    componentDidMount() {
        if (!!this.props.room) this.setState({...this.state, roomID:this.props.room})
        firebase.firestore().collection('rooms').doc(this.roomID)
        .onSnapshot((docRef:any) => {
            if (!!docRef.exists) {
                this.setState({...this.state, room:{...docRef.data()}, loading:false})

                getPalette(this.state.room.coverImage!)
                    .then((palette) => {
                        this.setState({...this.state, palette})
                    });
            }
        })
    }

    private unsubscribe = () => {
        firebase.firestore().collection('rooms')
            .doc(this.roomID)
            .onSnapshot(() => {})
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    render(){
        const { loading, room } = this.state;
        const { coverImage } = this.state.room
        return (
            <>
            {
                loading && <>Loading Room Card</>
            }
            {
                !loading &&
                    <Box direction="column"
                        round
                        overflow='hidden'
                        width='250px'
                        background={this.state.palette.lightVibrant}
                        style={{
                            background:`linear-gradient(-45deg, ${this.state.palette.vibrant}, ${this.state.palette.lightVibrant})`,
                            filter: `drop-shadow(0 0 0.125rem ${this.state.palette.vibrant})`
                        }}
                        margin={{bottom:'small'}}
                    >
                        <Box style={{position:'relative'}}>
                            <Image src={coverImage} fit='cover' style={{maxHeight:'150px'}} />
                            <Box style={{position:'absolute', bottom:'0', left:'50%', transform:'translate(-50%,50%)', filter: `drop-shadow(0 0 0.125rem ${this.state.palette.vibrant})`}} round='xlarge' background='light-1' border={{color:'light-1', size:'medium'}}>
                                <Image src={coverImage}/>
                            </Box>
                        </Box>
                        <Box margin='medium'></Box>
                        <Box pad='small'>
                            <Text size='large' weight='bold' alignSelf='center' textAlign='center'>{room.name}</Text>
                        </Box>
                        <Box pad="small"><Interweave content={room.description} /></Box>
                        <Box pad='small' align='center' alignContent='center'>
                            <Link to={`/room/${room.shortName}`} className="ui card">
                                <Button 
                                    color={this.state.palette.vibrant}
                                    primary 
                                    label='Go to Room'
                                />
                            </Link>
                        </Box>
                    </Box>
            }
            </>
        );
    }
}

export default RoomCard