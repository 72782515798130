import React from "react";
import { Button } from 'grommet'

import * as ROUTES from '../../constants/routes';
import { withRouter } from "react-router-dom";

import * as firebase from 'firebase/app'
import  'firebase/auth'

class SignOutButton extends React.Component<any> {

    private signOut = () => {
        firebase.auth().signOut()
        this.props.history.push(ROUTES.LANDING);
    }

    render () {
        return (<Button primary color='white' label='Log out' type="button" onClick={this.signOut} />);
    }
}

export default withRouter(SignOutButton)