import React, { CSSProperties } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import axios from 'axios';
import { Button, Form, Box, Heading, Text, TextInput, Image, Layer } from 'grommet'
import ReactCodeInput from 'react-code-input'

import * as ROUTES from '../../constants/routes';
import { API } from "../../constants/api";
import * as firebase from "firebase/app";
import 'firebase/auth'
import { FormRefresh, Close } from "grommet-icons";
import logo from '../../logo.svg'

import './CreateDomain.scss'
import { connect } from "react-redux";
import { toggleLoginModal } from "../../store/actions/appActions";
import AppContext from "../Provider";
import Rooms from "../UserDashboard/Rooms";


const INITIAL_STATE:CreateDomainState = {
    email: '',
    emailTaken:false,
    newEmail:true,
    emailChecking:false,
    showPasswordField:false,
    password:'',
    userID:'',
    canProceed:false,
    token:'',
    showOverlay:false,
    error: new Error(''),
};

type CreateDomainState = {
    email:string,
    emailTaken:boolean,
    newEmail:boolean,
    emailChecking:boolean,
    showPasswordField:boolean,
    password:string,
    userID:string,
    canProceed:boolean,
    token:string,
    showOverlay:boolean,
    error:Error,
}

interface DispatchProps {toggleLoginModal: (visibility:boolean, email:string, from?:'domain'|'room'|'home', room?:string,step?:number) => void}
interface RouterProps extends RouteComponentProps {toggleLoginModal: (visibility:boolean, email:string, from?:'domain'|'room'|'home', room?:string,step?:number) => void}
// type Props = DispatchProps & RouterParams

class CreateDomainNew extends React.Component<RouterProps, CreateDomainState> {
    static contextType = AppContext

    emailInput: any;
    appContext: any;
    constructor (props:RouterProps) {
        super(props);

        this.state = {...INITIAL_STATE};
        this.appContext = {name:'toto'}
    }

    componentDidMount() {
        this.appContext = this.context
    }

    onSubmit = (event: any) => {
        event.preventDefault();

        const { email } = this.state;

        var key = event.charCode || event.keyCode || 0;    
        if (key === 13) event.preventDefault();

        const regExEmail:RegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

        this.setState({...this.state, emailChecking:true})

        if (regExEmail.test(email)) {
            axios.get(API.users + `/email/${email}`)
                .then(res => {
                    let canProceed = false
                    if (res.data) {
                        if (res.data.isVerified) {
                            this.setState({...this.state, canProceed:false, emailTaken:true, emailChecking:false, showPasswordField:true, error:new Error()})
                            canProceed = false
                        }
                        else {
                            this.setState({...this.state, canProceed:true, newEmail:false, emailTaken:false, userID:res.data.uid, emailChecking:false, showPasswordField:false, error:new Error()})
                            canProceed = true
                        }
                    } else {
                        this.setState({...this.state, canProceed:true, newEmail:true, emailTaken:false, emailChecking:false, showPasswordField:false, error:new Error()})
                        canProceed = true
                    }
                    if (canProceed) {
                        if (!!this.state.newEmail) {
                            axios.post(API.users, {email})
                                .then((userRes) => {
                                    this.setState({...this.state, showOverlay:true, userID:userRes.data.user.uid, token:userRes.data.token.token})
                                })
                                .catch((error:Error) => {this.setState({ ...this.state, error });})
                            }
                        else {
                            axios.post(API.users + '/token/create', {email})
                                .then((tokenRes) => {
                                    this.setState({...this.state, showOverlay:true, userID:res.data.uid, token:tokenRes.data.token})
                                })
                                .catch((error:Error) => {this.setState({ ...this.state, error });})
                        }
                        
                    }
                })
                .catch((error:any) => {this.setState({ error });})
        } else this.setState({...this.state, error:new Error('Please provide a valid email adress')})

        event.preventDefault();

        return false
    }
  
    onEmailChange = (event: any) => {
        this.setState({ ...this.state, email: event.target.value.toLowerCase(), emailTaken:false, showPasswordField:false, canProceed:false, error:new Error() });
    };

    resetEmail = () => {
        this.setState({...this.state, email:'', showPasswordField:false})
        this.emailInput.focus()
    }

    passwordField = (bool:boolean):JSX.Element => {

        if (bool) {
            return (
                <Box direction='column' width='100%' alignContent='center' align='center'>
                    <Box alignContent='center' align='center' background='neutral-2' pad='small'>
                        This email is already taken, please login to access your dashboard.
                    </Box>
                    <br />
                    <Button primary label='Login' onClick={() => this.props.toggleLoginModal(true, this.state.email, 'domain', undefined, 0)} fill={false}/>
                    <br />
                    <Button onClick={this.resetEmail} plain icon={<FormRefresh/>} label='Try using another email address.' style={{textDecorationLine:'underline'}}/>
                </Box>
            )
        } return <></>
    }

    handleChange = () => {
        this.setState({...this.state, showOverlay:false})
    }
  
    render() {
        const {
            email,
            showPasswordField,
        } = this.state;

        return (
            <Box direction='column' background='white'>
                <Box direction='row' width='100%' align='center' alignContent='center' flex wrap style={{maxWidth:'1200px', margin:'0 auto'}}>
                    <Box flex wrap width='320px' pad='16px' style={{minWidth:'320px', borderBottom:'1px solid #C4C4C4'}}
                        align='center' alignSelf='start' background={{"dark": false, "opacity": false, color:'white'}} >
                        <Heading size='2em' textAlign='center' color='dark-3'>Easily create and manage your auction room with millionbids.</Heading>
                        <Text size='xlarge'>It's 100% free</Text>
                        <br />
                        <br />
                        <Form style={{width:'100%', maxWidth:'450px'}}>
                            <Box direction='column' width='100%' gap='medium' alignContent='center' align='center'>
                                <TextInput
                                    plain={false}
                                    ref={(el:any) => {this.emailInput = el}}
                                    autoComplete='off'
                                    name="emfld"
                                    value={email}
                                    onChange={this.onEmailChange}
                                    type="text"
                                    placeholder="your email"
                                    color='dark-2'
                                    style={{borderColor:'#00bbef', textAlign:'center', maxWidth:'450px', height:'46px', fontSize:'22px', position:'unset'}}
                                    size='xlarge'
                                />
                                <Button primary label={'Start here'}  onClick={this.onSubmit} fill={false} style={{display:(!showPasswordField ? 'block' : 'none'), width:'100%', maxWidth:'450px', height:'46px', color:'white', fontSize:'22px'}} />
                                {this.passwordField(showPasswordField)}
                                <Box style={{display:(!showPasswordField ? 'flex' : 'none')}}>
                                    <Text textAlign='center'>
                                        Already using millionbids?<br />
                                        Please <Button plain label='log in' style={{textDecorationLine:'underline'}} onClick={() => this.props.toggleLoginModal(true, '', 'domain', undefined, 0)} />!
                                    </Text>
                                </Box>
                                <CreateDomainLink emailTaken={false} error={this.state.error} />
                            </Box>
                        </Form>
                        
                    </Box>
                    <CreateDomainOverlayWithRouter showOverlay={this.state.showOverlay} email={this.state.email} userID={this.state.userID} token={this.state.token} handler={this.handleChange} />
                </Box>
                <Box direction='row' width='100%' align='center' alignContent='center' flex wrap style={{maxWidth:'1200px', margin:'0 auto'}}>
                    <Marketing />
                </Box>
                <Box direction='row' width='100%' align='center' alignContent='center' flex wrap style={{maxWidth:'1200px', margin:'0 auto'}}>
                    <DiscoverRooms />
                </Box>
            </Box>
        );
    }
}

const mapDispatchToProps = (dispatch:any):DispatchProps => {
    return {
        toggleLoginModal: (visibility:boolean, email:string, from?:'domain'|'room'|'home', room?:string, step?:number) => dispatch(toggleLoginModal(visibility, email, from, room, step))
    }
}

export default connect(null,mapDispatchToProps)(withRouter(CreateDomainNew));

export class Marketing extends React.Component<any,any> {

    render() {
        return (
            <Box flex wrap pad={{bottom:'40px', horizontal:'0px'}} style={{minWidth:'320px', borderBottom:'1px solid #C4C4C4'}} background={{"dark": false, "opacity": false, color:'white'}}
                direction='column' alignContent='center' align='center' alignSelf='start'>
                <Heading size='2em' textAlign='center' color='dark-3' margin={{vertical:'36px'}}>The only auction platform you'll ever need</Heading>
                <Box direction='row' flex wrap align='start' alignContent='start' alignSelf='start' justify='center' width='100%'>
                    <Box flex style={{minWidth:'320px', flex:'1 1 0%', maxWidth:'450px'}} width='100%'>
                        <Box className='marketingText'>
                            <Heading level='2' size='2.5em' color='brand'>Get started in only 2 minutes</Heading>
                            <Text size='large'>
                                With millionbids, you can enjoy a simple and unique experience to create and manage auction rooms.
                            </Text>
                        </Box>
                    </Box>
                    <Box flex style={{minWidth:'320px', minHeight:'250px', flex:'1 1 0%', maxWidth:'450px'}} width='100%' background='light-1' pad='24px' align='center' alignContent='center' alignSelf='center'>
                        {/* <Diamond size='150px' style={{margin:'auto'}} /> */}
                        <Image src='/_img/suits-needs.svg' fit='contain' style={{minWidth:'250px', maxWidth:'320px', minHeight:'250px', maxHeight:'320px'}} />
                    </Box> 
                </Box>
                <Box direction='row-reverse' flex wrap align='start' alignContent='start' alignSelf='start' justify='center' width='100%'>
                    <Box flex style={{minWidth:'320px', flex:'1 1 0%', maxWidth:'450px'}} width='100%'>
                        <Box className='marketingText'>
                            <Heading level='2' size='2.5em' color='brand'>1% fee, that's it!</Heading>
                            <Text size='large'>
                                Create, manage and hold as many auctions as you want for FREE.<br />
                                As a comission, we will only retain 1% of the total amount of the auctions.
                            </Text>
                        </Box>
                    </Box>
                    <Box flex style={{minWidth:'320px', minHeight:'250px', flex:'1 1 0%', maxWidth:'450px'}} width='100%' background='light-1' pad='24px' height='100%' align='center' alignContent='center' alignSelf='center'>
                        {/* <Diamond size='150px' style={{margin:'auto'}} /> */}
                        <Image src='/_img/one-percent.svg' fit='contain' style={{minWidth:'250px', maxWidth:'320px', minHeight:'250px', maxHeight:'320px'}} />
                    </Box> 
                </Box>
                <Box direction='row' flex wrap align='start' alignContent='start' alignSelf='start' justify='center' width='100%'>
                    <Box flex style={{minWidth:'320px', flex:'1 1 0%', maxWidth:'450px'}} width='100%'>
                        <Box className='marketingText'>
                            <Heading level='2' size='2.5em' color='brand'>For every usage</Heading>
                            <Text size='large'>
                                Will it be for charity, private or professional auctions, millionbids offers easy yet powerful features.
                                {/* Visible for everyone, on demand or private. Online or during a Real-life event. You have access to every tool you need to meaesuree and manage your auctions. */}
                            </Text>
                        </Box>
                    </Box>
                    <Box flex style={{minWidth:'320px', minHeight:'250px', flex:'1 1 0%', maxWidth:'450px'}} width='100%' background='light-1' pad='24px' align='center' alignContent='center' alignSelf='center'>
                        {/* <Diamond size='150px' style={{margin:'auto'}} /> */}
                        <Image src='/_img/easy.svg' fit='contain' style={{minWidth:'250px', maxWidth:'320px', minHeight:'250px', maxHeight:'320px'}} />
                    </Box> 
                </Box>
                <Box direction='row-reverse' flex wrap align='start' alignContent='start' alignSelf='start' justify='center' width='100%'>
                    <Box flex style={{minWidth:'320px', flex:'1 1 0%', maxWidth:'450px'}} width='100%'>
                        <Box className='marketingText'>
                            <Heading level='2' size='2.5em' color='brand'>Modern & multi device</Heading>
                            <Text size='large'>
                                Manage your content on the go, or bid from your couch. You can enjoy millionbids' modern, easy and fast interface on any device.
                            </Text>
                        </Box>
                    </Box>
                    <Box flex style={{minWidth:'320px', minHeight:'250px', flex:'1 1 0%', maxWidth:'450px'}} width='100%' background='light-1' pad='24px' height='100%' align='center' alignContent='center' alignSelf='center'>
                        {/* <Diamond size='150px' style={{margin:'auto'}} /> */}
                        <Image src='/_img/modern.svg' fit='contain' style={{minWidth:'250px', maxWidth:'320px', minHeight:'250px', maxHeight:'320px'}} />
                    </Box> 
                </Box>
                <Box direction='row' flex wrap align='start' alignContent='start' alignSelf='start' justify='center' width='100%'>
                    <Box flex style={{minWidth:'320px', flex:'1 1 0%', maxWidth:'450px'}} width='100%'>
                        <Box className='marketingText'>
                            <Heading level='2' size='2.5em' color='brand'>Plug it anywhere</Heading>
                            <Text size='large'>
                                Thanks to the millionbids plugin, you can easily integrate your auctions on your blog or website.
                            </Text>
                        </Box>
                    </Box>
                    <Box flex style={{minWidth:'320px', minHeight:'250px', flex:'1 1 0%', maxWidth:'450px'}} width='100%' background='light-1' pad='24px' align='center' alignContent='center' alignSelf='center'>
                        {/* <Diamond size='150px' style={{margin:'auto'}} /> */}
                        <Image src='/_img/api.svg' fit='contain' style={{minWidth:'250px', maxWidth:'320px', minHeight:'250px', maxHeight:'320px'}} />
                    </Box> 
                </Box>
            </Box>
        )
    }
}
export class DiscoverRooms extends React.Component<any,any> {

    render() {
        return (
            <Box flex wrap pad='16px' style={{minWidth:'320px'}} background={{"dark": false, "opacity": false, color:'white'}}
                direction='column' alignContent='center' align='center' alignSelf='start'>
                <Heading size='2em' textAlign='center' color='dark-3' margin={{bottom:'16px'}}>Browse existing auction rooms.</Heading>
                <Rooms embeded />
            </Box>
        )
    }
}

export class CreateDomainOverlay extends React.Component<
    {
        showOverlay:boolean, email:string, userID:string,
        match: any, location: any, history: any,
        handler:((el:any) => any),
        token:string,
    },
    {
        showOverlay:boolean, mode: 'auctionner'|'bidder'|undefined, step:number, canProceedDomain:boolean, loading:boolean,
        token:string, tokenError:Error, tokenLoading:boolean, tokenInvalid:boolean, 
        domainName:string, domainUnique:boolean, domainLoading:boolean, domainError:Error, domainValid:boolean, domainID:string, organizationID:string,
        roomName:string, roomError:Error, roomValid:boolean, roomID:string,
        password:string, passwordValid:boolean, passwordComplexity:number
    }
> {
    constructor (props:{showOverlay:boolean, email:string, userID:string, match: any, location: any, history: any, handler: ((el:any) => any), token:string}) {
        super(props);

        this.state = {
            showOverlay:props.showOverlay,
            mode:undefined,
            token:'',
            tokenError:new Error(),
            tokenLoading:false,
            tokenInvalid:false,
            domainName:'',
            domainUnique:true,
            domainError:new Error(),
            domainValid:true,
            domainID:'',
            organizationID:'',
            roomName:'',
            roomError:new Error(), 
            roomValid:false,
            roomID:'',
            password:'',
            passwordValid:false,
            passwordComplexity:0,
            domainLoading:false,
            canProceedDomain:false,
            step:1,
            loading:false
        }
    }

    componentDidMount () {
        // if (this.state.token !== this.props.token) this.setState({...this.state, token:this.props.token})
        // const tokenInput = document.getElementsByClassName('react-code-input')[0]
        // if (!!tokenInput) {
        //     const fourthTokenBlock = tokenInput.children[3]
        //     const dashNode = document.createElement('span')
        //     const dashContent = document.createTextNode('-')
        //     dashNode.appendChild(dashContent)
        //     if (fourthTokenBlock.nodeName === 'INPUT') {
        //         tokenInput.insertBefore(dashNode, fourthTokenBlock)
        //     }
        // }
    }

    componentDidUpdate() {
        if (this.state.showOverlay !== this.props.showOverlay) this.setState({...this.state, showOverlay:this.props.showOverlay})
    }

    onTokenChange = (token: any) => {
        this.setState({ ...this.state, token, tokenInvalid:false });

        if (token.length === 6) {
            const sentToken = token
            this.setState({tokenLoading:true})
            this.validateToken(this.props.email, sentToken)}
    };

    validateToken = (email:string, token:string) => {
        axios.post(API.users + '/token/validate', {email,token})
            .then(
                res => {
                    if (!!res.data) this.setState({...this.state, tokenInvalid:false, tokenLoading:false, step:1})
                    else this.setState({...this.state, tokenInvalid:true, tokenLoading:false, step:0})
                }
            )
            .catch((error:Error) => {
                this.setState({ ...this.state, tokenInvalid:true, tokenLoading:false, tokenError:error });
            })
    }

    onDomainSubmit = (event:any) => {
        this.setState({...this.state, loading:true})

        const { domainName } = this.state;

        this.setState({...this.state, canProceedDomain:false, domainLoading:true })

        axios.get(API.domains + `/name/${domainName}`)
            .then(res => {
                if (res.data) this.setState({...this.state, domainUnique:false, domainValid:false, loading:false })
                else this.setState({...this.state, domainValid:true, step:2, loading:false})
            })
            .catch((error:Error) => {this.setState({ domainError:error });})
        
        event.preventDefault();
    }

    onRoomSubmit = (event:any) => {
        this.setState({...this.state, loading:true})
        this.setState({...this.state, roomValid:true, step:3, loading:false})
    }

    onPasswordSubmit = (event:any) => {
        this.setState({...this.state, loading:true})
        const { domainName, password, roomName} = this.state
        const { userID, email} = this.props

        const domain = {
            name: domainName,
            shortName: domainName.replace(/\s/g, '').toLowerCase(),
            active: true,
            admin: userID,
            isFree: true,
            appFee: '1%',
        }

        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then(userRes => {
                axios.get(API.users + '/' + userID)
                    .then((userData) => {
                        const user = userData.data
                        user.passport = userRes.user!.uid
                        axios.put(API.users, user)
                        .then(() => {})
                        .catch((error:Error) => {throw error})
                        axios.post(API.domains, domain)
                            .then((domainRes) => {
                                const organization = {
                                    name: domainName,
                                    shortName: domainName.replace(/\s/g, '').toLowerCase(),
                                    domain: domainRes.data.uid,
                                    maxRooms:0,
                                    isFree: true,
                                    appFee: '1%',
                                }
                                axios.post(API.organizations, organization)
                                    .then((orgRes) => {
                                        const room = {
                                            type: 'solo',
                                            name: roomName,
                                            shortName: roomName.replace(/\s/g, '').toLowerCase(),
                                            domainShortName: domain.shortName,
                                            description: '',
                                            organisations: [orgRes.data.uid],
                                            biddingSteps: [100,500,1000],
                                            currency: 'EUR',
                                            closingMessage: '',
                                            startDate: '',
                                            endDate: '',
                                            roomTotal: 0,
                                            roomVisibility: 'open',
                                            active: false,
                                            isInverted: false,
                                            isAuto: true,
                                            isClosed: false,
                                            visibleWhenClosed: false,
                                            isFree: true,
                                            appFee: '1%',
                                            isConfig: false
                                        }
                                        axios.post(API.rooms, room)
                                            .then((roomRes) => {
                                                const userRights = {
                                                    domains:[{[domainRes.data.uid]:1}],
                                                    organizations:[{[orgRes.data.uid]:1}],
                                                    rooms:[{[roomRes.data.uid]:1}]
                                                }
                                                firebase.auth().onAuthStateChanged((user) => { if (user) {
                                                    axios.get(`${API.users}/passport/${user.uid}`)
                                                        .then((userData) => {
                                                            axios.put(`${API.users}/${userData.data.uid}/profile/rights/`, userRights)
                                                            .then(() => {})
                                                            .catch((error:Error) => {throw error})
                                                        })
                                                        .catch((error:Error) => {throw error})
                                                }});
                                                this.setState({...this.state, showOverlay:true, step:4 , domainID:domainRes.data.uid, organizationID:orgRes.data.uid, roomID:roomRes.data.uid, loading:false})
                                            })
                                            .catch((error:Error) => {throw error})
                                    })
                                    .catch((error:Error) => {throw error})
                            })
                            .catch((error:Error) => {throw error})
                    })
                    .catch((error:Error) => {throw error})
            })
            .catch((error:Error) => {throw error})
    }

    boxVisible = (bool:boolean):CSSProperties => {
        const display:string = (bool ? 'flex' : 'none')
        return {
            display,
            width:'100%',
            maxWidth:'480px',
            position:'fixed',
            top:'50%',
            left:'50%',
            transform:'translate(-50%,-50%)',
            height:'100%',
            maxHeight:'640px',
        }
    }

    checkPassword = ():boolean => {
        const { password } = this.state

        const contains = (password:string, allowedChars:string) => {
            for (let i = 0; i < password.length; i++) {
                var char = password.charAt(i);
                if (allowedChars.indexOf(char) >= 0) { return true; }
            }
                return false;
        }

        const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const lowercase = "abcdefghijklmnopqrstuvwxyz";
        const digits = "0123456789";
        const splChars ="!@#$%&*()";

        const ucaseFlag = contains(password, uppercase);
        const lcaseFlag = contains(password, lowercase);
        const digitsFlag = contains(password, digits);
        const splCharsFlag = contains(password, splChars);

        if(password.length>=8 && ucaseFlag && lcaseFlag && digitsFlag && splCharsFlag) {
            this.setState({...this.state, canProceedDomain:true })
            return true;
        }
        
        return false;
    }

    fullScreenStyle:CSSProperties = {
        position:'fixed',
        zIndex:10000,
        top:0,
        left:0,
        width:'100vw',
        height:'100vh'
    }

    closebuttonStyle:CSSProperties = {
        position: 'relative',
        zIndex: 1000,
        width: '100%',
        alignItems: 'flex-end',
    }

    logoStyle:CSSProperties = {
        position: 'fixed',
        top: 'calc(50% - 250px)',
        textAlign: 'center',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }

    titleStyle:CSSProperties = {
        position:'fixed',
        top:'100px',
        textAlign:'center'
    }
    
    contentStyle:CSSProperties = {
        position:'fixed',
        top:'300px',
        textAlign:'center',
        width:'100%',
    }

    footerStyle:CSSProperties = {
        position:'fixed',
        bottom:'100px',
        textAlign:'center'
    }

    buttonStyle:CSSProperties = {
        height:'30px',
        margin:'0 auto',
        color:'light-1'
    }

    render () {

        const { token } = this.state
        return (
        <>{this.state.showOverlay && (
            <Layer modal={false} style={{width:'100vw', height:'100vh'}}>
                <Box style={{position:'relative', width:'100vw', height:'100vh', top:0, right:0, bottom:0, left:0, display:'block', minHeight:'fit-content'}} overflow='hidden' background='light-1'>
                    <Box style={this.closebuttonStyle}>
                        <Button color='dark-1' onClick={() => {this.props.handler(false)}}>
                            <Box alignContent='center' align='center'>
                                <Close />
                            </Box>
                        </Button>
                    </Box>
                    <Box style={this.logoStyle} direction="row" pad='small' >
                        <Image src={logo} height='48' />
                        <Box pad={{left:'small', bottom:'xxsmall', top:'xxsmall'}}>
                            <Text style={{fontFamily:"Open Sans", color:"#00BEFF", fontWeight:600}} size='30px'>millionbids</Text>
                        </Box>
                    </Box>
                    <Box align='center' alignContent='center' style={this.boxVisible(this.state.step === 0)}>
                        <Box style={this.titleStyle}>
                            <Heading size='small'>Check your email</Heading>
                            <Text size='large' textAlign='center'>We sent you a 6-digit confirmation code to {this.props.email}. It will expire shortly, so enter it soon.</Text>
                        </Box>
                        <Box style={this.contentStyle}>
                            <ReactCodeInput style={{size:'24px'}} name='token' value={token} type="text" onChange={this.onTokenChange} inputMode='number' pattern='[0-9]*' fields={6} isValid={!this.state.tokenInvalid} />
                            <Text size='small' textAlign='center' style={this.boxVisible(this.state.tokenInvalid)}>This token is invalid, please try again.</Text>
                            <br />
                            <Text>Didn't receive your token?</Text>
                            <Button plain icon={<FormRefresh />} label='Generate a new one' onClick={() => {}} style={{textDecorationLine:'underline'}}/>
                            <br />
                            <Text size='small' textAlign='center'>Remember to keep this window open while checking, and don't forget to check your spam folder.</Text>
                        </Box>
                    </Box>
                    {/* <Box align='center' alignContent='center'  style={this.boxVisible(this.state.step === 5)}>
                        <Box style={this.titleStyle}>
                            <Heading textAlign='center' size='small'>How do you want to use "millionbids"?</Heading>
                        </Box>
                        <Box style={{...this.contentStyle, top:'200px'}}>
                        <Box height='150px' width='100%' border={{side:'top', size:'small', color:'light-3'}} onClick={() => {this.setState({ ...this.state, mode:'auctionner', step:1 })} } style={{position:'relative', cursor:'pointer'}}>
                            <Box style={{position:"absolute", top:'50%', transform:'translateY(-50%)'}}>
                                <Tools size='large'/>
                            </Box>
                            <Box gap='small' direction='column' alignContent='start' style={{position:"absolute", top:'20px', left:'64px', bottom:'20px', right:'48px'}}>
                                <Text textAlign='start' size='xlarge' color='brand' weight='bold'>I want to create auctions</Text>
                                <Text textAlign='start' size='small'>
                                    The room is automatically opened and closed on a date chosen by you.<br />
                                    Note: when the auction have started, you will not be able to updates the starting and closing dates.
                                </Text>
                            </Box>
                            <Box style={{position:"absolute", right:'0', top:'50%', transform:'translateY(-50%)'}}>
                                <FormNext size='large' color='brand' />
                            </Box>
                        </Box>
                        <Box height='150px' width='100%' border={{side:'top', size:'small', color:'light-3'}} onClick={() => {this.setState({ ...this.state, mode:'bidder', step:3 })} } style={{position:'relative', cursor:'pointer'}}>
                            <Box style={{position:"absolute", top:'50%', transform:'translateY(-50%)'}}>
                                <Tooltip size='large'/>
                            </Box>
                            <Box gap='small' direction='column' alignContent='start' style={{position:"absolute", top:'20px', left:'64px', bottom:'20px', right:'48px'}}>
                                <Text textAlign='start' size='xlarge' color='brand' weight='bold'>I want to bid on existing auctions</Text>
                                <Text textAlign='start' size='small'>
                                    The room is automatically opened and closed on a date chosen by you.<br />
                                    Note: when the auction have started, you will not be able to updates the starting and closing dates.
                                </Text>
                            </Box>
                            <Box style={{position:"absolute", right:'0', top:'50%', transform:'translateY(-50%)'}}>
                                <FormNext size='large' color='brand' />
                            </Box>
                        </Box>
                        </Box>
                        <Box style={this.footerStyle}>
                            {this.state.loading && <Box><Text>Loading, please wait...</Text></Box>}
                            <br />
                            <Text textAlign='center' size='small'>By continuing, you accept our <Link to=''>terms of use</Link>, <Link to=''>privacy policy</Link> and <Link to=''>cookie policy</Link></Text>
                        </Box>
                    </Box> */}
                    <Box align='center' alignContent='center'  style={this.boxVisible(this.state.step === 1)}>
                        <Box style={this.titleStyle}>
                            <Heading textAlign='center' size='small'>Please enter a name for your domain</Heading>
                            <Text size='large' textAlign='center'>Your domain name should be 3 to 36 characters long. Use only letters and numbers, no special characters.</Text>
                        </Box>
                        <Box style={this.contentStyle}>
                            <TextInput name='domainName'
                                placeholder='Domain Name'
                                onChange={(event:any) => this.setState({...this.state, domainName:event.target.value, domainValid:true})}
                                pattern='[A-Za-z0-9]*' maxLength={36} minLength={3}
                                style={{textAlign:'center'}}
                                />
                            {!this.state.domainValid && 
                                <Box background='neutral-2' pad='small'>
                                    <Text>This domain name is already taken, please enter a new one.</Text>
                                </Box>
                            }
                        </Box>
                        <Box style={this.footerStyle}>
                            {this.state.loading && <Box><Text>Loading, please wait...</Text></Box>}
                            {!this.state.loading &&<Button style={this.buttonStyle} primary label='Next' type='submit' onClick={this.onDomainSubmit} disabled={this.state.domainName.length <= 2 && this.state.loading} />}
                        </Box>
                    </Box>
                    <Box align='center' alignContent='center'  style={this.boxVisible(this.state.step === 2)}>
                        <Box style={this.titleStyle}>
                            <Heading textAlign='center' size='small'>Please chose a name for your auction room.</Heading>
                        </Box>
                        <Box style={this.contentStyle}>
                            <TextInput placeholder='Room Name' name='roomName' type='text' style={{textAlign:'center'}}
                                onChange={(event:any) => {
                                    this.setState({...this.state, roomName:event.target.value})
                                }}
                            />
                        </Box>
                        <Box style={this.footerStyle}>
                            {this.state.loading && <Box><Text>Loading, please wait...</Text></Box>}
                            {!this.state.loading && <Button style={this.buttonStyle} primary label='Next' type='submit' onClick={this.onRoomSubmit} disabled={(this.state.roomName.length <= 1 && this.state.loading)}/> }
                            <br />
                            <Text textAlign='center' size='medium'>You can change the room's name later if needed.</Text>
                        </Box>
                    </Box>
                    <Box align='center' alignContent='center'  style={this.boxVisible(this.state.step === 3)}>
                        <Box style={this.titleStyle}>
                            <Heading textAlign='center' size='small'>Please chose a password</Heading>
                        </Box>
                        <Box style={this.contentStyle}>
                            <TextInput placeholder='Password' name='password' type='password' style={{textAlign:'center'}}
                                onChange={(event:any) => {
                                    this.setState({...this.state, password:event.target.value})
                                }}
                            />
                        </Box>
                        <Box style={this.footerStyle}>
                        {this.state.loading && <Box><Text>Loading, please wait...</Text></Box>}
                            {!this.state.loading &&<Button style={this.buttonStyle} primary label='Next' type='submit' onClick={this.onPasswordSubmit} disabled={(this.state.password.length <= 5 && this.state.loading)}/>}
                            <br />
                            <Text size='small'>You can change your password later if needed.</Text>
                        </Box>
                    </Box>
                    <Box align='center' alignContent='center' style={this.boxVisible(this.state.step === 4)}>
                        <Box style={this.titleStyle}>
                            <Heading textAlign='center' size='small'>Congratulations!<br />Your domain is created.</Heading>
                        </Box>
                        <Box style={this.contentStyle}>
                            <Text size='large'>You can now access your admin dashboard. You will be assisted to create your first auction.</Text>
                        </Box>
                        <Box style={this.footerStyle}>
                            <Button style={this.buttonStyle} primary label='Start using "millionbids"' type='submit' onClick={() => this.props.history.push(`${ROUTES.DASHBOARD}r/${this.state.roomID}`)}/>
                            <Text size='small'>&nbsp;</Text>
                        </Box>
                    </Box>
                </Box>
            </Layer>
        )}</>
        );}
}

const CreateDomainOverlayWithRouter = withRouter(CreateDomainOverlay)

export class CreateDomainLink extends React.Component<{emailTaken:boolean, error:Error}> {

    boxVisible = (bool:boolean):CSSProperties => {
        const display:string = (bool ? 'flex' : 'none')
        return {display}
    }

    render () {
        return (
            <>
                <Box background='neutral-2' pad='small' style={this.boxVisible(!!this.props.error.message)}>
                    {this.props.error.message}
                </Box>
            </>
        );
    }
}
