import React, { MouseEvent } from 'react';
import { Box, Button, Image, Text, Carousel, TextInput } from 'grommet'
import { Bid } from './lot.type'
import { connect } from 'react-redux';
import { placeBid } from '../../store/actions/lotActions';
import { AuthUserContext } from '../Session';
import { FormUp, FormDown, Add, Close } from 'grommet-icons';
import Helpers from '../../constants/helpers';
import { toggleLoginModal } from '../../store/actions/appActions';

class Lot extends React.Component<any, any> {

    constructor(props:any) {
        super(props)

        this.state = {
            showImages:true,
            showDescription:false,
            showBidDrawer:false,
            bidValue:this.props.bidSteps[0],
            status:this.props.status
        }
    }

    componentWillReceiveProps(nextProps:any) {
        // if (nextProps.showPictures !== this.state.showImages ) this.setState({...this.state, showImages:nextProps.showPictures})
        if (nextProps.showDescription !== this.state.showDescription ) this.setState({...this.state, showDescription:nextProps.showDescription})
        if (nextProps.status !== this.state.status ) this.setState({...this.state, status:nextProps.status})
    }

    openBidDrawer = (userID:string) => {
        if (!!userID) {
            this.setState({...this.state, showBidDrawer:!this.state.showBidDrawer})
        } else {
            this.props.toggleLoginModal(true, '', 'room', window.location.pathname, 0)
        }
    }

    bid = (user:any) => {
        const bid = {
            lot: this.props.uid,
            user: user,
            bidValue: this.state.bidValue
        }

        this.props.placeBid(bid)
        this.setState({...this.state, bidValue:this.props.bidSteps[0], showBidDrawer:false})
    }

    render () {
        return (
            <AuthUserContext.Consumer>
                {(authUser) => 
                    <Box direction="column"
                        round
                        overflow='hidden'
                        width='280px'
                        background='white'
                        border
                        style={{
                            boxShadow:'0 0 0.5em 0 #dedede',
                            WebkitBoxShadow:'0 0 0.5em 0 #dedede'
                        }}
                        margin={{bottom:'medium', horizontal:'small'}}
                    >
                        <Box height="small" background='white' width="medium" overflow="hidden" style={{display:(this.props.showPictures ? 'flex' : 'none')}}>
                        {
                            this.props.mediaList.length >= 2 &&
                                <Carousel fill >
                                    {
                                        this.props.mediaList.map((image:string, i:number) => 
                                            <Image key={i} src={image} fit='contain'/>
                                        )
                                    }
                                </Carousel>
                        }
                        {
                            this.props.mediaList.length === 1 &&
                                this.props.mediaList.map((image:string, i:number) => 
                                    <Image key={i} src={image} fit='contain'/>
                                )
                        }
                        </Box>
                        {/* <Button>
                            <Icons.Favorite />
                        </Button> */}
                        <Box pad='medium'>
                            <Box gap='small'>
                                <Text size='medium' weight='bold'>{this.props.title}</Text>
                                <Box direction='row' gap='5px' align='center'>
                                    <Text size='small'>Current bid:</Text>
                                    <Text size='medium' weight='bold'>{this.props.currency} {this.props.currentAmount * 1}</Text>
                                    <div style={{flex:1}} />
                                    <Button plain icon={(this.state.showDescription ? <FormUp color='brand' /> : <FormDown color='brand' />)}
                                        onClick={() => this.setState({...this.state, showDescription:!this.state.showDescription})}
                                    />
                                </Box>
                                {
                                    !!this.state.showDescription &&
                                        <Text>{this.props.shortDesc}</Text>
                                }
                            </Box>
                        </Box>
                        <Box gap='medium' pad='medium' 
                            background={
                                this.state.status !== 'live' ? 'light-3' :
                                !!authUser.userID && authUser.userID === this.props.currentWinningUser ? 'neutral-1' : 'brand'
                            } 
                            flex fill align='center' alignContent='center' alignSelf='center'>
                            {
                                this.state.status !== 'live' &&
                                <Text
                                    style={{textAlign:'center', color:(!!authUser.userID && authUser.userID === this.props.currentWinningUser ? 'white' : '')}} 
                                >
                                    {this.state.status === 'ready' ? 'Not started yet' : 'Auctions closed' }
                                </Text>
                            }
                            {
                                !this.state.showBidDrawer && this.state.status === 'live' &&
                                <Button
                                    plain fill
                                    style={{textAlign:'center', color:'white'}} 
                                    label={this.state.showBidDrawer ? '' : (!!authUser.userID && authUser.userID === this.props.currentWinningUser ? 'You\'re winning' : 'Place a bid')}
                                    icon={(!!authUser.userID && authUser.userID === this.props.currentWinningUser ? <></> : (this.state.showBidDrawer ? <></> : <Add size='15px' color='white' />))} onClick={() => {this.openBidDrawer(authUser.userID)}} />
                            }
                            {
                                this.state.showBidDrawer && this.state.status === 'live' &&
                                <>
                                    <Box flex fill gap='small' style={{ position:'relative'}}>
                                        <Close color='white' style={{position:'absolute', top:'6px', right:'6px'}} size='small' onClick={() => {this.setState({...this.state, showBidDrawer:!this.state.showBidDrawer})}} />
                                        <Text color='white'>Set your outbid value</Text>
                                        <Box round='6px' margin={{bottom:'15px'}} fill flex border={{color:'light-4', size:'1px'}} overflow='hidden' gap='1px' background='#dadada'>
                                            <Box direction='row' flex fill pad='small' round={{corner:'top', size:'6px'}} background='light-2' align='center' alignContent='center' alignSelf='center'>
                                                <TextInput size='large' type='number' plain value={this.state.bidValue} />
                                                <Text size='large' weight='bold'>{this.props.currency}</Text>
                                            </Box>
                                            <Box flex fill direction='row' wrap align='center' alignContent='center' alignSelf='center' gap='1px'>
                                                {this.props.bidSteps.map((value:number, index:number) => 
                                                    <Button
                                                        icon={<Add size='small' />}
                                                        label={new Helpers().abbrNum(value, 2)}
                                                        color='white' primary key={index}
                                                        style={{padding:6, flex:1, borderRadius:0, minHeight:'48px'}}
                                                        onClick={(e:MouseEvent) => {const bidValue = this.state.bidValue + value; this.setState({...this.state, bidValue})}} />
                                                )}
                                            </Box>
                                            <Box flex fill direction='row' wrap align='center' alignContent='center' alignSelf='center'>
                                                <Button
                                                primary color='light-3'
                                                label={<Text size='xsmall'>RESET</Text>}
                                                style={{padding:6, flex:1, borderRadius:0}}
                                                onClick={(e:MouseEvent) => {const bidValue = this.props.bidSteps[0]; this.setState({...this.state, bidValue})}} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Button
                                        plain fill
                                        style={{textAlign:'center', color:'white'}} 
                                        label='Place a bid' icon={<Add size='15px' color='white' />}
                                        onClick={() => this.bid(authUser.userID)} />
                                </>
                            }
                        </Box>
                    </Box>
                }
            </AuthUserContext.Consumer>
        );
    }
}

const mapStateToProps = (state:any) => ({
    
})

const mapDispatchToProps = (dispatch:any) => {
    return {
        placeBid: (bid:Bid) => dispatch(placeBid(bid)),
        toggleLoginModal: (visibility:boolean, email:string, from?:'domain'|'room'|'home', room?:string, step?:number) => dispatch(toggleLoginModal(visibility, email, from, room, step))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Lot);
