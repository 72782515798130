export const addDomain = (payload:{}) => {
    return (dispatch: (action: { type: string; payload:{}}) => void, getState: any) => {
        dispatch({type: 'ADD_DOMAIN', payload})
    }
}

export const addOrganization = (payload:{}) => {
    return (dispatch: (action: { type: string; payload:{}}) => void, getState: any) => {
        dispatch({type: 'ADD_ORGANIZATION', payload})
    }
}

export const addRoom = (payload:{}) => {
    return (dispatch: (action: { type: string; payload:{}}) => void, getState: any) => {
        dispatch({type: 'ADD_ROOM', payload})
    }
}

export const setAccess = (payload:{domains:any[],organizations:any[],rooms:any[]}) => {
    return (dispatch: (action: { type: string; payload:{domains:any[],organizations:any[],rooms:any[]}}) => void, getState: any) => {
        dispatch({type: 'ADD_ROOM', payload})
    }
}