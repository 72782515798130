import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Grommet, Box } from 'grommet'

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import UnauthorizedPage from '../Unauthorized'
import { theme } from '../../theme'

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import ErrorBoundary from '../ErrorBoundary';
import LogoDisplay from '../../logoDisplay';
import Footer from '../Footer';

class App extends React.Component<any,any> {

    render () {
        return (
            <ErrorBoundary>
                <Grommet theme={theme} style={{display:'block'}}>
                    <Navigation />
                    <Box className='header' direction='row' height='92px'>
                        <LogoDisplay />
                    </Box>
                    <Box className='content'>
                        <Switch>
                            <Route exact path={ROUTES.LANDING} component={LandingPage} />
                            <Route exact path={ROUTES.E403} component={UnauthorizedPage} />
                        </Switch>
                    </Box>
                    <Footer />
                </Grommet>
            </ErrorBoundary>
        );
    }
  
}

export default withAuthentication(App);