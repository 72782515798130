import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import * as ROUTES from '../../constants/routes';
import AuthUserContext from './context';

import { User } from 'firebase'
import * as firebase from 'firebase/app'
import 'firebase/auth'

type AuthUser = {
    authUser: User|null
}

const withAuthorization = (condition:any) => (Component:React.ComponentClass) => {
  class WithAuthorization extends React.Component<any, AuthUser> {
    listener: any;
    
    componentDidMount() {
      this.listener = firebase.auth().onAuthStateChanged(
          (authUser: any) => {
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.SIGN_IN);
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            condition(authUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(
    withRouter,
  )(WithAuthorization);
};

export default withAuthorization;