import React, { Component } from 'react'
import { Box, Text } from 'grommet';

interface Props {
    
}
interface State {
    
}

export default class Cause extends Component<Props, State> {
    state = {}

    render() {
        return (
            <Box>
                <Box pad={{horizontal:'medium'}} direction='row' flex fill align='center' alignSelf='start' alignContent='center'>
                </Box>
                <Box pad='medium' gap='20px'>
                    <Box background='white' round direction='row' style={{position:'relative', overflow:'auto'}}>                            
                        <Box direction='column' pad='15px' gap='20px'>
                            <Text weight='bold'>
                                CAUSE
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }
}
