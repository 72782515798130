import React from "react";
import { Box, Image, Text } from "grommet";
import logo from './logo.svg'
import logotype from './logotype.svg'
import { withRouter } from "react-router-dom";

class LogoDisplay extends React.Component<any, {width:number, height:number}> {

    constructor(props:any) {
        super(props)

        this.state = {
            width:0,
            height:0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions() {
        this.setState({...this.state, width: window.innerWidth, height: window.innerHeight});
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    render() {

        const logoSVG = (this.state.width <= 768 ? logo : logotype)

        return (
            <Box className='logo' direction="row" onClick={() => {this.props.history.push('/')}}  style={{position:'fixed',cursor:'pointer', minWidth:'max-content', pointerEvents: 'auto'}} pad={{left:'36px', right:'18px', vertical:'small'}} align='center' alignContent='center' alignSelf='center' >
                <Image src={logoSVG} height='48' />
                <Box pad={{left:'small', bottom:'xxsmall', top:'xxsmall'}}>
                    <Text style={{fontFamily:"Open Sans", color:"#00BEFF", fontWeight:600}} size='30px'>millionbids</Text>
                </Box>
            </Box>
        )
    }
}

export default withRouter(LogoDisplay)