import React, { CSSProperties } from 'react';
import { Box, Button, Text, Menu, Layer, CheckBox } from 'grommet';
import { connect } from 'react-redux';
import { Add, Close, Validate, RadialSelected, Performance, Edit } from 'grommet-icons';
import * as RoomFields from './RoomFields'
import * as LotFields from './LotFields'
import axios from 'axios';
import  * as ROUTES from '../../constants/routes'

import { API } from '../../constants/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BigScreen from './BigScreen';
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import Countdown from '../Countdown';
import BidsList from './BidsList';

interface Lot {
    title: string
    shortDesc: string
    longDesc: string
    deliveryType: number
    deliveryDetails?:string
    lotCategory:number
    keywords:string[]
    startAmount: number|undefined
    currentAmount: number
    currentWinningUser: string
    lastBidder: string
    room: string
    coverImage: string
    mediaList: string[]
    active: boolean
    closed: boolean
    attachments: string[]
    deliveryDate: string
    hasProblem: boolean
    problemsId: string[]
    isScam: boolean
    appFee: string
    isFree: boolean
    uid?:string
}

interface RoomState {
    loading:boolean
    loadingLots:boolean
    showRoomWizzard:boolean
    roomCanProceed:boolean
    roomWizzardStep:number
    roomWizzardMaxStep:number
    showLotWizzard:boolean
    lotEditMode:boolean
    showBigScreen:boolean
    showShareLink:boolean
    showLots:boolean
    showBids:boolean
    room: {
        active: boolean
        appFee:string
        biddingSteps: number[]
        closingMessage:string
        coverImage:string
        currency:string
        description:string
        endDate:string
        isAuto: boolean
        isClosed: boolean
        isConfig:boolean
        isFree: boolean
        isInverted: boolean
        isStarted:boolean
        name:string
        organisations:string[]
        roomTotal: number
        roomVisibility:'open'|'closed'|'private'|undefined
        shortName:string
        sponsor?:string[]
        startDate:string
        type:string
        visibleWhenClosed: boolean
        domainShortName:string
        uid?:string
    }
    tempLot:Lot
    lots:Lot[]
}

class Room extends React.Component<any, RoomState> {
    roomID: string = this.props.match.params.roomID
    roomMenu:React.RefObject<any> = React.createRef();

    initialLotState = {
        title:'',
        shortDesc:'',
        longDesc:'',
        deliveryType:7,
        lotCategory:0,
        keywords:[],
        startAmount:undefined,
        currentAmount:0,
        currentWinningUser:'',
        lastBidder:'',
        room:this.roomID,
        coverImage:'',
        mediaList:[],
        active:false,
        closed:false,
        attachments:[],
        deliveryDate:'',
        hasProblem:false,
        problemsId:[],
        isScam:false,
        appFee:'',
        isFree:true,
    }

    constructor(props:any) {
        super(props)

        this.state = {
            loading:true,
            loadingLots:true,
            showRoomWizzard:false,
            roomCanProceed:true,
            roomWizzardStep:0,
            roomWizzardMaxStep:7,
            showLotWizzard:false,
            lotEditMode:false,
            showBigScreen:false,
            showShareLink:false,
            showLots:false,
            showBids:false,
            room: {
                active: false,
                appFee: '',
                biddingSteps: [0],
                closingMessage: '',
                coverImage:'',
                currency: '',
                description: '',
                domainShortName:'',
                endDate: '',
                isAuto: true,
                isClosed: false,
                isConfig:false,
                isFree: true,
                isInverted: false,
                isStarted: false,
                name: '',
                organisations: [''],
                roomTotal: 0,
                roomVisibility: 'open',
                shortName: '',
                startDate: '',
                type: '',
                visibleWhenClosed: false
            },
            tempLot:this.initialLotState,
            lots:[]
        }

        this.roomID = this.props.match.params.roomID
    }

    componentDidUpdate() {
        if (this.roomID !== this.props.match.params.roomID) this.roomID = this.props.match.params.roomID
    }

    componentDidMount() {
        this.setState({...this.state, tempLot:{...this.state.tempLot, room: this.roomID}})
        firebase.firestore().collection('rooms').doc(this.roomID)
            .get()
            .then((docRef:any) => {
                if (!!docRef.exists) {
                    this.setState({...this.state, room:{...docRef.data()}, loading:false})
                }
            })

        firebase.firestore().collection('lots').where('room', '==', this.roomID)
            .onSnapshot((querysnapshot:any) => {
                const lots:any[] = []
                querysnapshot.forEach((lot:any) => 
                    {lots.push({...lot.data(), uid:lot.id})}
                )
                return this.setState({...this.state, lots, loadingLots:false});
            })
    }

    componentWillReceiveProps(nextProps:any) {
        if (nextProps.match.params.roomID !== this.state.room.uid) {
            this.setState({...this.state, tempLot:{...this.state.tempLot, room: nextProps.match.params.roomID}})
            firebase.firestore().collection('rooms').doc(nextProps.match.params.roomID)
                .get()
                .then((docRef:any) => {
                    if (!!docRef.exists) {
                        this.setState({...this.state, room:{...docRef.data()}, loading:false})
                    }
                })

                firebase.firestore().collection('lots').where('room', '==', nextProps.match.params.roomID)
                .get()
                .then((querysnapshot:any) => {
                    const lots:any[] = []
                    querysnapshot.forEach((lot:any) => 
                        {lots.push({...lot.data(), uid:lot.id})}
                    )
                    return this.setState({...this.state, lots, loadingLots:false});
                })
        }
    }

    componentWillUnmount() {
        firebase.firestore().collection('lots').where('room', '==', this.roomID).onSnapshot(() => {})
    }

    setRoomDescription = (state:any) => {
        if (state.toolong && this.state.roomCanProceed) {
            this.setState({...this.state, roomCanProceed:false})
        }
        if (!state.toolong && !this.state.roomCanProceed) {
            this.setState({...this.state, roomCanProceed:true})
        }
        if(this.state.room.description !== state.editorContent) {
            this.setState({...this.state, room:{...this.state.room, description:state.editorContent}})
        }
    }
    
    setRoomCoverImage = (coverImage:string) => {
        if(this.state.room.coverImage !== coverImage) {
            this.setState({...this.state, room:{...this.state.room, coverImage}})
        }
    }

    setRoomVisibility = (state:{visibility:'open'|'closed'|'private'|undefined, isClicked:boolean}) => {
        if(state.isClicked && this.state.room.roomVisibility !== state.visibility) {
            this.setState({...this.state, room:{...this.state.room, roomVisibility:state.visibility}})
        }
    }

    setRoomAuto = (isAuto:string) => {
        const mode = (isAuto === 'automatic')
        if(this.state.room.isAuto !== mode) {
            this.setState({...this.state, room:{...this.state.room, isAuto:mode}})
        } 
    }

    setRoomDates = (dates:any) => {
        if (!!dates.startDate && !!dates.endDate) {
            // if (!this.state.roomCanProceed) this.setState({...this.state, roomCanProceed:false})
            const startDate = new Date(dates.startDate).toUTCString()
            const endDate = new Date(dates.endDate).toUTCString()
            console.log(startDate, endDate)
            if (endDate !== this.state.room.endDate || startDate !== this.state.room.startDate) {
                this.setState({...this.state, roomCanProceed:true, room:{...this.state.room, startDate, endDate}})
            }
        }
    }
    
    setRoomSponsors = (sponsor:string[]) => {
        if(this.state.room.sponsor !== sponsor) {
            this.setState({...this.state, room:{...this.state.room, sponsor}})
        }
    }

    saveRoomAndNext = () => {
        let payload = () => {
            switch (this.state.roomWizzardStep) {
                case 0: this.setState({...this.state, roomWizzardStep:1}); return {description:this.state.room.description};
                case 1: this.setState({...this.state, roomWizzardStep:2}); return {coverImage:this.state.room.coverImage};
                case 2: {
                    if (this.state.room.roomVisibility !== 'private') {
                        this.setState({...this.state, roomWizzardStep:4});
                        return {roomVisibility:this.state.room.roomVisibility, isAuto:true};
                    }
                    else {
                        this.setState({...this.state, roomWizzardStep:3});
                        return {roomVisibility:this.state.room.roomVisibility};
                    }
                }
                //TODO: Route to CC Details if Room is Manual
                case 3: 
                    if (this.state.room.isAuto) {
                        this.setState({...this.state, roomWizzardStep:4});
                        return {isAuto:this.state.room.isAuto};
                    } else {
                        this.setState({...this.state, roomWizzardStep:5});
                        return {isAuto:this.state.room.isAuto};
                    }
                case 4: this.setState({...this.state, roomWizzardStep:5}); return {startDate:this.state.room.startDate, endDate:this.state.room.endDate};
                case 5: this.setState({...this.state, roomWizzardStep:6}); return {sponsor:this.state.room.sponsor};
                //TODO: Set isConfig to true only if CC Data & Dates are OK
                case 6: 
                    if (
                        // TODO: ADD CC Check
                        // TODO: ADD Check Conditions
                        (this.state.room.isAuto && !!this.state.room.startDate && !!this.state.room.endDate) ||
                        (this.state.room.roomVisibility === 'private' && !this.state.room.isAuto) ||
                        (!!this.state.room.isAuto)
                    ) {
                        this.setState({...this.state, roomWizzardStep:0, showRoomWizzard:false, room:{...this.state.room, isConfig:true, active:true}}); 
                        return {isConfig:true, active:true};
                    } else {
                        this.setState({...this.state, roomWizzardStep:0, showRoomWizzard:false, room:{...this.state.room, isConfig:false, active:false}});
                        return {isConfig:false, active:false}
                    }
            }    
        }

        axios.put(`${API.rooms}/${this.roomID}`, payload())
            .then((res) => {})
            .catch((error:Error) => {throw error})
    }

    manualSetRoomStatus = (status:'open'|'closed') => {
        if (status === 'open') {
            firebase.firestore().collection('rooms').doc(this.roomID).update({isStarted:true, isClosed:false})
        } else if (status === 'closed') {
            firebase.firestore().collection('rooms').doc(this.roomID).update({isClosed:true, isStarted:false})
        }
    }

    deleteRoom = () => {
        firebase.firestore().collection('rooms').doc(this.roomID).update({isArchived:true}).then(() => this.props.history.push(`${ROUTES.ADMIN}`))
    }
    
    setLotTitle = (title:string) => {
        if(this.state.tempLot.title !== title) {
            this.setState({...this.state, tempLot:{...this.state.tempLot, title}})
        }
    }

    setLotDescription = (description:string) => {
        if(this.state.tempLot.shortDesc !== description) {
            this.setState({...this.state, tempLot:{...this.state.tempLot, shortDesc:description, longDesc:description}})
        }
    }

    setLotStartAmount = (startAmount:number) => {
        if(this.state.tempLot.startAmount !== startAmount) {
            this.setState({...this.state, tempLot:{...this.state.tempLot, currentAmount:startAmount, startAmount}})
        }
    }
    
    setLotMediaList = (mediaList:string[]) => {
        if(this.state.tempLot.mediaList !== mediaList ) {
            this.setState({...this.state, tempLot:{...this.state.tempLot, mediaList}})
        }
    }

    setLotDelivery = (state:any) => {
        if(!!state.value && this.state.tempLot.deliveryType !== state.value.value) {
            if (state.value.value === 3 || state.value.value === 4 || state.value.value === 7) {this.setState({...this.state, tempLot:{...this.state.tempLot, deliveryType:state.value.value}})}
            if (state.value.value === 2) {this.setState({...this.state, tempLot:{...this.state.tempLot, deliveryType:state.value.value, deliveryDetails:state.attachment}})}
        } else {
            if (state.value.value === 4 && this.state.tempLot.deliveryDetails !== `${state.deliveryService}|${state.deliveryDays}`) {
                this.setState({...this.state, tempLot:{...this.state.tempLot, deliveryDetails:`${state.deliveryService}|${state.deliveryDays}`}})
            }
            if (state.value.value === 2 && this.state.tempLot.deliveryDetails !== state.attachment) {
                this.setState({...this.state, tempLot:{...this.state.tempLot, deliveryDetails:state.attachment}})
            }
        } 
    }

    setLotCategory = (lotCategory:any) => {
        if(!!lotCategory && this.state.tempLot.lotCategory !== lotCategory.value) {
            this.setState({...this.state, tempLot:{...this.state.tempLot, lotCategory:lotCategory.value}})
        }
    }
    
    setLotKeywords = (keywords:any[]) => {
        if(this.state.tempLot.keywords !== keywords) {
            this.setState({...this.state, tempLot:{...this.state.tempLot, keywords}})
        }
    }

    saveLotAndNext = () => {
        if (!!this.state.lotEditMode) {
            firebase.firestore().collection('lots').doc(this.state.tempLot.uid).update(this.state.tempLot).then(() => {
                toast(<Box direction='row' gap='medium'><Validate /><Text>Successfully updated!</Text></Box>);
            }).catch((error:Error) => {throw error})
        } else {
            firebase.firestore().collection('lots').add(this.state.tempLot).then(() => {
                this.setState({...this.state, tempLot:{...this.initialLotState, mediaList:[], startAmount:undefined}},
                        () => toast(<Box direction='row' gap='medium'><Validate /><Text>Successfully saved!</Text></Box>)
                    )
            }).catch((error:Error) => {throw error})
        }
    }

    deleteLot = () => {
        firebase.firestore().collection('lots').doc(this.state.tempLot.uid).delete().then(() => {
            this.setState({...this.state, showLotWizzard:false, tempLot:{...this.initialLotState, mediaList:[], startAmount:undefined}},
                () => toast(<Box direction='row' gap='medium'><Validate /><Text>Successfully deleted!</Text></Box>)
            )
        })
    }

    setBigScreenVisibility = (showBigScreen:boolean) => {
        if(showBigScreen !== this.state.showBigScreen) {
            this.setState({...this.state, showBigScreen})
        }
    }

    editLot = (lot:Lot) => {
        if (lot !== this.state.tempLot) {
            this.setState({...this.state, tempLot:lot, showLotWizzard:true, lotEditMode:true})
        }
    }

    wizzardClosebuttonStyle:CSSProperties = {
        position: 'relative',
        zIndex: 1000,
        width: '100%',
        alignItems: 'flex-end',
    }

    wizzardLogoStyle:CSSProperties = {
        marginTop: '25px',
        textAlign: 'center',
        width: '100%',
        maxWidth: '450px'
    }

    wizzardContentStyle:CSSProperties = {
        textAlign: 'center',
        width: '100%',
        maxWidth: '450px'
    }

    wizzardFooterStyle:CSSProperties = {
        marginBottom: '25px',
        width: '100%',
        maxWidth: '400px'
    }
    
    RoomWizzard = () => {
        const { room } = this.state
        if (this.state.showRoomWizzard) {
            return (
                <Layer modal={false} style={{width:'100vw', height:'100vh', overflow:'auto'}}>
                    <Box 
                        style={{position:'relative', width:'100vw', height:'100vh', top:0, right:0, bottom:0, left:0, display:'block', minHeight:'fit-content'}}
                        background='light-1' pad='medium' gap='medium'>
                        <Box style={this.wizzardClosebuttonStyle}>
                            <Button color='dark-1' onClick={() => {this.setState({...this.state, showRoomWizzard:false, roomWizzardStep:0})}}>
                                <Box alignContent='center' align='center'>
                                    <Close />
                                </Box>
                            </Button>
                        </Box>
                        <Box background='white' round pad='medium' width='450px' 
                            style={{margin:'0 auto 100px', display:'flex', boxOrient:'vertical', overflow:'auto'}}
                            align='center' alignContent='center' alignSelf='center' 
                        >
                            <Box pad='small' style={{margin:'0 auto', minHeight:'min-content'}} direction='row'>
                                <Text size='xxlarge' style={{fontVariant:'all-small-caps'}}>Room Configuration</Text>
                            </Box>
                            <Box style={{...this.wizzardContentStyle, textAlign: 'center', minHeight:'min-content'}}>
                                {
                                    this.state.roomWizzardStep === 0 && <RoomFields.RoomDescription description={room.description} handler={(description) => {this.setRoomDescription(description)}} />
                                }
                                {
                                    this.state.roomWizzardStep === 1 && <RoomFields.RoomCoverImage src={room.coverImage} handler={(coverImage) => {this.setRoomCoverImage(coverImage)}} roomID={this.roomID}/>
                                }
                                {
                                    this.state.roomWizzardStep === 2 && <RoomFields.RoomVisibility visibility={room.roomVisibility} handler={(state) => {this.setRoomVisibility(state)}} />
                                }
                                {
                                    this.state.roomWizzardStep === 3 && <RoomFields.RoomManualAutomatic isAuto={room.isAuto} handler={(isAuto) => {this.setRoomAuto((isAuto))}} />
                                }
                                {
                                    this.state.roomWizzardStep === 4 && <RoomFields.RoomDates startDate={room.startDate} endDate={room.endDate} handler={(dates) => {this.setRoomDates(dates)}} />
                                }
                                {
                                    this.state.roomWizzardStep === 5 && <RoomFields.RoomSponsors roomID={this.roomID} sponsors={this.state.room.sponsor} handler={(sponsors) => {this.setRoomSponsors(sponsors)}} />
                                }
                                {
                                    this.state.roomWizzardStep === 6 && <RoomFields.RoomCCData handler={() => {}} />
                                }
                            </Box>
                            <div style={{flex:1}} />
                            <Box style={{...this.wizzardFooterStyle, minHeight:'min-content'}}>
                                { this.state.roomWizzardStep !== 0 && 
                                    <Button plain alignSelf='start' label='Back' 
                                        onClick={() => {this.setState({
                                            ...this.state,
                                            roomWizzardStep:(this.state.roomWizzardStep === 4 ?
                                                this.state.roomWizzardStep - 2 :
                                                this.state.roomWizzardStep - 1
                                                )
                                        })}}
                                        style={{textDecorationLine:'underline', transform:'translate(0px, 1.5rem)'}}
                                    /> 
                                }
                                { 
                                    this.state.roomWizzardStep !== 6 ?
                                        <Button primary alignSelf='center' disabled={!this.state.roomCanProceed} label='Next' onClick={() => this.saveRoomAndNext()} />
                                        :
                                        <Button primary alignSelf='center' label='Finish configuring your room' onClick={() => this.saveRoomAndNext()} />
                                }
                                <br />
                                { this.state.roomWizzardStep !== 6 && 
                                    <Button plain alignSelf='center' label='Skip for now'
                                    onClick={() => {this.setState({...this.state, roomCanProceed:true, roomWizzardStep:this.state.roomWizzardStep + (this.state.roomWizzardStep === 2 && this.state.room.roomVisibility === 'open' ? 2: 1)})}}
                                    style={{textDecorationLine:'underline'}}
                                /> 
                                }
                            </Box>
                        </Box>
                    </Box>
                </Layer>
            )
        } else return <></>
    }


    LotWizzard = () => {
        const { tempLot } = this.state
        if (this.state.showLotWizzard) {
            return (
                <Layer modal={false} style={{width:'100vw', height:'100vh', overflow:'auto'}}>
                    <Box style={{position:'relative', width:'100vw', height:'100vh', top:0, right:0, bottom:0, left:0, display:'block', minHeight:'fit-content'}} background='light-1' pad='medium' gap='medium'>
                        <Box style={this.wizzardClosebuttonStyle}>
                            <Button color='dark-1' onClick={() => {this.setState({...this.state, tempLot:this.initialLotState, lotEditMode:false, showLotWizzard:false})}}>
                                <Box alignContent='center' align='center'>
                                    <Close />
                                </Box>
                            </Button>
                        </Box>
                        <Box background='white' round pad='medium' width='450px' 
                            style={{margin:'0 auto 100px', flex:1, display:'flex', boxOrient:'vertical', overflow:'auto'}}
                            align='center' alignContent='center' alignSelf='center' 
                        >
                            <Box pad='small' style={{margin:'0 auto', minHeight:'min-content'}} direction='row'>
                                <Text size='xxlarge' style={{fontVariant:'all-small-caps'}}>Lot Creation</Text>
                            </Box>
                            <Box style={{...this.wizzardContentStyle, textAlign:'start', minHeight:'min-content'}}>
                                <LotFields.LotTitle title={tempLot.title} handler={(title) => this.setLotTitle(title)} />
                                <LotFields.LotDescription description={tempLot.shortDesc} handler={(description) => this.setLotDescription(description)} />
                                <LotFields.LotStartPrice currency={this.state.room.currency} startPrice={tempLot.startAmount} handler={(startPrice) => this.setLotStartAmount(startPrice)} />
                                <LotFields.LotMedia roomID={this.roomID} medias={tempLot.mediaList} handler={(media) => this.setLotMediaList(media)}/>
                                <LotFields.LotDelivery deliveryType={tempLot.deliveryType} roomID={this.roomID} handler={(state) => this.setLotDelivery(state)}/>
                                <LotFields.LotCategory category={tempLot.lotCategory} roomID={this.roomID} handler={(state) => this.setLotCategory(state)}/>
                                {/* <LotFields.LotKeywords keywords={tempLot.keywords} roomID={this.roomID} handler={(state) => this.setLotKeywords(state)}/> */}
                            </Box>
                            <div style={{flex:1}} />
                            <br />
                            <Box style={{...this.wizzardFooterStyle, minHeight:'min-content'}}>
                                <Button primary alignSelf='center' label={this.state.lotEditMode ? 'Update lot' : 'Add this lot'} onClick={() => this.saveLotAndNext()} disabled={!tempLot.title} />
                                <br />
                                {
                                    this.state.lotEditMode &&
                                    <Button plain color='accent-1' alignSelf='center' style={{textDecorationLine:'underline'}} label='Delete this lot' onClick={() => this.deleteLot()} />
                                }
                            </Box>
                        </Box>
                    </Box>
                </Layer>
            )
        } else return <></>
    }

    copyToClipboard = () => {
        navigator.clipboard.writeText(this.roomURL().toString())
    }

    roomURL = ():string => {
        const url:string = 
            window.location.protocol + '//' + 
            window.location.hostname + '/' + 
            this.state.room.domainShortName + '/' + 
            this.state.room.shortName
        return url
    }

    auctionStatus = () => {
        const now = new Date()
        const startDate = new Date(this.state.room.startDate)
        const endDate = new Date(this.state.room.endDate)

        const auctionStatus = ():'ready'|'live'|'closed'|undefined => {
            if (this.state.room.isAuto) {
                if (now <= startDate) return 'ready'
                if (now >= startDate && startDate <= endDate) return 'live'
                if (now >= startDate && startDate >= endDate) return 'closed'
            } else {
                if (this.state.room.isConfig && !this.state.room.isClosed && !this.state.room.isStarted) return 'ready'
                if (this.state.room.isConfig && !this.state.room.isClosed && this.state.room.isStarted) return 'live'
                if (this.state.room.isConfig && this.state.room.isClosed) return 'closed'
            }
            return undefined
        }

        return auctionStatus()
    }
    
    okStatus = ():JSX.Element => {
        
        let color:string = ''
        let status:string = ''

        switch(this.auctionStatus()) {
            case 'ready': color = 'dark-3'; status = 'Auctions have not started yet'; break;
            case 'live': color = 'neutral-1'; status = 'Auctions are live'; break;
            case 'closed': color = 'dark-3'; status = 'Auctions are closed'; break;
            default: break;
        }

        if (!!color && !!status) {
            return (
                <Box direction='row' align='center' gap='small'>
                    <RadialSelected color={color} size='small' />
                    <Text color={color}>{status}</Text>
                </Box>
            )
        } else return <></>
        
    }

    render() {
        const { 
            room, loading, lots
        } = this.state

        if (loading) {
            return (
                <Box>Loading Room data...</Box>
            )
        }
        else {
            return (
                <>
                {
                    this.state.showShareLink &&
                        <Layer >
                            <Box width='medium' pad='medium' gap='small' direction='column' justify='end' align='center' alignSelf='center' alignContent='center'>
                                <Box align='end' alignContent='end' alignSelf='end' fill flex>
                                    <Close onClick={() => this.setState({...this.state, showShareLink:false})}/>
                                </Box>
                                <Text weight='bold'>Sharable link</Text>
                                <Box flex width='100%' margin='16px'
                                    align='center' alignContent='center' alignSelf='center'
                                >
                                    <Text style={{wordWrap:'break-word', width:'inherit'}} >{this.roomURL().toString()}</Text>
                                </Box>
                                <Button primary color='light-3' label='Copy to Clipboard' onClick={this.copyToClipboard} />
                            </Box>
                        </Layer>
                }
                <Box>
                    <Box pad={{horizontal:'medium'}} direction='row' flex fill align='center' alignSelf='start' alignContent='center'>
                        <Menu
                            dropProps={{ align: { top: 'bottom', left: 'left' } }}
                            label={room.name}
                            items={[
                                // { label: 'Big Screen', onClick: () => {this.setState({...this.state, showBigScreen:true})} },
                                { label: 'Sharable link', onClick: () => {this.setState({...this.state, showShareLink:true})} },
                                { label: 'Delete room', onClick: () => this.deleteRoom() },
                                // { label: 'Disabled', disabled: true }
                            ]}
                        />
                    </Box>
                    <Box pad='medium' gap='20px' style={{display:'block'}}>
                        <Box background='white' round direction='row' style={{position:'relative', overflow:'auto'}}>                            
                            <Box direction='column' pad='15px' gap='20px'>
                                <Text weight='bold'>
                                    AUCTIONS ROOM
                                </Text>
                                {(!room.isConfig || (room.isAuto && !room.startDate && !room.endDate) ?
                                    <Box direction='row' align='center' gap='small'>
                                        <RadialSelected color='accent-2' size='small' />
                                        <Text color='accent-2'>Needs configuration</Text>
                                    </Box>
                                : 
                                    <>
                                        {this.okStatus()}
                                        <Performance size="20px" color='brand' style={{position:'absolute', top:16, right:16}} onClick={() => {this.setState({...this.state, showRoomWizzard:true})}} />
                                    </>
                                    
                                )}            
                                <Box direction='column' gap='medium'>
                                    {(!room.isConfig || (room.isAuto && !room.startDate && !room.endDate) ?
                                        <Button color='brand' alignSelf='start' label='Configure' onClick={() => this.setState({...this.state, showRoomWizzard:true})} />
                                        :
                                        <></>
                                    )}
                                    {(room.isAuto ? 
                                        (!!room.startDate ? 
                                            <Box gap='small'>
                                                <Box direction='row' gap='medium'>
                                                    <Text style={{minWidth:'64px'}} weight='bold'>Start</Text>
                                                    <Box>
                                                        { (room.startDate ? new Date(room.startDate).toLocaleDateString('en', {weekday: "short", year: "numeric", month: "short", day: "numeric"}) + ' - ' + new Date(room.startDate).toLocaleTimeString('en',{hour12:false}) : '-') }
                                                    </Box>
                                                </Box>
                                                <Box direction='row' gap='medium'>
                                                    <Text style={{minWidth:'64px'}} weight='bold'>End</Text>
                                                    <Box>
                                                        { (room.endDate ? new Date(room.endDate).toLocaleDateString('en', {weekday: "short", year: "numeric", month: "short", day: "numeric"}) + ' - ' + new Date(room.endDate).toLocaleTimeString('en',{hour12:false}) : '-') }
                                                    </Box>
                                                </Box>
                                                
                                                    {
                                                        new Date() <= new Date(room.startDate) && 
                                                        <Box>
                                                            <Text size='small'>Starts in</Text>
                                                            <Countdown date={room.startDate} />
                                                        </Box>
                                                    }
                                                    {
                                                        new Date() >= new Date(room.startDate) && new Date(room.startDate) <= new Date(room.endDate) && 
                                                        <Box>
                                                            <Text size='small'>Closes in</Text>
                                                            <Countdown date={room.endDate} />
                                                        </Box>
                                                    }
                                                    {
                                                        new Date() >= new Date(room.startDate) && new Date(room.startDate) >= new Date(room.endDate) && 
                                                        <></>
                                                    }
                                                    
                                            </Box>
                                        :
                                            <></>
                                        )
                                    : 
                                        <Box>
                                            <Box direction='row' gap='medium'>
                                                {
                                                    this.state.room.isConfig && !this.state.room.isStarted &&
                                                        <Button label='Open Room' onClick={() => this.manualSetRoomStatus('open')} />
                                                }
                                                {
                                                    this.state.room.isConfig && this.state.room.isStarted &&
                                                        <Button label='Close Room' onClick={() => this.manualSetRoomStatus('closed')} />
                                                }
                                            </Box>
                                        </Box>
                                    )}
                                    {(room.isConfig && ( this.auctionStatus() === 'live' || this.auctionStatus() === 'closed') &&
                                        <Box direction='row' gap='small'>
                                            <Button  label={(this.state.showBids ? 'Hide' : 'Show') + ' Bids'} onClick={() => {this.setState({...this.state, showBids:!this.state.showBids})}}/>
                                            {/* <Button  label='Show Big Screen' onClick={() => this.setState({...this.state, showBigScreen:true})} /> */}
                                        </Box>
                                    )}
                                    {
                                    this.state.showBids &&
                                        <BidsList lotList={this.state.lots} currency={this.state.room.currency} />
                                }
                                </Box>
                            </Box>
                        </Box>
                        {
                            this.auctionStatus() !== 'live' && this.auctionStatus() !== 'closed' &&
                            <Box background='white' round direction='row' style={{position:'relative', overflow:'auto'}}>
                                <Box  pad={{vertical:'15px', left:'15px', right:'47px'}} gap='20px' direction='column'>
                                    <Text weight='bold'>LOTS</Text>
                                    {
                                        !lots.length ? 
                                            <Box>
                                                <Text>This room has <Text color='accent-2'>0</Text> lots</Text>
                                            </Box> 
                                        : 
                                            <Box>
                                                <Text>This room has {lots.length} lots</Text>
                                            </Box> 
                                    }
                                    <Box direction='row' gap='small'>
                                        <Button alignSelf='start' icon={<Add size='15px' />} label='Add lots' onClick={() => this.setState({...this.state, showLotWizzard:true})} />
                                        <CheckBox label='Show lots' disabled={!lots.length} checked={this.state.showLots} onClick={() => this.setState({...this.state, showLots:!this.state.showLots})} />
                                    </Box>
                                {
                                    this.state.showLots &&
                                    <Box>
                                        {
                                            this.state.lots.map((lot, i) => 
                                                <Box direction='row' key={i} background={i%2 ? 'white' : 'light-1'} pad='8px' align='center'>
                                                    <Text>{lot.title}</Text> <div style={{flex:1}} /> <Edit size='20px' onClick={() => {this.editLot(lot)}} style={{cursor:'pointer'}} />
                                                </Box>
                                            )
                                        }
                                    </Box>
                                }
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
                <this.RoomWizzard />
                <this.LotWizzard />
                <BigScreen room={this.state.room} lots={this.state.lots} visible={this.state.showBigScreen} handler={(visibility:boolean) => this.setBigScreenVisibility(visibility)} />
                <ToastContainer position= "bottom-center" autoClose={2000} hideProgressBar={true} closeOnClick={true} pauseOnHover={true} draggable={true}/>
            </>
            )
        }
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {}
}

const mapStateToProps = (state:any) => {
    return { state };
  }

export default connect(mapStateToProps, mapDispatchToProps)(Room)
