const initState = {
    logInFormVisible:false,
    logInFormEmail:'',
    logInFormFrom:undefined,
    logInFormRoom:undefined,
    logInFormStep:0,
    userProfileVisible:false
}

const appReducer = (state = initState, action:{type:string, payload:any}) => {
    switch (action.type) {
        case 'TOGGLE_LOGIN':
            state.logInFormVisible = action.payload.visibility;
            state.logInFormEmail = action.payload.email;
            state.logInFormFrom = action.payload.from;
            state.logInFormRoom = action.payload.room;
            state.logInFormStep = action.payload.step;
            break;
        case 'TOGGLE_USER':
            state.userProfileVisible = action.payload.visibility;
            break;
        default:
            break;
    }
    return state;
}

export default appReducer;