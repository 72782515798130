import React from "react";
import { Link, withRouter } from "react-router-dom";
import axios from 'axios';
import { Button, Text, Box, TextInput, Heading } from 'grommet'

import * as ROUTES from '../../constants/routes';
import { API } from "../../constants/api";

import store from "../../store";

import * as firebase from 'firebase/app'
import 'firebase/auth'

type SignUpState = {
    email:string,
    passwordOne:string,
    error:any,
    from:'domain'|'room'|'home';
    room:string
}

const INITIAL_STATE:SignUpState = {
    email: '',
    passwordOne: '',
    error: null,
    from:'home',
    room:'',
};


export default class SignUpPage extends React.Component {

    render () {
        return (
            <Box alignContent='center' align='center'>
                <Heading textAlign='center' size='small'>Create an account</Heading>
                <SignUpForm {...this.props}/>
            </Box>
        );
    }
}

export class SignUpFormBase extends React.Component<any, SignUpState> {
    unsubscribe:any

    constructor (props:any) {
        super(props);

        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        this.unsubscribe = store.subscribe(this.handleStoreChange().bind(this))
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    handleStoreChange = () => () => {
        const {
            logInFormEmail,
            logInFormFrom,
            logInFormRoom} = store.getState().app
        this.setState({
            ...this.state,
            email:logInFormEmail,
            from:logInFormFrom!,
            room:logInFormRoom!
        })
    }

    onSubmit = (event: any) => {
        const { email, passwordOne } = this.state;
        firebase.auth().createUserWithEmailAndPassword(email, passwordOne)
            .then((authUser:any) => {
                axios.post(API.users, {email})
                    .then(res => {})
                    .catch((error:any) => {
                        this.setState({ error });
                    })
            })
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                // this.props.history.push(ROUTES.SIGN_UP_VALIDATE_TOKEN)
                this.props.history.push(ROUTES.USER_DASHBOARD)
            }
        )
        .catch((error:any) => {
            this.setState({ error });
        });

        event.preventDefault();
    }
  
    onChange = (event: any) => {
        this.setState({ ...this.state, [event.target.name]: event.target.value });
    };
  
    render() {
        const {
            email,
            passwordOne,
            error,
        } = this.state;

        const isInvalid =
            passwordOne === '' ||
            email === ''
        
        return (
            <Box alignContent='center' align='center'>
                <Text textAlign='center'>Fill in your email and password to create a new account.</Text>
                <br />
                <TextInput
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    type="text"
                    placeholder='Email Address'
                    style={{textAlign:'center'}}
                />
                <br />
                <TextInput
                    autoComplete='new-password'
                    name="passwordOne"
                    value={passwordOne}
                    onChange={this.onChange}
                    type="password"
                    placeholder='Password'
                    style={{textAlign:'center'}}
                />
                <br />
                <Button primary disabled={isInvalid} label='Create an account' onClick={this.onSubmit} />

                {error && <p>{error.message}</p>}
            </Box>
        );
    }
}

export class SignUpLink {
    render () {
        return (<p>
            Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
        </p>);
    }
}

const SignUpForm = withRouter(SignUpFormBase)
