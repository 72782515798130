import React, { CSSProperties } from 'react'
import { connect } from 'react-redux';
import store from '../../store';
import { Grommet, Layer, Box, Button, Text } from 'grommet';
import { theme } from '../../theme';
import { toggleUserModal } from '../../store/actions/appActions';
import { Close } from 'grommet-icons';

interface Props {
    
}

interface State {
    
}

interface DispatchProps {toggleUserModal: (visibility:boolean) => void}

type MergedProps = DispatchProps & Props

class UserProfileModal extends React.Component<MergedProps, State> {
    state = {
        visibility:false
    }

    unsubscribe: any;

    componentDidMount() {
        this.unsubscribe = store.subscribe(this.handleStoreChange().bind(this))
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    handleStoreChange = () => () => {
        const {userProfileVisible} = store.getState().app
        this.setState({visibility:userProfileVisible})
    }

    closebuttonStyle:CSSProperties = {
        position: 'relative',
        zIndex: 1000,
        width: '100%',
        alignItems: 'flex-end',
    }

    contentStyle:CSSProperties = {
        textAlign: 'center',
        width: '100%',
        maxWidth: '450px'
    }

    render() {
        return (
            <>
            {this.state.visibility && (
                <Grommet theme={theme}>
                    <Layer
                        modal={false}
                        style={{width:'100vw', height:'100vh', overflow:'auto', zIndex:1100}}
                    >
                        <Box 
                            style={{position:'relative', width:'100vw', height:'100vh', top:0, right:0, bottom:0, left:0, display:'block', minHeight:'fit-content'}}
                            background='light-1' pad='medium' gap='medium'>
                            <Box style={this.closebuttonStyle}>
                                <Button color='dark-1' onClick={() => {this.props.toggleUserModal(false)}}>
                                    <Box alignContent='center' align='center'>
                                        <Close />
                                    </Box>
                                </Button>
                            </Box>
                            <Box background='white' round pad='medium' width='450px' 
                                style={{margin:'0 auto 100px', display:'flex', boxOrient:'vertical', overflow:'auto'}}
                                align='center' alignContent='center' alignSelf='center' 
                            >
                                <Box pad='small' style={{margin:'0 auto', minHeight:'min-content'}} direction='row'>
                                    <Text size='xxlarge' style={{fontVariant:'all-small-caps'}}>User profile</Text>
                                </Box>
                                <Box style={{...this.contentStyle, textAlign: 'center', minHeight:'min-content'}}></Box>
                            </Box>
                        </Box>
                    </Layer>
                    </Grommet>
            )}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch:any):DispatchProps => {
    return {
        toggleUserModal: (visibility:boolean) => dispatch(toggleUserModal(visibility))
    }
}

export default connect<null, DispatchProps>(null, mapDispatchToProps)(UserProfileModal)