import React, { Component, ReactNode } from 'react'
import { Box, Image, Text, Button } from 'grommet'
import { /*Favorite,*/ FormUp, FormDown, RadialSelected, Lock, FormCheckmark, FormClose } from 'grommet-icons'
import { withRouter, match, StaticContext } from 'react-router'
import { History, Location } from 'history'
import Interweave from 'interweave';
import { toggleLoginModal } from "../../store/actions/appActions";
import { AuthUserContext } from '../Session'
import * as firebase from 'firebase'
import 'firebase/firestore'
import { connect } from 'react-redux'

interface DispatchProps {toggleLoginModal: (visibility:boolean, email:string, from?:'domain'|'room'|'home', room?:string,step?:number) => void}

interface Props {
    room:any
    isFav:boolean
    showImage:boolean
    showDescription:boolean
    history: History<any>
    location: Location<any>
    match: match<any>
    staticContext?: StaticContext | undefined
    children?: ReactNode
    domainName:string
}

class RoomCard extends Component<Props & DispatchProps, {showDescription:boolean}> {

    constructor(props:Props & DispatchProps) {
        super(props)

        this.state = {
            showDescription:false
        }
    }

    componentWillReceiveProps(nextProps:any) {
        if (nextProps.showDescription !== this.state.showDescription ) this.setState({...this.state, showDescription:nextProps.showDescription})
    }

    auctionStatus = () => {
        const now = new Date()
        const startDate = new Date(this.props.room.startDate)
        const endDate = new Date(this.props.room.endDate)

        const auctionStatus = ():'ready'|'live'|'closed'|undefined => {
            if (this.props.room.isAuto) {
                if (now <= startDate) return 'ready'
                if (now >= startDate && startDate <= endDate) return 'live'
                if (now >= startDate && startDate >= endDate) return 'closed'
            } else {
                if (this.props.room.isConfig && !this.props.room.isClosed && !this.props.room.isStarted) return 'ready'
                if (this.props.room.isConfig && !this.props.room.isClosed && this.props.room.isStarted) return 'live'
                if (this.props.room.isConfig && this.props.room.isClosed) return 'closed'
            }
            return undefined
        }

        return auctionStatus()
    }
    
    okStatus = ():JSX.Element => {
        
        let color:string = ''
        let status:string = ''

        switch(this.auctionStatus()) {
            case 'ready': color = 'dark-3'; status = 'Auctions have not started yet'; break;
            case 'live': color = 'neutral-1'; status = 'Auctions are live'; break;
            case 'closed': color = 'dark-3'; status = 'Auctions are closed'; break;
            default: break;
        }

        if (!!color && !!status) {
            return (
                <Box direction='row' align='center' pad={{bottom:'16px', horizontal:'16px'}} gap='small'>
                    <RadialSelected color={color} size='small' />
                    <Text size='small' color={color}>{status}</Text>
                </Box>
            )
        } else return <></>
        
    }

    requestAccess = (profileID:string) => {
        if (!!profileID) {
            const roomAccessRequested = {[this.props.room.uid]:0}
            firebase.firestore().collection('userProfiles').doc(profileID).set({roomAccess:firebase.firestore.FieldValue.arrayUnion(roomAccessRequested)},{merge:true})
        } else this.props.toggleLoginModal(true, '', 'home', undefined, 0)
    }

    render() {
        const { coverImage, name, shortName, domainShortName, roomVisibility, description, uid } = this.props.room
        return (
            <AuthUserContext.Consumer>
                {authUser => 
                    <Box direction="column"
                        round
                        border
                        overflow='hidden'
                        width='280px'
                        background='white'
                        style={{
                            boxShadow:'0 0 0.5em 0 #dedede',
                            WebkitBoxShadow:'0 0 0.5em 0 #dedede'
                        }}
                        margin={{bottom:'medium', horizontal:'small'}}
                        alignContent='center' alignSelf='start'
                    >
                        <Box height='100px' style={{display:(this.props.showImage ? 'flex' : 'none'), background: (!!coverImage ? 'white' : 'linear-gradient(158deg, rgba(0,190,255,1) 0%, rgba(81,81,229,1) 100%)'), position:'relative'}}>
                            <Image src={coverImage} fit='contain' style={{maxHeight:'100px'}} />
                            {/* <Box 
                                pad='small' round='100%' background='white' style={{position:'absolute', top:'8px', right:'8px'}}
                                onClick={() => alert('Clicked')}
                            >
                                <Favorite size='16px' fill='dark-1'/>
                            </Box> */}
                        </Box>
                        <Box direction='row' pad='medium' gap='16px'
                            background='white'
                        >
                            <Box direction='row' flex>
                                <Box>
                                    <Text size='large' weight='bold' textAlign='start'>
                                        {name}
                                    </Text>
                                    <Text size='small' textAlign='start'>by {this.props.domainName}</Text>
                                </Box>
                                <div style={{flex:1}} />
                                <Box>
                                    <Button plain icon={(this.state.showDescription ? <FormUp color='brand' /> : <FormDown color='brand' /> )}
                                        onClick={() => this.setState({showDescription:!this.state.showDescription})}
                                    />
                                </Box>
                            </Box>
                            {/* <Box style={{display:(this.props.showImage ? 'none' : 'flex'), height:'fit-content'}}
                                pad='small' round='100%' background='white'
                                onClick={() => alert('Clicked')}
                            >
                                <Favorite size='16px' fill='dark-1'/>
                            </Box> */}
                        </Box>
                        {
                            this.state.showDescription &&
                                <Box pad={{bottom:'16px', horizontal:'16px'}} background='white'>
                                    <Interweave 
                                        allowList={['strong', 'del', 'em', 'u', 'p', 'ul', 'ol', 'li', 'h3', 'h4', 'br']}
                                        content={description} />
                                </Box>
                        }
                        {this.okStatus()}
                        {roomVisibility === 'open' ? 
                            <Box
                                background='brand'
                                flex width='100%'
                                pad='10px'
                                align='center' alignContent='center' alignSelf='center'
                                style={{cursor:'pointer'}}
                                onClick={() => this.props.history.push(`/${domainShortName}/${shortName}`)}
                            >
                                <Text color='white'>
                                    Visit Room
                                </Text>
                            </Box>
                        :
                            !!authUser.roomAccess && authUser.roomAccess.find((access) => {
                                return Object.keys(access)[0] === uid
                            }) ?
                            <>
                            {!!authUser.roomAccess && authUser.roomAccess.find((access) => {
                                return Object.keys(access)[0] === uid
                            })[uid] === -1 &&
                                <Box
                                    background='light-3'
                                    flex width='100%'
                                    pad='10px'
                                    align='center' alignContent='center' alignSelf='center'
                                >
                                    <Box direction='row' gap='8px' align='center'>
                                        <FormClose size='small' />
                                        <Text>
                                            Access Denied
                                        </Text>
                                    </Box>
                                </Box>
                            }
                            {!!authUser.roomAccess && authUser.roomAccess.find((access) => {
                                return Object.keys(access)[0] === uid
                            })[uid] === 0 &&
                                <Box
                                    background='light-3'
                                    flex width='100%'
                                    pad='10px'
                                    align='center' alignContent='center' alignSelf='center'
                                >
                                    <Box direction='row' gap='8px' align='center'>
                                        <FormCheckmark size='small' />
                                        <Text>
                                            Access Requested
                                        </Text>
                                    </Box>
                                </Box>
                            }
                            {!!authUser.roomAccess && authUser.roomAccess.find((access) => {
                                return Object.keys(access)[0] === uid
                            })[uid] === 1 &&
                                <Box
                                    background='brand'
                                    flex width='100%'
                                    pad='10px'
                                    align='center' alignContent='center' alignSelf='center'
                                    style={{cursor:'pointer'}}
                                    onClick={() => this.props.history.push(`/${domainShortName}/${shortName}`)}
                                >
                                    <Text color='white'>
                                        Visit Room
                                    </Text>
                                </Box>
                            }
                            </>
                            :
                                <Box
                                    background='accent-1'
                                    flex width='100%'
                                    pad='10px'
                                    align='center' alignContent='center' alignSelf='center'
                                    style={{cursor:'pointer'}}
                                    onClick={() => this.requestAccess(authUser.profileID)}
                                >
                                    <Box direction='row' gap='8px' align='center'>
                                        <Lock color='white' size='small' />
                                        <Text color='white'>
                                            Request Access
                                        </Text>
                                    </Box>
                                </Box>
                            }
                    </Box>
                }
            </AuthUserContext.Consumer>
        )
    }
}

const mapDispatchToProps = (dispatch:any):DispatchProps => {
    return {
        toggleLoginModal: (visibility:boolean, email:string, from?:'domain'|'room'|'home', room?:string, step?:number) => dispatch(toggleLoginModal(visibility, email, from, room, step))
    }
}

export default connect(null,mapDispatchToProps)(withRouter(RoomCard))