import React, { CSSProperties } from 'react'
import { TextInput, Box, Text, Button, Image, FormField, TextArea } from 'grommet'
import * as firebase from "firebase/app";
import 'firebase/storage'
import Dropzone from 'react-dropzone';
import { Add, FormTrash } from 'grommet-icons';
import TagsInput from 'react-tagsinput'
import Select from 'react-select'


interface LotFieldProps {required?:boolean, handler:(state:any) => void}

interface LotTitleProps {title:string}
type LotTitleMergeProps = LotFieldProps & LotTitleProps
export class LotTitle extends React.Component<LotTitleMergeProps, any> {

    constructor(props:LotTitleMergeProps) {
        super(props)

        this.state = {
            title:''
        }
    }

    componentWillReceiveProps(nextProps:any) {
        if (!!nextProps.title !== this.state.title) {
            this.setState({...this.state, title:nextProps.title})
        }
    }

    componentDidUpdate() {
        this.props.handler(this.state.title)
    }
    
    render () {

        return (
            <FormField label='Title'>
                <TextInput 
                    required={this.props.required}
                    type='text'
                    value={this.state.title}
                    onChange={event => this.setState({title:event.target.value})}
                />
            </FormField>
        )
    }
}

interface LotDescriptionProps {description?:string}
type LotDescriptionMergeProps = LotFieldProps & LotDescriptionProps
export class LotDescription extends React.Component<LotDescriptionMergeProps, any> {
    
    constructor(props:any) {
        super(props)

        this.state = {
            editorContent:null,
            description:''
        }
    }

    componentWillReceiveProps(nextProps:any) {
        if (!!nextProps.description !== this.state.description) {
            this.setState({...this.state, description:nextProps.description})
        }
    }

    componentDidUpdate() {
        this.props.handler(this.state.description)
    }

    render () {
    
        return (
            <FormField label='Description'>
                <TextArea
                    value={this.state.description}
                    onChange={(event:any) => this.setState({description:event.target.value})}
                    maxLength={250}
                />
            </FormField>
        )
    }
}

interface LotStartPriceProps {currency:string, startPrice:number|undefined}
type LotStartPriceMergeProps = LotFieldProps & LotStartPriceProps
export class LotStartPrice extends React.Component<LotStartPriceMergeProps, any> {

    constructor(props:LotStartPriceMergeProps) {
        super(props)

        this.state = {
            startPrice:undefined,
        }
    }

    componentWillReceiveProps(nextProps:LotStartPriceMergeProps) {
        if (nextProps.startPrice === undefined) {
            this.setState({...this.state, startPrice:''})
        } else if (nextProps.startPrice !== this.state.startPrice) {
            const value = ('' + nextProps.startPrice).replace(/\D/g,'')
            this.setState({...this.state, startPrice:parseInt(value,10)})
        }
    }

    setStartPrice = (value:string|number) => {
        this.setState({startPrice:('' + value).replace(/\D/g,'')})
    }

    componentDidUpdate() {
        this.props.handler(this.state.startPrice)
    }

    render () {

        return (
            <FormField label='Start amount'>
                <Box direction='row' align='center'>
                    <TextInput 
                        plain
                        placeholder='e.g.: 1000'
                        required={this.props.required}
                        type='number'
                        pattern="[0-9]"
                        min='0'
                        step='1'
                        value={this.state.startPrice}
                        onChange={event => this.setStartPrice(event.target.value)}
                        style={{textAlign:'right'}}
                    />
                    <Text>{this.props.currency}</Text>
                </Box>
            </FormField>
        )
    }
}

interface LotMediaProps {roomID:string, medias?:string[]}
type LotMediaMergeProps = LotMediaProps & LotFieldProps
export class LotMedia extends React.Component<LotMediaMergeProps,{medias:string[]}> {
    constructor(props:any) {
        super(props)

        this.state = {
            medias:[]
        }
    }

    componentWillReceiveProps(nextProps:LotMediaMergeProps) {
        if (!!nextProps.medias && nextProps.medias !== this.state.medias) {
            this.setState({...this.state, medias:nextProps.medias})
        }
    }

    componentDidMount() {
        if (!!this.props.medias) {
            this.setState({...this.state, medias:[...this.props.medias]})
        }
    }

    componentDidUpdate() {
        this.props.handler(this.state.medias)
    }

    onDrop = (files:File[]) => {
        const imageHash = Math.random().toString(36).substr(2, 9)
        const ref = firebase.storage().ref().child(`${this.props.roomID}/lots/medias/${imageHash}`)

        files.forEach((file) => {
            ref.put(file)
        .then((snapshot) => {
            snapshot.ref.getDownloadURL()
                .then((url:string) => {
                    this.setState({
                        ...this.state,
                        medias:[...this.state.medias, url]})
                })
                .catch()
            })
            .catch()
        });
    }

    render() {
        return (
            <Box pad='small' gap='small'>
                <FormField label='Photos'>
                {
                    this.state.medias.length <= 2 &&
                    <Dropzone onDrop={acceptedFiles => this.onDrop(acceptedFiles)}>
                        {({getRootProps, getInputProps}) => (
                            <Box {...getRootProps()} round='small' border={{color:'brand', style:'dashed', size:'small'}} height='48px' pad='medium' style={{position:'relative'}}>
                                <input {...getInputProps()} />
                                <Box direction='row' gap='small' style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)', alignItems:'center'}}>
                                    <Text size='medium'>Add media (max. 3)</Text>
                                    <Add size='36px' />
                                </Box>
                            </Box>
                        )}
                    </Dropzone>
                }
                </FormField>
                <Box direction='row' gap='small' pad='small' border={{color:'light-3', style:'dashed', size:'small'}} round='small' style={{minHeight:'78px'}}>
                    {this.state.medias && this.state.medias.map((media:string) =>
                        <Box key={Math.random().toString()} direction='column' gap='small' alignContent='center' align='center'>
                            <Image src={media} width='72px' />
                            <Button plain label='delete' icon={<FormTrash />} onClick={() => {
                                const {medias} = this.state
                                medias.forEach((item, i) => {
                                    if (item === media) medias.splice(i, 1)
                                })
                                this.setState({medias})
                            }}/>
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }
}

interface LotDeliveryProps {deliveryType:number, roomID:string}
type LotDeliveryMergeProps = LotFieldProps & LotDeliveryProps
export class LotDelivery extends React.Component<LotDeliveryMergeProps, any> {

    setRef: (ref: any) => void;
    file: any;

    constructor(props:LotDeliveryMergeProps) {
        super(props)

        this.state = {
            deliveryType:7,
            value:'',
            attachment:undefined,
            deliveryDetails:undefined,
            deliveryService:'',
            deliveryDays:14,
        }

        this.setRef = (ref) => {
            this.file = ref
        }

        this.onChange = this.onChange.bind(this)
    }

    objectOptions = [
        {label:'None', value: 7},
        // {label:'TombolaOffline', value: 6},
        // {label:'TombolaOnline', value: 5},
        {label:'Delivered by', value: 4},
        {label:'Takeaway', value: 3},
        {label:'Virtual', value: 2},
        // {label:'VirtualDeliery', value: 1},
        // {label:'AccessPass', value: 0}
    ]

    search(value:number, array:{label:string,value:number}[]): {label:string,value:number}|{} {
        let found:number = NaN
        array.forEach((object,index) => {
            if (object.value === value) found = index
        })
        return array[found]
    }

    componentDidMount () {
        const nextValue = this.search(this.props.deliveryType, this.objectOptions)
        this.setState({...this.state, value: nextValue})
    }
    
    componentWillReceiveProps(nextProps:any) {
        if (!!nextProps.deliveryType !== this.state.deliveryType) {
            const nextValue = this.search(nextProps.deliveryType, this.objectOptions)
            this.setState({...this.state, deliveryType:nextProps.deliveryType, value: nextValue})
        }
    }

    componentDidUpdate() {
        this.props.handler(this.state)
    }

    onUpload = () => {
        const docHash = Math.random().toString(36).substr(2, 9)
        const ref = firebase.storage().ref().child(`${this.props.roomID}/lots/attachments/${docHash}.pdf`)

        ref.put(this.file.files[0])
        .then((snapshot) => {
            snapshot.ref.getDownloadURL()
                .then((url:string) => {
                    this.setState({
                        ...this.state,
                        attachment:url})
                })
                .catch()
            })
            .catch()
    }

    onSelectChange = (value: any) => {
        if (value !== this.state.value) this.setState({ ...this.state, value });
    };

    onChange = (event: any) => {
        this.setState({ ...this.state, [event.target.name]: event.target.value });
    };

    render () {

        return (
            <FormField label='Delivery'>
                <Box direction='row' width='100%' align='center'>
                    <div style={{display:'block', width:'100%'}}>
                        <Select
                            className='select'
                            id='delivery'
                            options={this.objectOptions}
                            value={this.state.value}
                            onChange={this.onSelectChange}
                        />
                    </div>
                    {
                        this.state.value.value === 2 && 
                        <Box direction='row'>
                            <TextInput plain type='file' ref={this.setRef}  accept="application/pdf" />
                            <Button label='Upload' onClick={() => this.onUpload()} />
                        </Box>
                    }
                    {
                        this.state.value.value === 4 && 
                        <Box direction='row' gap='small' align='center'>
                            <TextInput name='deliveryService' placeholder='Service' plain type='text' onChange={(ev) => this.onChange(ev)} value={this.state.deliveryService} />
                            <Text>within</Text>
                            <TextInput name='deliveryDays' placeholder='14' plain type='number' onChange={(ev) => this.onChange(ev)} value={this.state.deliveryDays} />
                            <Text>days</Text>
                        </Box>
                    }
                </Box>
            </FormField>
        )
    }
}

interface LotCategoryProps {category:number, roomID:string}
type LotCategoryMergeProps = LotFieldProps & LotCategoryProps
export class LotCategory extends React.Component<LotCategoryMergeProps, any> {
    constructor(props:any) {
        super(props);
        this.state = {
            category:0,
            value:''
        };
    
    }

    objectOptions = [
        {label:'Other', value:0},
        {label:'Automotive', value:1},
        {label:'Clothing & Accessories', value:2},
        {label:'Electronics', value:3},
        {label:'Entertainment', value:4},
        {label:'Event Tickets', value:5},
        {label:'Food & Beverages', value:6},
        {label:'Golf', value:7},
        {label:'Health & Beauty', value:8},
        {label:'Health & Fitness', value:9},
        {label:'Home Improvement', value:10},
        {label:'Hotels', value:11},
        {label:'Jewelry & Watches', value:12},
        {label:'Services', value:13},
        {label:'Sports', value:14},
        {label:'Specialty', value:15},
        {label:'Tools', value:16},
        {label:'Travel', value:17},
    ]

    search(value:number, array:{label:string,value:number}[]): {label:string,value:number}|{} {
        let found:number = NaN
        array.forEach((object,index) => {
            if (object.value === value) found = index
        })
        return array[found]
    }

    componentDidMount () {
        const nextValue = this.search(this.props.category, this.objectOptions)
        this.setState({...this.state, value: nextValue})
    }

    componentWillReceiveProps(nextProps:any) {
        if (!!nextProps.category !== this.state.category) {
            const nextValue = this.search(nextProps.category, this.objectOptions)
            this.setState({...this.state, category:nextProps.category, value: nextValue})
        }
    }

    componentDidUpdate() {
        this.props.handler(this.state.value)
    }

    onChange = (value: any) => {
        if (value !== this.state.value) this.setState({ ...this.state, value });
    };

    render () {

        return (
            <FormField label='Category'>
                <Select
                    className='select'
                    id='categories'
                    options={this.objectOptions}
                    value={this.state.value}
                    onChange={this.onChange}
                />
            </FormField>
        )
    }
}

interface LotKeywordsProps {keywords:string[], roomID:string}
type LotKeywordsMergeProps = LotFieldProps & LotKeywordsProps
export class LotKeywords extends React.Component<LotKeywordsMergeProps,{keywords:string[]}> {

    constructor(props:any) {
        super(props);
 
        this.state = {
            keywords: []
        };

        this.handleChange = this.handleChange.bind(this)
    }

    componentWillReceiveProps(nextProps:LotKeywordsMergeProps) {
        if (!!nextProps.keywords && nextProps.keywords !== this.state.keywords) {
            this.setState({...this.state, keywords:nextProps.keywords})
        }
    }

    componentDidUpdate() {
        this.props.handler(this.state.keywords)
    }

    handleChange(keywords:string[]) {
        this.setState({...this.state, keywords})
    }

    tagsInputStyle:CSSProperties = {
        boxSizing: 'border-box',
        fontSize: 'inherit',
        fontFamily: 'inherit',
        border: 'none',
        padding: '9px',
        outline: 'none',
        background: 'transparent',
        color: 'inherit',
        fontWeight: 600,
        margin: 0,
        width: '100%',
    }

    render() {
        const { keywords } = this.state;
        return (
            <FormField label='Keywords'>
                <Box direction='row' align='center'>
                    <TagsInput value={keywords} onChange={this.handleChange} />
                </Box>
            </FormField>
        )
    }
}