const environment = (env:string) => {
  switch(env) {
      case 'millionbids.com': return process.env.REACT_APP_PRD_FUNCTIONS
      case 'beta.millionbids.com': return process.env.REACT_APP_BTA_FUNCTIONS
      default: return process.env.REACT_APP_STG_FUNCTIONS
  }
}

export const API_PATH = environment(document.location.host) + '/api';

export const API = {
    users: API_PATH + '/users',
    domains: API_PATH + '/domains',
    organizations: API_PATH + '/organizations',
    rooms: API_PATH + '/rooms',
    lots: API_PATH + '/lots',
    bids: API_PATH + '/bids',
}