import React from 'react';

export default class NotFound extends React.Component<any> {
    render () {
        return (
            <div>
                <h1>404 Not found!</h1>
                <h3>No match for <code>{this.props.location.pathname}</code></h3>
            </div>
        )
    }
}