import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const stagingConfig = {
    apiKey: process.env.REACT_APP_STG_API_KEY,
    authDomain: process.env.REACT_APP_STG_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_STG_DATABASE_URL,
    projectId: process.env.REACT_APP_STG_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STG_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_STG_MESSAGING_SENDER_ID,
}

const betaConfig = {
    apiKey: process.env.REACT_APP_BTA_API_KEY,
    authDomain: process.env.REACT_APP_BTA_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_BTA_DATABASE_URL,
    projectId: process.env.REACT_APP_BTA_PROJECT_ID,
    storageBucket: process.env.REACT_APP_BTA_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_BTA_MESSAGING_SENDER_ID,
}

const prodConfig = {
    apiKey: process.env.REACT_APP_PRD_API_KEY,
    authDomain: process.env.REACT_APP_PRD_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PRD_DATABASE_URL,
    projectId: process.env.REACT_APP_PRD_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PRD_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PRD_MESSAGING_SENDER_ID,
}

const environment = (env:any) => {
    console.log(env)
    switch(env) {
        case 'millionbids.com': return prodConfig
        case 'beta.millionbids.com': return betaConfig
        default: return stagingConfig
    }
}

const config = environment(document.location.host)

class Firebase {
    
    constructor() {
        if (!app.apps.length) {
            app.initializeApp(config);
        }
    }

}
  
export default Firebase