import React from 'react';
// import CreateDomain from '../CreateDomain/CreateDomain';
import { Box } from 'grommet';
import CreateDomainNew from '../CreateDomain/CreateDomainNew';

export default class LandingPage extends React.Component {
    render () {
        return (
            <Box direction='column'>
                {/* <CreateDomain /> */}
                <CreateDomainNew />
            </Box>
        )
    }
}