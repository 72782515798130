import React from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'
import * as ROUTES from '../../constants/routes';
import {default as SignOutButton} from '../SignOut';
import { AuthUserContext, withAuthentication } from '../Session';
import { Box, Button } from 'grommet';
import { connect } from "react-redux";
import { toggleLoginModal, toggleUserModal } from '../../store/actions/appActions';
import { Menu as MenuIcon, Close, User, Home, Tools, Achievement } from 'grommet-icons'
import './Navigation.scss'

const menuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: '36px',
    top: '36px',
    transform: 'translate(0, -4px)',
    zIndex:'unset'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {},
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top:'0px'
  },
  bmMenu: {
    background: '#00bbef',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
    width: 'inherit',
    overflow: 'hidden'
  },
  bmMorphShape: {
    fill: '#00bbef'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
    overflow: 'hidden',
    width:'inherit'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    position: 'fixed',
    height: '100%',
    top:'0px',
    left:'0px',
    width:'100%',
    background: 'rgba(0, 0, 0, 0.3)',
  }
}
class Navigation extends React.Component<any,any> {
    static contextType = AuthUserContext
  
    constructor(props:any) {
      super (props)

      this.state = {
        menuOpen:false
      }
    }

    toggleMenu () {
      this.setState((state:any) => ({menuOpen: !state.menuOpen}))
    }

    handleStateChange (state:any) {
      this.setState({menuOpen: state.isOpen})  
    }

    NavigationAuth = () => (
        <Box direction='column' gap='medium' style={{position:'relative', height:'100%'}}>
            <Link className='linkSideNav' onClick={() => {this.toggleMenu(); this.props.toggleUserModal(true)}} to='#'><Box direction='row' align='center' gap='16px'><User color='white' /> User Profile</Box></Link>
            <Link className='linkSideNav' onClick={() => this.setState({...this.state, menuOpen:!this.state.menuOpen})} to={ROUTES.LANDING}><Box direction='row' align='center' gap='16px'><Home color='white' /> Home</Box></Link>
            <Link className='linkSideNav' onClick={() => this.setState({...this.state, menuOpen:!this.state.menuOpen})} to={ROUTES.USER_DASHBOARD}><Box direction='row' align='center' gap='16px'><Tools color='white' />Auctions Rooms</Box></Link>
            <Link className='linkSideNav' onClick={() => this.setState({...this.state, menuOpen:!this.state.menuOpen})} to={ROUTES.USER_BIDS}><Box direction='row' align='center' gap='16px'><Achievement color='white' />My Bids</Box></Link>
            {/* { this.checkRooms() && <Link className='linkSideNav' to={`${ROUTES.DASHBOARD}r/${Object.keys(this.state.rooms[0])}`}>Admin</Link>} */}
            <div style={{flex:1}} />
            <Box style={{position:'absolute', bottom:'50px', left:'32px', right:'32px'}} >
              <SignOutButton />
            </Box>
        </Box>
    );

    NavigationNonAuth = () => (
        <Box direction='column' gap='medium' style={{position:'relative', height:'100%'}}>
            <Link className='linkSideNav' onClick={() => this.setState({...this.state, menuOpen:!this.state.menuOpen})} to={ROUTES.LANDING}><Box direction='row' align='center' gap='16px'><Home color='white' /> Home</Box></Link>
            <div style={{flex:1}} />
            <Box style={{position:'absolute', bottom:'50px', left:'32px', right:'32px'}} >
              <Button primary color='white' label='Log in' onClick={() => {
                this.toggleMenu()
                this.props.toggleLoginModal(true,'','home',undefined,0)
              }} />
            </Box>
        </Box>
    );

  render () {
    return (
      <Box direction="row" pad='small' width='80px' height='100%' align='center' alignContent='center' alignSelf='center' style={{position:'absolute', zIndex:2, height:'0', width:'0'}}>
        <Menu customCrossIcon={<Close color='white' />} customBurgerIcon={<MenuIcon color='brand' />} styles={menuStyles} right isOpen={ this.state.menuOpen } onStateChange={(state) => this.handleStateChange(state)}>
        <AuthUserContext.Consumer>
          {(authUser) =>
            !!authUser.authUser ? <this.NavigationAuth /> : <this.NavigationNonAuth />
          }
        </AuthUserContext.Consumer>
        </Menu>
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        toggleLoginModal: (visibility:boolean, email:string, from?:'domain'|'room'|'home', room?:string, step?:number) => dispatch(toggleLoginModal(visibility, email, from, room, step)),
        toggleUserModal: (visibility:boolean) => dispatch(toggleUserModal(visibility))
    }
}

export default withAuthentication(connect(null,mapDispatchToProps)(Navigation));