export const toggleLoginModal = (visibility:boolean, email?:string, from?:'domain'|'room'|'home', room?:string, step?:number) => {
    return (dispatch: (action: { type: string; payload:{visibility: boolean; email?:string; from?:'domain'|'room'|'home'; room?:string, step?:number}}) => void, getState: any) => {
        dispatch({type: 'TOGGLE_LOGIN', payload:{visibility, email, from, room, step}})
    }
}

export const toggleUserModal = (visibility:boolean) => {
    return (dispatch: (action: { type: string; payload:{visibility: boolean}}) => void, getState: any) => {
        dispatch({type: 'TOGGLE_USER', payload:{visibility}})
    }
}