import React, { Component } from 'react'

interface Props {
    
}
interface State {
    
}

export default class LotCard extends Component<Props, State> {
    state = {}

    render() {
        return (
            <div>
                
            </div>
        )
    }
}
