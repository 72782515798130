import React, { Component } from 'react'
import { Box, CheckBox } from 'grommet';
import SearchBox from '../SearchBox';
import { RoomCard } from '../Cards';
import * as firebase from 'firebase/app'
import 'firebase/firestore'

interface State {
    showImages:boolean
    showDescriptions:boolean
    showPrivateRooms:boolean
    rooms: any[]
    domains: any[]
    filteredRooms: any[]
    filterTerm:string
}
export default class Rooms extends Component<any,State> {

    userDashboardRoomList:any

    constructor(props:any) {
        super(props)

        this.userDashboardRoomList = React.createRef()

        this.state = {
            showImages:true,
            showDescriptions:false,
            showPrivateRooms:false,
            rooms: [],
            domains: [],
            filteredRooms: [],
            filterTerm:'string'
        }
    }

    activeRoomsInCollection = firebase.firestore().collection('rooms').where('active', '==', true)
    activeDomains = firebase.firestore().collection('domains').where('active', '==', true)

    componentDidMount () {
        this.activeRoomsInCollection.onSnapshot((querySnapshot) => {
            let rooms:any[] = [];
            querySnapshot.forEach((doc) => {
                    rooms.push({
                        uid:doc.id,
                        coverImage:doc.data().coverImage,
                        name:doc.data().name,
                        shortName:doc.data().shortName,
                        domainShortName:doc.data().domainShortName,
                        roomVisibility:doc.data().roomVisibility,
                        isAuto:doc.data().isAuto,
                        isArchived:doc.data().isArchived,
                        isConfig:doc.data().isConfig,
                        isStarted:doc.data().isStarted,
                        startDate:doc.data().startDate,
                        endDate:doc.data().endDate,
                        description:doc.data().description
                    });
                    this.setState({...this.state, rooms, filteredRooms:rooms})
            });
        });

        this.activeDomains.onSnapshot((querySnapshot) => {
            let domains:any[] = []
            querySnapshot.forEach((doc) => {
                domains.push({shortName:doc.data().shortName, name:doc.data().name})
                this.setState({...this.state, domains})
            })
        })
    }

    componentWillUnmount () {
        this.activeRoomsInCollection.onSnapshot(() => {})
        this.activeDomains.onSnapshot(() => {})
    }

    missingBlocks = (count:number) => {
        let blocks:JSX.Element[] = []
        if (count !== 3) {
            for (let i = 0; i < count ; i++) {
                blocks.push(<Box key={i} width='280px' margin='small' />)
            }
        }
        return blocks
    }

    searchDomainShortname = ( value:string, array:any[]):string => {
        let domainName:string = ''
        array.forEach((obj) => {
            if (obj.shortName === value) domainName = obj.name
        })
        return domainName
    }

    updateFilterTerm = (term:string) => {
        if (term !== this.state.filterTerm) {
            this.setState({...this.state, filterTerm:term}, () => {
                this.filterRooms(this.state.filterTerm)
            })
        }
    }

    filterRooms = (term:string) => {
        let filteredResult:any[] = []

        this.state.rooms.forEach(room => {
            if (
                room.name.toLowerCase().includes(term.toLowerCase()) || 
                room.description.toLowerCase().includes(term.toLowerCase()) ||
                room.domainShortName.includes(term.replace(/\s/g, '').toLowerCase())
            ) {
                filteredResult.push(room)
            }
        })

        this.setState({...this.state, filteredRooms:filteredResult})
    }

    render() {
        return (
            <Box overflow='auto'>
                <Box
                    align='center' pad={{vertical:'0px', horizontal:'medium'}} gap='6px' alignContent='start' alignSelf='center'
                    direction='column' flex style={{maxWidth:'1200px', margin:'0 auto', width:'100%'}}
                >
                    <SearchBox handler={(state:any) => this.updateFilterTerm(state.value)} />
                    <Box direction='row' pad='medium' gap='small'>
                        <CheckBox label='Show images' checked={this.state.showImages} onChange={(e:any) => this.setState({...this.state, showImages:e.target.checked})} />
                        <CheckBox label='Show descriptions' checked={this.state.showDescriptions} onChange={(e:any) => this.setState({...this.state, showDescriptions:e.target.checked})} />
                        <CheckBox label='Show Private Rooms' checked={this.state.showPrivateRooms} onChange={(e:any) => {this.setState({...this.state, showPrivateRooms:e.target.checked})}} />
                    </Box>
                    <Box ref={this.userDashboardRoomList} width='100%' margin={{top:'20px'}} flex wrap direction='row' align='start' alignContent='start' alignSelf='start' justify='center' >
                        {
                            this.state.filteredRooms.map((room:any,i:number) => {
                                if (((this.state.showPrivateRooms && room.roomVisibility === 'private') || room.roomVisibility === 'open') && !room.isArchived) {
                                    return <RoomCard key={i} room={room} isFav={true} showDescription={this.state.showDescriptions} showImage={this.state.showImages} domainName={this.searchDomainShortname(room.domainShortName, this.state.domains)} />
                                } else return false
                            })
                        }
                        {
                            this.missingBlocks(4 - (this.state.filteredRooms.length % 4)).map((block) => {
                                return block
                            })
                        }
                    </Box>
                </Box>
            </Box>
        )
    }
}
