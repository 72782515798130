import React, { Component, CSSProperties } from 'react'
import { Box, Button, Text, Image, Heading, Layer } from 'grommet';
import Gauge from 'react-svg-gauge';
import { Close, Lock, StopFill, PlayFill } from 'grommet-icons';
import logo from '../../logo.svg'


class BigScreen extends Component<
{
    handler:(state:any) => void,
    room:{
        active?:boolean,appFee?:string,biddingSteps?:number[],closingMessage?:string,
        coverImage?:string,currency?:string,description?:string,endDate?:string,
        isAuto?:boolean,isClosed?:boolean,isConfig?:boolean,isFree?:boolean,
        isInverted?:boolean,name?:string,organisations?:string[],roomTotal?:number,
        roomVisibility?:string,shortName?:string,sponsor?:string[],startDate?:string,
        type?:string,visibleWhenClosed?:boolean,uid?:string
    },
    lots:{
        title: string, shortDesc: string, longDesc: string, deliveryType: number,
        deliveryDetails?:string, lotCategory:number, startAmount: number|undefined, currentAmount: number,
        lastBidder: string, room: string, coverImage: string, mediaList: string[],
        active: boolean, closed: boolean, attachments: string[], deliveryDate: string,
        hasProblem: boolean, problemsId: string[], isScam: boolean, appFee: string,
        isFree: boolean, uid?:string,
    }[],
    visible:boolean
}, {
    visibility:boolean
}
> {
    roomID:string | undefined;

    constructor(props:any) {
        super(props)

        this.state = {
            visibility:false
        }
    }

    componentWillReceiveProps(nextProps:any) {
        if (nextProps.visible !== this.state.visibility) this.setState({visibility:nextProps.visible})
    }

    componentDidUpdate() {
        this.props.handler(this.state.visibility)
    }

    closebuttonStyle:CSSProperties = {
        position: 'fixed',
        top: '16px',
        right: '16px'
    }
    
    roomStatusStyle:CSSProperties = {
        position: 'fixed',
        top: '16px',
        right: '64px'
    }

    logoStyle:CSSProperties = {
        position: 'fixed',
        top: '25px',
        textAlign: 'center',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        maxWidth: '450px'
    }

    topStyle:CSSProperties = {
        position: 'fixed',
        top: '100px',
        textAlign: 'center',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%'
    }
    
    midStyle:CSSProperties = {
        position: 'fixed',
        top: '275px',
        textAlign: 'center',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%'
    }
    
    footerStyle:CSSProperties = {
        position: 'fixed',
        bottom: '10px',
        textAlign: 'center',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%'
    }

    render() {
        const { room, lots } = this.props;

        return (
            <>
            { !!this.state.visibility &&
                <Layer modal={false} >
                    <Box style={{position:'absolute', top:0, right:0, bottom:0, left:0 }} background='light-1' pad='medium' gap='medium'>
                        <Box style={this.closebuttonStyle}>
                            <Button color='dark-1' onClick={() => {this.setState({visibility:false})}}>
                                <Box alignContent='center' align='center'>
                                    <Close />
                                </Box>
                            </Button>
                        </Box>
                        <Box style={this.roomStatusStyle}>
                            {!room.active && 
                                <Box align='center' alignContent='center' alignSelf='center'>
                                    <StopFill color='accent-4' size='small' />
                                    <Text size='small' color='accent-4'>Inactive</Text>
                                </Box>
                            }
                            {room.active && !room.isClosed && 
                                <Box align='center' alignContent='center' alignSelf='center'>
                                    <PlayFill color='accent-1' size='small' />
                                    <Text size='small' color='accent-1'>Active</Text>
                                </Box>
                            }
                            {room.active && room.isClosed && 
                                <Box align='center' alignContent='center' alignSelf='center'>
                                    <Lock color='accent-2' size='small' />
                                    <Text size='small' color='accent-2'>Closed</Text>
                                </Box>
                            }
                        </Box>
                        <Box style={this.logoStyle} direction="row" pad='small' >
                            <Box style={{margin:'0 auto'}} direction='row'>
                                <Image src={logo} height='48' alignSelf='center'/>
                                <Box pad={{left:'small', bottom:'xxsmall', top:'xxsmall'}}>
                                    <Text style={{fontFamily:"Open Sans", color:"#00BEFF", fontWeight:600}} size='30px'>millionbids</Text>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={this.topStyle} direction="column" pad='small' align='center' alignContent='center' alignSelf='center'>
                            <Heading margin='small' size='medium'>{room.name}</Heading>
                            <Box direction='row' align='center' alignContent='center' alignSelf='center'>
                                <Heading margin='small' size='large'>{room.roomTotal}</Heading>
                                <Text size='large'>{room.currency}</Text>
                            </Box>
                        </Box>
                        <Box background='light-2' color='light-1' style={this.midStyle} pad='small' align='center' alignContent='center' alignSelf='center'>
                            <Box direction='row' style={{width:'100%', maxWidth:'768px'}} align='center' alignContent='center' alignSelf='center'>
                                <Box width='300px'>
                                    <Gauge  
                                        label=''
                                        width={300}
                                        height={240}
                                        color='rgb(0, 190, 255)'
                                        topLabelStyle={{display:'none'}}
                                        valueLabelStyle={{display:'none'}}
                                        minMaxLabelStyle={{display:'none'}}
                                    />
                                </Box>
                                <Box direction='row' pad='small' align='stretch' alignContent='start' alignSelf='stretch' style={{flexGrow:1}}>
                                    <Box style={{flexGrow:1}} gap='small'>
                                        {
                                            lots.sort((a,b) => (a.currentAmount > b.currentAmount) ? 1 : ((b.currentAmount > a.currentAmount) ? -1 : 0)).slice(0,5).map((lot,index) => 
                                                <Box direction='row' width='100%'>
                                                    <Text size='large' weight='bold'>{index + 1}. {lot.title}</Text>
                                                    <Box style={{flexGrow:1}}></Box>
                                                    <Text size='large' weight='bold'>{lot.currentAmount}</Text>
                                                    <Text size='large' weight='bold'>{room.currency}</Text>
                                                </Box>
                                            )
                                        }
                                        <Box style={{flexGrow:1}}></Box>
                                        <Button alignSelf='start' label='See all lots' />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {
                            !!room.sponsor &&
                            <Box style={this.footerStyle} align='center' alignContent='center' alignSelf='center' gap='medium'>
                                <Text>sponsors</Text>
                                <Box direction='row' gap='medium'>
                                    {
                                        room.sponsor.map((sponsor,index) => 
                                            <Image key={index} src={sponsor} height='75px' width='75px' />
                                        )
                                    }
                                </Box>
                            </Box>
                        }
                    </Box>
                </Layer>
            }
            </>
        )
    }
}

export default BigScreen