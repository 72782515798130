import React from 'react'
import { Box, CheckBox, Text } from 'grommet';
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import { AuthUserContext } from '../Session';
import { Lot } from '../Lot';


export default class Bids extends React.Component<any,any> {

    static contextType = AuthUserContext

    constructor (props:any) {
        super(props)

        this.state = {
            bids:[],
            lots:[],
            showPictures:true
        }
    }

    componentDidMount() {
        if (!!this.context.userID) {
            firebase.firestore().collection('userProfiles').where('user', '==', this.context.userID ).onSnapshot((querySnapshot) => {
                    querySnapshot.forEach((profile) => {
                        this.setState({...this.state, bids:profile.data().bids})
                        if (profile.data().bids !== undefined) {
                            profile.data().bids.forEach((bid:string) => {
                                firebase.firestore().collection('lots').doc(bid).onSnapshot((lot) => {
                                    firebase.firestore().collection('rooms').doc(lot.data()!.room).onSnapshot((room) => {
                                        if (
                                            !!room.data() &&
                                            (
                                                new Date().getTime() - (1*24*60*60*1000) >= new Date(room.data()!.endDate).getTime() ||
                                                !room.data()!.closed
                                            )
                                        ) {

                                            const auctionStatus = () => {
                                                const now = new Date()
                                                const startDate = new Date(room.data()!.startDate)
                                                const endDate = new Date(room.data()!.endDate)
                                        
                                                const auctionStatus = ():'ready'|'live'|'closed'|undefined => {
                                                    if (now <= startDate) return 'ready'
                                                    if (now >= startDate && startDate <= endDate) return 'live'
                                                    if (now >= startDate && startDate >= endDate) return 'closed'
                                                    return undefined
                                                }
                                        
                                                return auctionStatus()
                                            }

                                            const { lots } = this.state

                                            const removeLot = lots.map((l:any) => l.uid).indexOf(lot.id)

                                            ~removeLot && lots.splice(removeLot, 1)

                                            const lotRecord:any = {...lot.data(), uid:lot.id, status:auctionStatus(), bidSteps:room.data()!.biddingSteps,currency:room.data()!.currency};
                                            
                                            (this.context.userID !== lot.data()!.currentWinningUser ?
                                                this.setState({...this.state, lots:[...lots, lotRecord]}) :
                                                this.setState({...this.state, lots:[lotRecord, ...lots]})
                                            )
                                        }
                                    })
                                })
                            })
                        }
                    })
                })
            }
    }

    componentWillUnmount() {
        firebase.firestore().collection('userProfiles').where('user', '==', this.context.userID ).onSnapshot(() => {})
    }



    render() {
        return (
            <Box overflow='auto'>
                <Box
                    align='center' pad='medium' gap='medium' alignContent='start' alignSelf='center'
                    direction='column' flex style={{maxWidth:'1200px', margin:'0 auto', width:'100%'}}
                >
                    {
                        !!this.state.bids && this.state.bids !== undefined &&
                        <>
                            <Box pad='medium' direction='row' gap='10px'>
                                <CheckBox label='Show images' checked={this.state.showPictures} onChange={(e:any) => this.setState({...this.state, showPictures:e.target.checked})} />
                                <CheckBox label='Show descriptions' checked={this.state.showDescriptions} onChange={(e:any) => this.setState({...this.state, showDescriptions:e.target.checked})} />
                            </Box>

                            <Box width='100%' margin={{top:'20px'}} flex wrap direction='row' align='start' alignContent='start' alignSelf='start' justify='center' >
                                { this.state.lots.map((lot:any, i:number) => (
                                        <Lot 
                                            key = {i}
                                            bidSteps = {lot.bidSteps}
                                            currency = {lot.currency}
                                            uid = {lot.uid}
                                            title = {lot.title}
                                            shortDesc = {lot.shortDesc}
                                            longDesc = {lot.longDesc}
                                            deliveryType = {lot.deliveryType}
                                            lotCategory = {lot.lotCategory}
                                            startAmount = {lot.startAmount}
                                            currentAmount = {lot.currentAmount}
                                            lastBidder = {lot.lastBidder}
                                            room = {lot.room}
                                            coverImage = {lot.coverImage}
                                            mediaList = {lot.mediaList}
                                            active = {lot.active}
                                            closed = {lot.closed}
                                            attachments = {lot.attachments}
                                            deliveryDate = {lot.deliveryDate}
                                            hasProblem = {lot.hasProblem}
                                            problemsId = {lot.problemsId}
                                            isScam = {lot.isScam}
                                            currentWinningUser = {lot.currentWinningUser}
                                            showPictures = {this.state.showPictures}
                                            showDescription = {this.state.showDescriptions}
                                            status= {lot.status}
                                        />
                                    )
                                )}
                            </Box>
                        </>
                    }
                    {
                        this.state.bids === undefined && <Text>These are not the bids you are looking for...</Text>
                    }
                </Box>
            </Box>
        )
    }
}
