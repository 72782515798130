import React, { Component } from 'react'
import { Box, Text } from 'grommet';
import * as ROUTES from '../../constants/routes'
import { withRouter } from "react-router-dom";
import Switch from "react-switch";
import { AuthUserContext } from '../Session';

class DashboardSwitch extends Component<any, any> {
    constructor(props:any) {
        super(props)

        this.state = {
            checked:false
        }

        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        if (this.state.checked !== this.props.checked) {
            this.setState({...this.state, checked:this.props.checked})
        }     
    }

    componentDidUpdate() {
        if (this.state.checked !== this.props.checked) {
            this.setState({...this.state, checked:this.props.checked})
        }
    }

    onChange = (checked:boolean, accessLevels:{domains?:any[], organizations?:any[], rooms?:any[]}) => {
        console.log(accessLevels)
        if (checked !== this.state.checked) {
            this.setState({...this.state, checked}, () => {
                this.props.history.push((this.state.checked ? `${ROUTES.DASHBOARD}r/${Object.keys(accessLevels.rooms![0])[0]}` : ROUTES.USER_DASHBOARD ))
            })
        }
    }

    displaySwitch = (userID:string, accessLevels:{domains?:any[], organizations?:any[], rooms?:any[]}):JSX.Element => {
        const { checked } = this.state
        return (
            <Box align='center' alignContent='center' alignSelf='center' pad='small' style={{flex:1, minWidth:'max-content', width:'100%', pointerEvents: 'none'}}>
                <Box direction='row' gap='small' style={{pointerEvents: 'auto'}}>
                    <Text>Auctions</Text>
                    <Switch 
                        height={16} width={36} handleDiameter={18}
                        offColor='#CCC' onColor='#CCC'
                        offHandleColor='#00bbef' onHandleColor='#00bbef'
                        uncheckedIcon={false} checkedIcon={false}
                        checked={checked} onChange={(e) => this.onChange(e, accessLevels)} />
                    <Text>Admin</Text>
                </Box>
            </Box>
        )
    }

    render() {
        return (
        <AuthUserContext.Consumer>
            { (authUser) => 
                !!authUser.authUser && !!authUser.accessLevels.rooms![0] &&
                    this.displaySwitch(authUser.userID, authUser.accessLevels)
            }
        </AuthUserContext.Consumer>
        )
    }
}

export default withRouter(DashboardSwitch)