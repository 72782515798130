import React, { CSSProperties } from 'react';
import { Box, Text, Grommet, Button, Drop, TextInput } from 'grommet';
import { Route, Switch, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import * as firebase from "firebase/app";
import 'firebase/auth'
import { theme } from '../../theme'
import axios from 'axios';
import { API } from '../../constants/api';
import Room from './Room';
import { addDomain, setAccess, addOrganization, addRoom } from '../../store/actions/dashboardActions';
import ErrorBoundary from '../ErrorBoundary';
import  * as ROUTES from '../../constants/routes'
import CardList from '../CardList';
import { AddCircle, Add, CaretNext, CaretPrevious, Close } from 'grommet-icons';
import './Dashboard.scss'
import Navigation from '../Navigation';
import { withAuthentication, withAuthorization, AuthUserContext } from '../Session';
import DashboardSwitch from '../DashboardSwitch';
import LogoDisplay from '../../logoDisplay';
import Cause from './Cause';

interface DashboardState {
    width:number
    height:number
    menuOpen:boolean
    userID:string,
    accessLevels: {
        domains?:{}[],
        organizations?:{}[],
        rooms?:{}[]
    },
    domains:any[],
    organizations:any[],
    rooms:any[]
    roomAdd:boolean
    roomAddField:string
    canAddRoom:boolean
    causes:any[]
    causeAdd:boolean
    causeAddField:string
    canAddCause:boolean
}


class Dashboard extends React.Component<any, DashboardState> {
    
    constructor(props:any) {
        super(props)

        this.state={
            width:0,
            height:0,
            menuOpen:true,
            userID:'',
            accessLevels:{},
            domains:[],
            organizations:[],
            rooms:[],
            roomAdd:false,
            roomAddField:'',
            canAddRoom:false,
            causes:[],
            causeAdd:false,
            causeAddField:'',
            canAddCause:false
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    roomsMenu:any;
    causesMenu:any;

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions() {
        this.setState({...this.state, width: window.innerWidth, height: window.innerHeight});
    }
    
    isEmpty = (obj:Object) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        firebase.auth().onAuthStateChanged((user) => { if (!!user) {
            axios.get(`${API.users}/passport/${user.uid}`)
                .then((userData) => {
                    this.setState({...this.state, userID:userData.data.uid})
                    axios.get(`${API.users}/${this.state.userID}/profile/`)
                        .then((profile) => {
                            const { accessLevels} = profile.data
                            this.setState({...this.state, accessLevels})
                            accessLevels.domains.forEach((domain:any) => {
                                axios.get(`${API.domains}/${Object.keys(domain)[0]}`)
                                    .then((domainData) => {
                                        firebase.firestore().collection('causes').where('domain', '==', domainData.data.uid)
                                            .onSnapshot((querySnapshot) => {
                                                querySnapshot.forEach((cause) => {
                                                    this.setState({...this.state, causes:[...this.state.causes, {...cause.data(), uid:cause.id}]})
                                                })
                                            })
                                        this.setState({...this.state, domains:[...this.state.domains, domainData.data]})
                                        this.props.addDomain(domainData.data)
                                    })
                                    .catch((error:Error) => {throw error})
                            });
                            accessLevels.organizations.forEach((organization:any) => {
                                axios.get(`${API.organizations}/${Object.keys(organization)[0]}`)
                                    .then((organizationData) => {
                                        this.setState({...this.state, organizations:[...this.state.organizations, organizationData.data]})
                                        this.props.addOrganization(organizationData.data)
                                    })
                                    .catch((error:Error) => {throw error})
                            });
                            if (this.isEmpty(this.props.match.params)) {
                                this.props.history.push(`${ROUTES.DASHBOARD}r/${Object.keys(accessLevels.rooms[0])[0]}`)
                            }
                            accessLevels.rooms.forEach((room:any) => {
                                firebase.firestore().collection('rooms').doc(Object.keys(room)[0]).onSnapshot((roomData) => {
                                    if (this.state.rooms.some(room => roomData.id === room.uid)) {
                                        const rooms = [...this.state.rooms]
                                        console.log({...roomData.data(), uid:roomData.id})
                                        rooms.splice(rooms.findIndex(r => r.uid === roomData.id), 1)
                                        rooms.push({...roomData.data(), uid:roomData.id})
                                        this.setState({...this.state, rooms})
                                    } else this.setState({...this.state, rooms:[...this.state.rooms, {...roomData.data(), uid:roomData.id}]})
                                })
                            });
                            this.setState({...this.state, accessLevels})
                            this.props.setAccess(accessLevels)
                        })
                        .catch((error:Error) => {throw error})
                })
                .catch((error:Error) => {throw error})
        }});
    }

    checkRoomName = (evt:any) => {
        const searchName = (nameKey:string, myArray:any[]) =>{
            for (var i=0; i < myArray.length; i++) {
                if (myArray[i].name === nameKey) {
                    return myArray[i];
                }
            }
        }

        this.setState({...this.state, roomAddField:evt.target.value}, () => {
            const existingRoom = searchName(this.state.roomAddField, this.state.rooms)
            this.setState({...this.state, canAddRoom:(!existingRoom)})
        })
    }

    addRoom = () => {
        this.setState({...this.state, roomAdd:false, roomAddField:''})
        const room = {
            type: 'solo',
            name: this.state.roomAddField,
            domainShortName: this.state.domains[0].shortName,
            shortName: this.state.roomAddField.replace(/\s/g, '').toLowerCase(),
            description: '',
            organisations: this.state.rooms[0].organisations,
            biddingSteps: [100,500,1000],
            currency: 'EUR',
            closingMessage: '',
            startDate: '',
            endDate: '',
            roomTotal: 0,
            roomVisibility: 'open',
            active: false,
            isInverted: false,
            isAuto: true,
            isClosed: false,
            visibleWhenClosed: false,
            isFree: true,
            appFee: '1%',
            isConfig: false
        }
        axios.post(API.rooms, room)
            .then((room) => {
                const accessLevels = {...this.state.accessLevels}
                accessLevels.rooms!.push({[room.data.uid]:1})
                this.setState({...this.state, accessLevels})
                axios.put(`${API.users}/${this.state.userID}/profile/rights/`, accessLevels)
                .then(() => {
                    this.setState({...this.state, rooms:[...this.state.rooms, room.data]})
                    this.props.history.push(`${ROUTES.DASHBOARD}r/${room.data.uid}`)
                })
                .catch((error:Error) => {throw error})
            })
    }

    checkCauseName = (evt:any) => {
        const searchName = (nameKey:string, myArray:any[]) =>{
            for (var i=0; i < myArray.length; i++) {
                if (myArray[i].name === nameKey) {
                    return myArray[i];
                }
            }
        }

        this.setState({...this.state, causeAddField:evt.target.value}, () => {
            const existingCause = searchName(this.state.causeAddField, this.state.causes)
            this.setState({...this.state, canAddCause:(!existingCause)})
        })
    }

    addCause = () => {
        this.setState({...this.state, causeAdd:false, causeAddField:''})
        const cause = {
            name: this.state.causeAddField,
            domain: this.state.domains[0].uid,
            domainShortName: this.state.domains[0].shortName,
            shortName: this.state.causeAddField.replace(/\s/g, '').toLowerCase(),
            description: '',
            active: false,
            isFree: true,
            appFee: '1%',
            isConfig: false
        }
        firebase.firestore().collection('causes').add(cause)
            .then((doc) => {
                this.setState({...this.state, causes:[...this.state.causes, {...cause,uid:doc.id}]})
            })
    }

    containerStyle:CSSProperties = {
        flex:1
    }

    menuStyle:CSSProperties = {
        flex:1,
        height:'fit-content',
        zIndex:1,
        minHeight:'calc(100vh - 92px)'
    }
    
    contentStyle:CSSProperties = {
        flex:1,
        height:'fit-content',
        minHeight:'calc(100vh - 92px)'
    }

    render () {
        return (
            <ErrorBoundary>
                <AuthUserContext.Consumer>
                    {authUser =>
                        <Grommet theme={theme} style={{display:'block'}}>
                            <Navigation />
                            <Box className='header' direction='row' height='92px'>
                                <LogoDisplay />
                                <DashboardSwitch checked={true} />
                            </Box>
                            <Box className='content' background='light-2'
                                align='start' alignContent='start' alignSelf='start'
                                direction='row' flex style={{height:'-webkit-fill-available'}}
                            >
                                <Box 
                                    direction='row' background='light-2'
                                    style={{
                                        ...this.menuStyle,
                                        width:(this.state.menuOpen ? (this.state.width >= 512 ? '256px' : '100%') : '32px'),
                                        maxWidth:(this.state.menuOpen ? (this.state.width >= 512 ? '256px' : '100%') : '32px'),
                                        position:(this.state.width >= 512 ? 'relative' : 'absolute')
                                    }}
                                >
                                    <Box pad='medium' style={{paddingRight:0, display:(this.state.menuOpen ? 'flex' : 'none')}} flex background='brand'>
                                        <Box gap='small' margin={{bottom:'medium'}}>
                                            <Box direction='row' align='center' >
                                                <Text size='small' weight='bold' color='neutral-3' style={{textTransform:'uppercase'}}>Domains</Text>
                                                {/* <div style={{flex:1}} />
                                                <Button icon={<AddCircle size='18px' color='neutral-3' />} /> */}
                                            </Box>
                                            {this.state.domains && this.state.domains.map((domain, key) => 
                                                // <NavLink activeClassName='link-active' className='link' key={key} to={`${ROUTES.DASHBOARD}d/${domain.uid}`}>
                                                //     <Text>#</Text> <Text>{domain.name}</Text>
                                                // </NavLink>
                                                <Text key={key} color='white'>{domain.name}</Text>
                                            )}
                                        </Box>
                                        <Box gap='small' style={{display:'none'}} margin={{bottom:'medium'}}>
                                            <Box direction='row' align='center'>
                                                <Text size='small' weight='bold' color='neutral-3' style={{textTransform:'uppercase'}}>Organizations</Text>
                                                {/* <div style={{flex:1}} />
                                                <Button icon={<AddCircle size='18px' color='neutral-3' />} /> */}
                                            </Box>
                                            {this.state.organizations && this.state.organizations.map((organization, key) => 
                                                <NavLink activeClassName='link-active' className='link' key={key} to={`${ROUTES.DASHBOARD}o/${organization.uid}`}>
                                                    <Text>#</Text> <Text>{organization.name}</Text>
                                                </NavLink>
                                            )}
                                        </Box>
                                        <Box gap='small' margin={{bottom:'medium'}}>
                                            <Box direction='row' align='center' ref={(el:any) => this.causesMenu = el}>
                                                <Text size='small' weight='bold' color='neutral-3' style={{textTransform:'uppercase'}}>Causes</Text>
                                                <div style={{flex:1}} />
                                                <Button icon={<AddCircle size='18px' color='neutral-3' onClick={() => this.setState({...this.state, causeAdd:true })} />}  />
                                            </Box>
                                            {
                                                this.state.causeAdd &&
                                                <Drop
                                                    align={{top:'bottom'}}
                                                    target={this.causesMenu}
                                                    style={{zIndex:1002}}
                                                >
                                                    <Box pad='small' round='small' direction='row' gap='10px'>
                                                        <TextInput placeholder='Cause Name'
                                                            value={this.state.causeAddField}
                                                            onChange={(evt) => this.checkCauseName(evt)}
                                                        />
                                                        <Button primary label='Add' icon={<Add size='small' />} style={{padding:'6px'}} onClick={this.addCause} disabled={!this.state.canAddCause} />
                                                        <Button plain icon={<Close size='15px' />} onClick={() => this.setState({...this.state, causeAdd:false })} />
                                                    </Box>
                                                </Drop>
                                            }
                                            {this.state.causes && this.state.causes.sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.size > b.size) ? 1 : -1) : -1).map((cause, key) => 
                                                <NavLink activeClassName='link-active' className='link' key={key} onClick={() => {if(this.state.width <= 512) {this.setState({...this.state, menuOpen:false})}}} to={`${ROUTES.DASHBOARD}c/${cause.uid}`}>
                                                    <Text>#</Text> <Text>{cause.name}</Text>
                                                </NavLink>
                                            )}
                                        </Box>
                                        <Box gap='small' margin={{bottom:'medium'}}>
                                            <Box direction='row' align='center' ref={(el:any) => this.roomsMenu = el}>
                                                <Text size='small' weight='bold' color='neutral-3' style={{textTransform:'uppercase'}}>Rooms</Text>
                                                <div style={{flex:1}} />
                                                <Button icon={<AddCircle size='18px' color='neutral-3' onClick={() => this.setState({...this.state, roomAdd:true })} />}  />
                                            </Box>
                                            {
                                                this.state.roomAdd &&
                                                <Drop
                                                    align={{top:'bottom'}}
                                                    target={this.roomsMenu}
                                                    style={{zIndex:1002}}
                                                >
                                                    <Box pad='small' round='small' direction='row' gap='10px'>
                                                        <TextInput placeholder='Room Name'
                                                            value={this.state.roomAddField}
                                                            onChange={(evt) => this.checkRoomName(evt)}
                                                        />
                                                        <Button primary label='Add' icon={<Add size='small' />} style={{padding:'6px'}} onClick={this.addRoom} disabled={!this.state.canAddRoom} />
                                                        <Button plain icon={<Close size='15px' />} onClick={() => this.setState({...this.state, roomAdd:false })} />
                                                    </Box>
                                                </Drop>
                                            }
                                            {this.state.rooms && this.state.rooms.sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.size > b.size) ? 1 : -1) : -1).map((room, key) => {
                                                if (!room.isArchived) { return (
                                                    <NavLink activeClassName='link-active' className='link' key={key} onClick={() => {if(this.state.width <= 512) {this.setState({...this.state, menuOpen:false})}}} to={`${ROUTES.DASHBOARD}r/${room.uid}`}>
                                                        <Text>#</Text> <Text>{room.name}</Text>
                                                    </NavLink>
                                                )}}
                                            )}
                                        </Box>
                                    </Box>
                                    <Box
                                        background='brand'
                                        round={{size:'small',corner:'right'}} pad='10px' 
                                        style={{maxWidth:'max-content', maxHeight:'max-content', height:(this.state.menuOpen ? 'auto' : 'fit-content')}}
                                        onClick={() => this.setState({...this.state, menuOpen:!this.state.menuOpen})}
                                    >
                                        {
                                            this.state.menuOpen ?
                                            <CaretPrevious color='white' size='16px' />
                                            :
                                            <CaretNext color='white' size='16px' />
                                        }
                                    </Box>
                                </Box>
                                <Box style={this.contentStyle} background='light-2' pad={{top:'medium', right:'medium', bottom:'medium', left:(this.state.width >= 512 ? 'medium':'32px') }}>
                                    <Switch>
                                        <Route exact path={`${ROUTES.ADMIN}d`} component={() => <Box pad='medium'>Loading...</Box>} />
                                        <Route exact path={`${ROUTES.ADMIN}d/c/:causeID`} component={Cause}/>
                                        <Route exact path={`${ROUTES.ADMIN}d/r/:roomID`} component={Room}/>
                                        <Route exact path={`${ROUTES.ADMIN}d/o/:organizationID`} component={() => <Box>Organization</Box>}/>
                                        <Route exact path={`${ROUTES.ADMIN}d/d/:domainID`} component={() => <Box>Domain</Box>}/>
                                        <Route exact path={`${ROUTES.ADMIN}d/r/:roomID/card`} component={CardList}/>
                                        <Route component={() => <Box><Text>Please select a room to manage.</Text></Box>} />
                                    </Switch>
                                </Box>
                            </Box>
                        </Grommet>
                    }
                </AuthUserContext.Consumer>
            </ErrorBoundary>
        )
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        addDomain: (payload:any[]) => dispatch(addDomain(payload)),
        addOrganization: (payload:any[]) => dispatch(addOrganization(payload)),
        addRoom: (payload:any[]) => dispatch(addRoom(payload)),
        setAccess: (payload:{domains:any[],organizations:any[],rooms:any[]}) => dispatch(setAccess(payload))
    }
}

const condition = (authUser:any) => authUser

export default withAuthentication(connect(null, mapDispatchToProps)(withAuthorization(condition)(Dashboard)))
