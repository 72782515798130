import React from "react";
import { connect } from "react-redux";
import { Room } from "./room.type";
import { createRoom } from '../../store/actions/roomActions'

class RoomCreate extends React.Component {

    render () {
        return (
            <div>Toto</div>
        )
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        createRoom: (room:Room) => dispatch(createRoom(room))
    }
}

export default connect(null, mapDispatchToProps)(RoomCreate)