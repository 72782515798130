import React, { Component } from 'react'
import { Button, Box, Heading, Text } from 'grommet';
import { toggleLoginModal } from '../../store/actions/appActions';
import { connect } from 'react-redux';

interface DispatchProps {toggleLoginModal: (visibility:boolean, email:string, from?:'domain'|'room'|'home', room?:string,step?:number) => void}
type Props = DispatchProps
class UnauthorizedPage extends Component<Props, any> {

    openModal = () => {
        this.props.toggleLoginModal(true, '', 'home', undefined, 0)
    }

    render() {
        return (
            <Box width='100%' height='500px' align='center' alignContent='center' style={{
                background: 'linear-gradient(158deg, rgba(0,190,255,1) 0%, rgba(81,81,229,1) 100%)',
                position:'relative'
            }}>
                <Box 
                    style={{minWidth:'360px', position:'absolute', top:'50%', transform:'translateY(-50%)'}} 
                    align='center' alignSelf='center' width='calc((100%/3)*2)' background={{"dark": true, "opacity": true}} >
                    <Heading size='medium' textAlign='center'>403 Unauthorized</Heading>
                    <Text size='large' textAlign='center'>
                        Please login.
                    </Text>
                    <br />
                    <Button primary label='Login' onClick={this.openModal} fill={false}/>
                </Box>
            </Box>
        )
    }
}

const mapDispatchToProps = (dispatch:any):DispatchProps => {
    return {
        toggleLoginModal: (visibility:boolean, email:string, from?:'domain'|'room'|'home', room?:string, step?:number) => dispatch(toggleLoginModal(visibility, email, from, room, step))
    }
}

export default connect(null,mapDispatchToProps)(UnauthorizedPage);