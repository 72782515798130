import React from 'react';
import { Box, Text } from 'grommet';

interface Props {
    date:string
}

interface State {
    days: number
    hours: number
    min: number
    sec: number
}

class Countdown extends React.Component<Props,State> {
    interval: NodeJS.Timeout;

    constructor(props:Props) {
        super(props);

        this.state = {
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
        }
    
        this.interval = setInterval(() => {}, 1000)
    }

    componentDidMount() {
        // update every second
        this.interval = setInterval(() => {
            const date = this.calculateCountdown(this.props.date);
            date ? this.setState(date) : this.stop();
        }, 1000);
    }

    componentWillUnmount() {
        this.stop();
    }

    calculateCountdown(endDate:string) {
        let diff = (Date.parse(new Date(endDate).toString()) - Date.parse(new Date().toString())) / 1000;

        // clear countdown when date is reached
        if (diff <= 0) return false;

        const timeLeft = {
        years: 0,
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
        millisec: 0,
        };

        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
        timeLeft.years = Math.floor(diff / (365.25 * 86400));
        diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) { // 24 * 60 * 60
        timeLeft.days = Math.floor(diff / 86400);
        diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
        timeLeft.hours = Math.floor(diff / 3600);
        diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
        timeLeft.min = Math.floor(diff / 60);
        diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }

    stop() {
        clearInterval(this.interval);
    }

    addLeadingZeros(value:string) {
        value = String(value);
        while (value.length < 2) {
        value = '0' + value;
        }
        return value;
    }

    render() {
        const { days, hours, min, sec } = this.state;

        return (
        <Box direction='row' gap='small'>
            <Box direction='row' align='baseline' alignContent='end' alignSelf='end' gap='6px'>
                <Text weight='bold'>{this.addLeadingZeros(days.toString())}</Text>
                <Text size='small'>{days === 1 ? 'Day' : 'Days'}</Text>
            </Box>
            <Box direction='row' align='baseline' alignContent='end' alignSelf='end' gap='6px'>
                <Text weight='bold'>{this.addLeadingZeros(hours.toString())}</Text>
                <Text size='small'>Hours</Text>
            </Box>
            <Box direction='row' align='baseline' alignContent='end' alignSelf='end' gap='6px'>
                <Text weight='bold'>{this.addLeadingZeros(min.toString())}</Text>
                <Text size='small'>Min</Text>
            </Box>
            <Box direction='row' align='baseline' alignContent='end' alignSelf='end' gap='6px'>
                <Text weight='bold'>{this.addLeadingZeros(sec.toString())}</Text>
                <Text size='small'>Sec</Text>
            </Box>
        </Box>
        );
    }
}

export default Countdown;