class Helpers {
    public abbrNum = (number:number, decPlaces:number) => {
        decPlaces = Math.pow(10,decPlaces);
        const abbrev = [ "", "K", "M" ];
        
        let stringValue:string = ''
    
        abbrev.forEach((value, i) => {
            const size = Math.pow(10, (i)*3 )
            if (size <= number) {
                number = Math.round(number*decPlaces/size)/decPlaces;

                stringValue = number + value
            }
        })

        return stringValue;
    }
}

export default Helpers