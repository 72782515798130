import React from 'react';
import { render } from 'react-dom';
import './index.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './store'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import Dashboard from './components/Dashboard';
import NotFound from './components/NotFound';
import { StripeProvider } from 'react-stripe-elements';
import UserDashboard from './components/UserDashboard';
import Firebase from './components/Firebase';
import { AppProvider } from './components/Provider';
import SignInModal from './components/SignIn'
import UserProfile from './components/UserProfile';

const stripeAPIKey = (process.env.FIREBASE_CONFIG === 'PRODUCTION' ? 'pk_live_ZBuMEC2snIZPDkttyxaDAYZh001NHEYCBe' : 'pk_test_X4eWalrDKJ5LQMYUW93R0pKC000hOd02DR');

new Firebase()

const AppData = {name:'"millionbids"'}

render(
    <AppProvider value={AppData}>
        <Provider store={store}>
            <StripeProvider apiKey={stripeAPIKey}>
                <Router>
                    <SignInModal />
                    <UserProfile />
                    <Switch >
                        <Route exact path={ROUTES.LANDING} component={App} />
                        <Route exact path={ROUTES.E403} component={App} />
                        <Route exact path={ROUTES.USER_DASHBOARD} render={() => <UserDashboard section='rooms' />} />
                        <Route exact path={ROUTES.USER_BIDS} render={() => <UserDashboard section='bids' />} />
                        <Route path={ROUTES.ADMIN} component={Dashboard} />
                        <Route exact path={`/:domainShortName/:roomShortName`} render={() => <UserDashboard section='rooms' />} />
                        <Route component={NotFound} />
                    </Switch>
                </Router>
            </StripeProvider>
        </Provider>
    </AppProvider>,
    document.getElementById('root'),
);

serviceWorker.unregister();
