import React, { Component } from 'react'
import { Box, Text } from 'grommet';
import { RadialSelected } from 'grommet-icons';

interface Props {
    lotList:any[]
    currency:string
}

export default class BidsList extends Component<Props> {
    render() {
        return (
            <Box>
                {this.props.lotList.map((lot, i) => 
                    <Box key={i} background={i%2 ? 'white' : 'light-1'} direction='row' pad='8px' gap='8px' flex width='100%' align='center'>
                        {lot.currentAmount === lot.startAmount && 
                            <RadialSelected size='16px' color='dark-3' />
                        }
                        {lot.currentAmount !== lot.startAmount && 
                            <RadialSelected size='16px' color='brand' />
                        }
                        <Text>{lot.title}</Text>
                        <div style={{flex:1}} />
                        <Text>{lot.currentAmount} {this.props.currency}</Text>
                    </Box>
                )}
            </Box>
        )
    }
}
