import React, { CSSProperties } from 'react'
import { Box, Text, Image, FormField, Button, TextInput } from 'grommet';
import RichTextEditor from 'react-rte'
import * as firebase from "firebase/app";
import 'firebase/storage'
import Dropzone from "react-dropzone";
import { Add, User, Calendar, Clock, FormTrash, RadialSelected, Radial } from 'grommet-icons';
import * as Stripe from 'react-stripe-elements';
import DateTimePicker from 'react-datetime-picker'

const boxStyle:CSSProperties = {
    boxSizing: 'border-box',
    outline: 'none',
    fontWeight: 600,
    margin: 0,
    border: '1px solid rgba(255,255,255,0.33)',
    borderRadius: '6px',
    padding:'1em',
    width: '100%',
    background: 'rgba(255, 255, 253)',
    color: 'rgb(51, 51, 51)',
    maxWidth: '450px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
}

interface RoomDescriptionProps {description?:string}
interface RoomFieldProps {handler:(state:any) => void}

type RoomDescriptionMergedProps = RoomDescriptionProps & RoomFieldProps
export class RoomDescription extends React.Component<RoomDescriptionMergedProps,any> {  
    editorRef:any;

    constructor(props:any) {
        super(props)

        this.state = {
            editorState:(!!this.props.description ? RichTextEditor.createValueFromString(this.props.description,'html') : RichTextEditor.createEmptyValue()),
            editorContent:'<p></p>',
            toolong:false
        }

        this.editorRef = React.createRef()
    }

    componentWillReceiveProps(nextProps:any) {
        if (nextProps.description !== this.state.editorState.toString('html')) {
            this.setState({...this.state, editorContent:nextProps.description, editorState:RichTextEditor.createValueFromString(nextProps.description,'html')})
        }
    }

    componentDidUpdate() {
        this.props.handler(this.state)
    }

    updateEditorState = (editorState:any) => {
        const content = JSON.parse(this.state.editorState.toString('raw'))
        let total = 0
        content.blocks.forEach((block:any) => total += block.text.length)
        if (total <= 1000) {
            this.setState({...this.state, toolong: false, editorState, editorContent:editorState.toString('html')})
        } else this.setState({...this.state, toolong: true, editorState, editorContent:editorState.toString('html')})
    }

    render() {
        return (
            <>
                <Text size='large'>Add a description to your room</Text>
                <br />
                <Box style={boxStyle} pad='small' gap='small'>
                    <div className='rte'>
                        <RichTextEditor
                            ref={this.editorRef}
                            toolbarConfig={{
                                display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
                                INLINE_STYLE_BUTTONS:[
                                    {label: 'Bold', style: 'BOLD'},
                                    {label: 'Italic', style: 'ITALIC'},
                                    {label: 'Strikethrough', style: 'STRIKETHROUGH'},
                                    {label: 'Underline', style: 'UNDERLINE'},
                                ],
                                BLOCK_TYPE_DROPDOWN:[
                                    {label: 'Normal', style: 'unstyled'},
                                    {label: 'Heading', style: 'header-three'},
                                    {label: 'Sub-Heading', style: 'header-four'},
                                ],
                                BLOCK_TYPE_BUTTONS:[
                                    {label: 'UL', style: 'unordered-list-item'},
                                    {label: 'OL', style: 'ordered-list-item'},
                                    {label: 'Blockquote', style: 'blockquote'},
                                ]
                            }}
                            value={this.state.editorState}
                            onChange={this.updateEditorState}
                        />
                    </div>
                    {
                        this.state.toolong && <Text color='neutral-2'>Max 1000 characters.</Text>
                    }
                    {
                        !this.state.toolong && <Text>Max 1000 characters.</Text>
                    }
                    <Text size='small'>Note: The description will be visible by the bidders.</Text>
                </Box>
            </>
        );
    }
}

interface RoomCoverimageProps {src?:string, roomID:string}
type RoomCoverMergeProps = RoomCoverimageProps & RoomFieldProps
export class RoomCoverImage extends React.Component<RoomCoverMergeProps,any> {
    setRef: (ref: any) => void;
    file: any;
    constructor(props:RoomCoverMergeProps) {
        super(props)

        this.state = {
            imagePath:'',
            error:false
        }

        this.setRef = (ref) => {
            this.file = ref
        }
    }

    componentDidMount() {
        if (!this.props.src || this.props.src === '') {
            const canvas = document.createElement('canvas')
            canvas.width=600
            canvas.height=215
            const ctx = canvas.getContext('2d');

            if (!!ctx) {
                var gradient = ctx.createLinearGradient(0,0,600,240);
                gradient.addColorStop(0,"rgba(0,190,255,1)");
                gradient.addColorStop(1,"rgba(81,81,229,1)");
                ctx.fillStyle = gradient;
                ctx.fillRect(0,0,600,338);
            }
            const getFileBlob = (url:string, callback:(response:XMLHttpRequest["response"]) => void) => {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.addEventListener('load', function() {
                    callback(xhr.response);
                });
                xhr.send();
            };
            const imageHash = Math.random().toString(36).substr(2, 9)
            const ref = firebase.storage().ref().child(`${this.props.roomID}/${imageHash}`)
            const dataURL = canvas.toDataURL('image/png')
            
            getFileBlob(dataURL, blob => {
                ref.put(blob).then((snapshot) => {
                    snapshot.ref.getDownloadURL()
                        .then((imagePath:string) => {
                            this.setState({...this.state, imagePath})
                        })
                        .catch()
                })
            })
        } else this.setState({...this.state, imagePath:this.props.src})
    }

    componentDidUpdate() {
        this.props.handler(this.state.imagePath)
    }

    uploadImage = () => {
        if(!!this.file.files && this.file.files[0].size > 2000000){
            this.setState({...this.state, error:true})
            this.file.value = ''
        } else if (!!this.file.files){
            this.setState({...this.state, error:false})
            const imageHash = Math.random().toString(36).substr(2, 9)
            const ref = firebase.storage().ref().child(`${this.props.roomID}/${imageHash}`)
    
            ref.put(this.file.files[0])
            .then((snapshot) => {
                snapshot.ref.getDownloadURL()
                    .then((imagePath:string) => {
                        this.setState({...this.state, imagePath})
                    })
                    .catch()
                })
                .catch()
        }

    }

    render() {
        return (
            <>
                <Text size='large'>Change your cover image</Text>
                <br />
                <Text>Your image will be cropped to a maximum size of 600x200 pixel.</Text>
                <Box style={boxStyle} pad='small' gap='small'>
                    <Box>
                        <TextInput type='file' ref={this.setRef} accept='image/*' onChange={this.uploadImage} />
                        <Text size='small' weight={(this.state.error ? 'bold' : 'normal')} color={(this.state.error ? 'accent-2' : '')}>(max. 2Mb)</Text>
                        {/* <Button primary label='Submit' onClick={this.uploadImage} /> */}
                    </Box>
                    <Image style={{borderRadius:5}} src={this.state.imagePath} width='100%'/>
                    <br />                    
                    <Text size='small'>Note: The cover image will be visible by the bidders.</Text>
                </Box>
            </>
        )
    }
}

interface RoomVisibilityProps {visibility:'open'|'closed'|'private'|undefined}
type RoomVisibilityMergeProps = RoomVisibilityProps & RoomFieldProps
export class RoomVisibility extends React.Component<RoomVisibilityMergeProps,{visibility:'open'|'closed'|'private'|undefined, isClicked:boolean}> {
    constructor(props:any) {
        super(props)

        this.state = {
            visibility:undefined,
            isClicked:false
        }
    }

    componentDidMount() {
        if (!!this.props.visibility && this.props.visibility !== this.state.visibility) {
            this.setState({...this.state, visibility:this.props.visibility})
        }
    }

    componentDidUpdate() {
        this.props.handler(this.state)
    }

    render() {
        return (
            <>
                <Text size='large'>Choose the visibility of your room</Text>
                <br />
                <Box style={boxStyle} pad='small'>
                    <Box pad='16px' height='150px' width='100%' border={{side:'top', size:'small', color:'light-3'}} style={{position:'relative', cursor:'pointer'}} onClick={() => {this.setState({ visibility:'open', isClicked:true })}}>
                        <Box style={{position:"absolute", top:'50%', transform:'translateY(-50%)'}}>
                            {
                                this.state.visibility === 'open' ?
                                    <RadialSelected color='brand' size='32px'/>
                                    :
                                    <Radial color='brand' size='32px'/>
                            }
                        </Box>
                        <Box gap='small' direction='column' alignContent='start' style={{position:"absolute", top:'20px', left:'64px', bottom:'20px', right:'16px'}}>
                            <Text textAlign='start' color='brand' size='large' weight='bold'>Public Room</Text>
                            <Text textAlign='start' size='small'>
                                This room will be visible for everyone. Anyone will be able to bid on your auctions.<br />
                                The link to your room will be visible on the platform.
                            </Text>
                        </Box>
                    </Box>
                    {/* <Box pad='16px' height='150px' width='100%' border={{side:'top', size:'small', color:'light-3'}} style={{position:'relative', cursor:'pointer'}} onClick={() => {this.setState({ visibility:'closed', isClicked:true })}}>
                        <Box style={{position:"absolute", top:'50%', transform:'translateY(-50%)'}}>
                        {
                            this.state.visibility === 'closed' ?
                                <RadialSelected color='brand' size='32px'/>
                                :
                                <Radial color='brand' size='32px'/>
                        }
                        </Box>
                        <Box gap='small' direction='column' alignContent='start' style={{position:"absolute", top:'20px', left:'64px', bottom:'20px', right:'16px'}}>
                            <Text textAlign='start' color='brand' size='large' weight='bold'>Visible with a private link</Text>
                            <Text textAlign='start' size='small'>
                                This room will be accessible only with a private link. This type of room is intended for auctions held in a physical place (e.g.: a charity gala)<br />
                                The link will NOT be visible on the platform.
                            </Text>
                        </Box>
                    </Box> */}
                    <Box pad='16px' height='150px' width='100%' border={{side:'top', size:'small', color:'light-3'}} style={{position:'relative', cursor:'pointer'}} onClick={() => {this.setState({ visibility:'private', isClicked:true })}}>
                        <Box style={{position:"absolute", top:'50%', transform:'translateY(-50%)'}}>
                        {
                            this.state.visibility === 'private' ?
                                <RadialSelected color='brand' size='32px'/>
                                :
                                <Radial color='brand' size='32px'/>
                        }
                        </Box>
                        <Box gap='small' direction='column' alignContent='start' style={{position:"absolute", top:'20px', left:'64px', bottom:'20px', right:'16px'}}>
                            <Text textAlign='start' color='brand' size='large' weight='bold'>Private Room</Text>
                            <Text textAlign='start' size='small'>
                                Only invited people will be able to access and place bids on your auctions.<br />
                                Each person you invite will recieve a unique link.<br />
                                (You may want to use this type to invite your club members)
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </>
        )
    }
}

interface RoomManualAutomaticProps {isAuto:boolean}
type RoomManualAutomaticMergeProps = RoomManualAutomaticProps & RoomFieldProps
export class RoomManualAutomatic extends React.Component<RoomManualAutomaticMergeProps,{mode:'manual'|'automatic'|undefined}> {
    constructor(props:any) {
        super(props)

        this.state = {
            mode:undefined
        }
    }

    componentDidMount() {
        const mode = (this.props.isAuto ? 'automatic' : 'manual')
        if (!!mode && mode !== this.state.mode) {
            this.setState({...this.state, mode})
        }
    }

    componentDidUpdate() {
        this.props.handler(this.state.mode)
    }

    render() {
        return (
            <>
                <Text size='large'>Manual or Automatic room?</Text>
                <br />
                <Box style={boxStyle} pad='small' gap='small'>
                    <Box height='150px' width='100%' border={{side:'top', size:'small', color:'light-3'}} onClick={() => {this.setState({ mode:'automatic' })} } style={{position:'relative', cursor:'pointer'}}>
                        <Box style={{position:"absolute", top:'50%', transform:'translateY(-50%)'}}>
                            {
                                this.state.mode === 'automatic' ?
                                    <RadialSelected color='brand' size='32px'/>
                                    :
                                    <Radial color='brand' size='32px'/>
                            }
                        </Box>
                        <Box gap='small' direction='column' alignContent='start' style={{position:"absolute", top:'20px', left:'64px', bottom:'20px', right:'16px'}}>
                            <Text color='brand' textAlign='start' size='large' weight='bold'>Automatic room</Text>
                            <Text textAlign='start' size='small'>
                                The room is automatically opened and closed on a date chosen by you.<br />
                                Note: when the auction have started, you will not be able to updates the starting and closing dates.
                            </Text>
                        </Box>
                    </Box>
                    <Box height='150px' width='100%' border={{side:'top', size:'small', color:'light-3'}} onClick={() => {this.setState({ mode:'manual' })} } style={{position:'relative', cursor:'pointer'}}>
                        <Box style={{position:"absolute", top:'50%', transform:'translateY(-50%)'}}>
                            {
                                this.state.mode === 'manual' ?
                                    <RadialSelected color='brand' size='32px'/>
                                    :
                                    <Radial color='brand' size='32px'/>
                            }
                        </Box>
                        <Box gap='small' direction='column' alignContent='start' style={{position:"absolute", top:'20px', left:'64px', bottom:'20px', right:'16px'}}>
                            <Text color='brand' textAlign='start' size='large' weight='bold'>Manual room</Text>
                            <Text textAlign='start' size='small'>
                                The room is opened and closed manually by you or a professional auctioneer.
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </>
        )
    }
}

interface RoomDatesProps {startDate:string, endDate:string}
type RoomDatesMergeProps = RoomDatesProps & RoomFieldProps
export class RoomDates extends React.Component<RoomDatesMergeProps,any> {
    todayPlusThreeDays = new Date(new Date(new Date().setDate(new Date().getDate() + 3)))
    
    constructor(props:any) {
        super(props)

        this.state = {
            startDate:this.todayPlusThreeDays,
            endDate:new Date(this.todayPlusThreeDays).setHours(new Date(this.todayPlusThreeDays).getHours() + 1),
        }
    }

    componentDidMount () {
        if (!!this.props.startDate && !!this.props.endDate) {
            const startISO = new Date(this.props.startDate).toISOString()
            const endISO = new Date(this.props.endDate).toISOString()
            const startDate = startISO.substring(0, startISO.indexOf('T') ) + 'T' + new Date(this.props.startDate).toLocaleTimeString()
            const endDate = endISO.substring(0, endISO.indexOf('T') ) + 'T' + new Date(this.props.endDate).toLocaleTimeString()
            if (startDate !== this.state.startDate) {
                this.setState({...this.state, startDate}, () => {
                    if (endDate !== this.state.endDate) {
                        this.setState({...this.state, endDate})
                    }
                })
            }
        }
    }

    componentDidUpdate() {
        this.props.handler(this.state)
    }

    onChange = (name:string ,value: any) => {
        this.setState({ ...this.state, [name]: new Date(value).toISOString() });
    };

    render() {
        
        return (
            <>
                <Text size='large'>Choose your start and end date/time</Text>
                <br />
                <Box style={boxStyle} pad='small' gap='small'>
                    <Box direction='row' alignContent='stretch' width='100%' border={{side:'top', size:'small', color:'light-3'}} pad='medium' gap='small'>
                        <Box direction='row' style={{minWidth:'64px', height:'48px'}}>
                            <Text size='large' weight='bold'>Start</Text>
                        </Box>
                        <FormField label={<><Text>Date</Text> <Calendar size='small' /> and <Text>Time</Text> <Clock size='small' /></>}>
                            <DateTimePicker value={new Date(this.state.startDate)} name='startDate' minDate={new Date(new Date().setDate(new Date().getDate()))} onChange={(e) => {this.onChange('startDate',e)}}/>
                            {/* <TextInput value={this.state.startDate} name='startDate' min={this.minStartDay} type='datetime-local' onChange={(e) => {this.onChange(e)}} /> */}
                        </FormField>
                    </Box>
                    <Box direction='row' alignContent='stretch' width='100%' border={{side:'top', size:'small', color:'light-3'}} pad='medium' gap='small'>
                        <Box direction='row' style={{minWidth:'64px', height:'48px'}}>
                            <Text size='large' weight='bold'>End</Text>
                        </Box>
                        <FormField label={<><Text>Date</Text> <Calendar size='small' /> and <Text>Time</Text> <Clock size='small' /></>}>
                            <DateTimePicker value={new Date(this.state.endDate)} minDate={new Date(new Date(this.state.startDate).setHours(new Date(this.state.startDate).getHours() + 1))} name='endDate' onChange={(e) => {this.onChange('endDate',e)}}/>
                            {/* <TextInput value={this.state.endDate} name='endDate' min={this.state.startDate} type='datetime-local' onChange={(e) => this.onChange(e)} /> */}
                        </FormField>
                    </Box>
                </Box>
            </>
        )
    }
}

interface RoomSponsorsProps {roomID:string, sponsors?:string[]}
type RoomSponsorsMergeProps = RoomSponsorsProps & RoomFieldProps
export class RoomSponsors extends React.Component<RoomSponsorsMergeProps,{sponsors:string[], error:boolean}> {
    constructor(props:any) {
        super(props)

        this.state = {
            sponsors:[],
            error:false
        }
    }

    componentDidMount() {
        if (!!this.props.sponsors && this.props.sponsors.length !== 0) {
            this.setState({...this.state, sponsors:[...this.props.sponsors]})
        }
    }

    componentDidUpdate() {
        this.props.handler(this.state.sponsors)
    }

    onDrop = (files:File[]) => {
        const imageHash = Math.random().toString(36).substr(2, 9)
        const ref = firebase.storage().ref().child(`${this.props.roomID}/sponsors/${imageHash}`)

        files.forEach((file) => {
            if(file.size > 512000){
                this.setState({...this.state, error:true})
            } else {
                ref.put(file)
                .then((snapshot) => {
                    snapshot.ref.getDownloadURL()
                        .then((url:string) => {
                            this.setState({
                                ...this.state,
                                sponsors:[...this.state.sponsors, url]})
                        })
                        .catch()
                    })
                    .catch()
            }
        });
    }

    render() {
        return (
            <>
                <Text size='large'>Add sponsors</Text>
                <br />
                <Box style={boxStyle} pad='small' gap='small'>
                    <Dropzone onDrop={acceptedFiles => this.onDrop(acceptedFiles)}>
                        {({getRootProps, getInputProps}) => (
                            <Box {...getRootProps()} round='small' border={{color:'brand', style:'dashed', size:'small'}} height='48px' pad='medium' style={{position:'relative'}}>
                                <input accept='image/*' {...getInputProps()} />
                                <Box direction='row' gap='small' style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)', alignItems:'center'}}>
                                    <Text size='large'>Add a logo</Text>
                                    <Add size='36px' />
                                    <Text weight={(this.state.error ? 'bold' : 'normal')} color={(this.state.error ? 'accent-2' : '')} size="small">(max. 512Kb)</Text>
                                </Box>
                            </Box>
                        )}
                    </Dropzone>
                    <Box direction='row' gap='small' pad='small' border={{color:'light-3', style:'dashed', size:'small'}} round='small' style={{minHeight:'78px'}}>
                        {this.state.sponsors && this.state.sponsors.map((sponsor:string) =>
                            <Box key={Math.random().toString()} direction='column' gap='small' alignContent='center' align='center'>
                                <Image src={sponsor} width='75px' />
                                <Button plain label='delete' icon={<FormTrash />} onClick={() => {
                                    const {sponsors} = this.state
                                    sponsors.forEach((item, i) => {
                                        if (item === sponsor) sponsors.splice(i, 1)
                                    })
                                    this.setState({sponsors})
                                }}/>
                            </Box>
                        )}
                    </Box>
                </Box>
            </>
        )
    }
}

class _RoomCCData extends React.Component<RoomFieldProps,any> {
    constructor(props:any) {
        super(props)

        this.state = {
            cardOwner: '',
            cardCountry: '',
            cardData:{
                number:'',
                expDate:'',
                CVC:''
            }
        }
    }

    fieldStyle:CSSProperties = {
        border: 'none',
        margin: '0px',
        padding: '0px',
        width: '1px',
        minWidth: '100%',
        overflow: 'hidden',
        display: 'block',
        fontSize: '1em',
        lineHeight: '1.2em',
        height: '1.2em',
    }

    componentDidUpdate() {
        this.props.handler(this.state)
    }

    render() {
        return (
            <Box style={boxStyle} pad='small' gap='small'>
                <Box direction='row' gap='small'>
                    <User size='16px' /><TextInput placeholder='Card Owner' name='cardOwner' style={this.fieldStyle} />
                </Box>
                <br />
                <Box>
                    <Stripe.CardElement/>
                </Box>
            </Box>
        )
    }
}

export const StripedRoomCCData =  Stripe.injectStripe(_RoomCCData);

export class RoomCCData extends React.Component<RoomFieldProps,any> {

    constructor(props:any) {
        super(props)

        this.state = {}

        this.submit = this.submit.bind(this);
    }

    async submit(ev:any) {
        console.log(ev)
    }

    componentDidUpdate() {
        this.props.handler(this.state)
    }

    render() {
        return (
            <>
                <Text size='large'>Add a Credit Card</Text>
                <br />
                <Box style={boxStyle} pad='small' gap='small'>
                    <Text>Your Credit Card will be used to credit your account after the auctions end.</Text>
                    <br />
                    <Stripe.Elements>
                        <StripedRoomCCData handler={(state) => {this.setState = state}}/>
                    </Stripe.Elements>
                    <br />
                    <Text size='small'>
                        Note: <br />
                        We do not store any bank information on our server.<br />
                        Your credit Card informations will be handled by our payment partner "Stripe".<br />
                        For more information, please visit <a href='https://www.stripe.com' rel="noopener noreferrer" target='_blank'>stripe.com</a>
                    </Text>
                </Box>
            </>
        )
    }
}
