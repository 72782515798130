import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { Box, TextInput, Button, Heading, Text } from 'grommet';
import * as firebase from 'firebase/app'
import 'firebase/auth'

const PasswordForgetPage = () => (
    <Box align='center' alignContent='center'>
        <Heading textAlign='center' size='small'>Reset your password</Heading>
        <PasswordForgetForm />
    </Box>
);

const INITIAL_STATE = {
    email: '',
    error: null,
};

type ForgotState = {
    email: string,
    error: any,
}

class PasswordForgetFormBase extends React.Component<any, ForgotState> {
    constructor(props: any) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = (event:any) => {
        const { email } = this.state;

        firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
            this.setState({ ...INITIAL_STATE });
        })
        .catch((error:any) => {
            this.setState({ error });
        });

        event.preventDefault();
    };

    onChange = (event:any) => {
        this.setState({ ...this.state, [event.target.name]: event.target.value });
    };

    render() {
        const { email, error } = this.state;

        const isInvalid = email === '';

        return (
            <Box alignContent='center' align='center'>
                <Text textAlign='center'>Fill in your email to receive a password reset link.</Text>
                <br />
                <TextInput
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Email Address"
                    style={{textAlign:'center'}}
                />
                <br/>
                <Button primary label='Reset My Password' disabled={isInvalid} onClick={this.onSubmit} />
                
                {error && <p>{error.message}</p>}
            </Box>
        );
    }
}

const PasswordForgetLink = () => (
    <p>
        <Link to={ROUTES.PASSWORD_FORGET}>I forgot my password.</Link>
    </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = PasswordForgetFormBase;

export { PasswordForgetForm, PasswordForgetLink };