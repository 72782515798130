import React from 'react'
import { Box, Image, Text, Heading } from 'grommet'
import logotype from '../../logotype.svg'
import { Facebook, Twitter, Linkedin } from 'grommet-icons'

interface Props {
    
}
interface State {
    
}

export default class Footer extends React.Component<Props, State> {
    state = {}

    render() {
        return (
            <Box className='footer' background='dark-2'>
                <Box direction='row' width='100%' style={{minWidth:'256px', maxWidth:'900px'}} flex wrap align='start' alignContent='start' alignSelf='center'>
                    <Box flex background='dark-2' pad={{vertical:'16px', horizontal:'16px'}} style={{minWidth:'256px'}}>
                        <Heading level='3' margin={{top:'0px'}}><Image src={logotype} height='21px' /></Heading>
                        {/* <Text>
                            Adress line 1
                        </Text>
                        <Text>
                            Adress line 2
                        </Text>
                        <Text>
                            CA 90210, Beverly Hills
                        </Text>
                        <Text>
                            United States of America
                        </Text> */}
                    </Box>
                    <Box flex background='dark-2' pad={{vertical:'16px', horizontal:'16px'}} style={{minWidth:'256px'}}>
                        <Heading level='3' margin={{top:'0px'}}>About</Heading>
                        <Text>Home</Text>
                        <Text>Privacy Policy</Text>
                        <Text>Terms & Conditions</Text>
                        <Text>Contact us</Text>
                    </Box>
                    <Box flex background='dark-2' pad={{vertical:'16px', horizontal:'16px'}} style={{minWidth:'256px'}}>
                        <Heading level='3' margin={{top:'0px'}}>Follow us on</Heading>
                        <Box direction='row' align='center' gap='8px'><Facebook size='small'/><Text>Facebook</Text></Box>
                        <Box direction='row' align='center' gap='8px'><Twitter size='small'/><Text>Twitter</Text></Box>
                        <Box direction='row' align='center' gap='8px'><Linkedin size='small'/><Text>LinkedIn</Text></Box>
                    </Box>
                </Box>
            </Box>
        )
    }
}
