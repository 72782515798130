import { Room } from "../../components/Room/room.type";

export const createRoom = ( room:Room ) => {
    return (dispatch: (action: { type: string; room: Room; }) => void, getState: any) => {
        dispatch({type: 'CREATE_ROOM', room})
    }
}
export const cupdateRoom = ( room:Room ) => {
    return (dispatch: (action: { type: string; room: Room; }) => void, getState: any) => {
        dispatch({type: 'UPDATE_ROOM', room})
    }
}
export const deleteRoom = ( room:Room ) => {
    return (dispatch: (action: { type: string; room: Room; }) => void, getState: any) => {
        dispatch({type: 'DELETE_ROOM', room})
    }
}