import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Text, Grommet, Box} from 'grommet';
import { Switch, Route, withRouter } from 'react-router-dom';
import { AuthUserContext, withAuthentication } from '../Session';
import ErrorBoundary from '../ErrorBoundary';
import Navigation from '../Navigation';
import { theme } from '../../theme'
import DashboardSwitch from '../DashboardSwitch';
import  * as ROUTES from '../../constants/routes'
import LogoDisplay from '../../logoDisplay';
import Rooms from './Rooms'
import { RoomPage } from '../Room';
import Bids from './Bids';
import Footer from '../Footer';

export class UserDashboard extends Component<any, any> {
    state = {
        rooms:[{}],
        visibility:false,
        email:'',
        from:undefined,
        room:undefined,
        step:0,
        section:'rooms'
    }

    componentDidUpdate () {
        if (this.state.section !== this.props.section) {
            this.setState({...this.state, section:this.props.section})
        }
    }

    componentDidMount () {
        if (this.state.section !== this.props.section) {
            this.setState({...this.state, section:this.props.section})
        }
    }

    navigateTo = (section:string) => {
        if (this.state.section !== section) {
            let route:string;
            if ( section === 'rooms') route = ROUTES.USER_DASHBOARD;
            if ( section === 'bids') route = ROUTES.USER_BIDS;
            this.setState({...this.state, section}, () => this.props.history.push(route))
        }
    }

    render() {
        return (
            <ErrorBoundary>
                <AuthUserContext.Consumer>
                    {authUser =>
                        <Grommet theme={theme} style={{display:'block'}}>
                            <Navigation />
                            <Box className='header' direction='row' height='92px'>
                                <LogoDisplay />
                                <DashboardSwitch checked={false} />
                            </Box>
                            <Box className='content'
                                align='center' alignContent='center' alignSelf='center'
                                direction='column' flex
                            >
                                <Box direction='row' width='280px' pad={{top:'20px'}}>
                                    <Box 
                                        flex align='center' alignContent='center' alignSelf='center'
                                        round={{corner:'left', size:'small'}}
                                        border={{color:'brand'}} 
                                        background={(this.state.section === 'rooms' ? 'brand' : 'white')}
                                        pad='10px' style={{cursor:'pointer'}}
                                        onClick={() => this.navigateTo('rooms')}
                                        >
                                        <Text color={this.state.section === 'rooms' ? 'white' : 'brand' }>Auction Rooms</Text>
                                    </Box>
                                    <Box 
                                        flex align='center' alignContent='center' alignSelf='center'
                                        round={{corner:'right', size:'small'}} 
                                        border={{color:'brand'}}
                                        background={(this.state.section === 'bids' ? 'brand' : 'white')}
                                        pad='10px' style={{cursor:'pointer'}}
                                        onClick={() => this.navigateTo('bids')}
                                    >
                                        <Text color={this.state.section === 'bids' ? 'white' : 'brand' }>My Bids</Text>
                                    </Box>
                                </Box>
                                <Switch>
                                    <Route exact path={`${ROUTES.USER_DASHBOARD}`} component={() => <Rooms embeded />} />
                                    <Route exact path={`${ROUTES.USER_BIDS}`} component={() => <Bids />}/>
                                    <Route exact path={`/:domainShortName/:roomShortName`} component={RoomPage} />
                                </Switch>
                            </Box>
                            <Footer />
                        </Grommet>
                    }
                </AuthUserContext.Consumer>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = (state:any) => ({
    
})

const mapDispatchToProps = {
    
}

export default withRouter(withAuthentication(connect(mapStateToProps, mapDispatchToProps)(UserDashboard)))
