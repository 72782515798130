import React from 'react';

import AuthUserContext from './context';
import { User } from 'firebase'
import * as firebase from 'firebase/app'
import 'firebase/auth'

type AuthUser = {
  authUser: User | null
  userID:string
  accessLevels:{domains?:any[], organizations?:any[], rooms?:any[]}
  profileID:string
  roomAccess:any[]
}

const withAuthentication = (Component: React.ComponentClass) => {
  class WithAuthentication extends React.Component<any, AuthUser> {
    
    listener: any;

    constructor(props:any) {
      super(props);

      this.state = {
        authUser: null,
        userID:'',
        accessLevels:{},
        profileID:'',
        roomAccess:[]
      };
    }

    componentDidMount() {
      this.listener = firebase.auth().onAuthStateChanged(
        (authUser: User|null) => {
          if (authUser) {
            firebase.firestore().collection('users').where('passport', '==', authUser.uid).get()
              .then((snapShot) => {
                snapShot.forEach((user) => {
                  firebase.firestore().collection('userProfiles').doc(user.data().profile).onSnapshot((profile) => {
                      this.setState({authUser, userID:user.id, profileID:profile.id, accessLevels:profile.data()!.accessLevels, roomAccess:profile.data()!.roomAccess})
                    })
                })
              })
          } else this.setState({ authUser:null, userID:'', accessLevels:{} })
        }
      )
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;