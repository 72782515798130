import React from 'react';
import { LotList } from '../Lot';
import { Lot } from '../Lot/lot.type';
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import { Room } from './room.type';
import { Box, Text, Button, Image, CheckBox } from 'grommet';
import { Close, RadialSelected } from 'grommet-icons';
import * as ROUTES from '../../constants/routes'
import { withRouter } from 'react-router';
import Countdown from '../Countdown';
import Interweave from 'interweave';
import { Link } from 'react-router-dom';

interface RoomPageState {
    lots:Lot[]
    room:Room
    section:'description'|'lots'
    showPictures:boolean
    showDescriptions:boolean
}
class RoomPage extends React.Component<any, RoomPageState> {

    constructor(props:any) {
        super(props)

        this.state = {
            lots:[],
            room:{} as Room,
            section:'lots',
            showPictures:true,
            showDescriptions:false
        }

        this.roomSnapshot.onSnapshot((querySnapshot) => {
            let room:Room
            querySnapshot.forEach((doc) => {
                room = {...doc.data() as Room, uid:doc.id}
                this.setState({...this.state, room}, () =>
                    firebase.firestore().collection('lots').where('room', '==', doc.id).onSnapshot((querySnapshot) => {
                        let lots:any[] = []
                        querySnapshot.forEach((doc) => {
                            let lot:Lot = {...doc.data() as Lot, uid:doc.id}
                            lots.push(lot)
                            this.setState({...this.state, lots})
                        })
                    })
                )
            })
        })
    }

    roomSnapshot = firebase.firestore().collection('rooms').where('shortName', '==', this.props.match.params.roomShortName).where('domainShortName', '==', this.props.match.params.domainShortName)

    componentDidMount() {
        this.roomSnapshot.onSnapshot((querySnapshot) => {
            let room:Room
            querySnapshot.forEach((doc) => {
                room = {...doc.data() as Room, uid:doc.id}
                this.setState({...this.state, room}, () =>
                    firebase.firestore().collection('lots').where('room', '==', doc.id).onSnapshot((querySnapshot) => {
                        let lots:any[] = []
                        querySnapshot.forEach((doc) => {
                            let lot:Lot = {...doc.data() as Lot, uid:doc.id}
                            lots.push(lot)
                            this.setState({...this.state, lots})
                        })
                    })
                )
            })
        })
    }


    componentWillUnmount() {
        if (!!this.state.room) {
            firebase.firestore().collection('lots').where('room', '==', this.state.room.uid).onSnapshot(() => {})
        }
    }

    auctionStatus = () => {
        const now = new Date()
        const startDate = new Date(this.state.room!.startDate)
        const endDate = new Date(this.state.room!.endDate)

        const auctionStatus = ():'ready'|'live'|'closed'|undefined => {
            if (this.state.room.isAuto) {
                if (now <= startDate) return 'ready'
                if (now >= startDate && startDate <= endDate) return 'live'
                if (now >= startDate && startDate >= endDate) return 'closed'
            } else {
                if (this.state.room.isConfig && !this.state.room.isClosed && !this.state.room.isStarted) return 'ready'
                if (this.state.room.isConfig && !this.state.room.isClosed && this.state.room.isStarted) return 'live'
                if (this.state.room.isConfig && this.state.room.isClosed) return 'closed'
            }
            return undefined
        }

        return auctionStatus()
    }
    
    okStatus = ():JSX.Element => {
        
        let color:string = ''
        let status:string = ''

        switch(this.auctionStatus()) {
            case 'ready': color = 'dark-3'; status = 'Auctions have not started yet'; break;
            case 'live': color = 'neutral-1'; status = 'Auctions are live'; break;
            case 'closed': color = 'dark-3'; status = 'Auctions are closed'; break;
            default: break;
        }

        if (!!color && !!status) {
            return (
                <Box direction='row' align='center' gap='small'>
                    <RadialSelected color={color} size='small' />
                    <Text color={color}>{status}</Text>
                </Box>
            )
        } else return <></>
        
    }

    render () {
        const { room } = this.state

        return (
            <>
            <Box
                align='center' pad='medium' alignContent='end' alignSelf='center'
                direction='column' background='light-1' flex
                style={{position:'fixed', height:'unset', width:'100%', top:0, bottom:0, zIndex:1002}}
                overflow='scroll'
            >
                <Box width='100%' style={{position:'absolute', top: '16px',right: '16px',width: '32px', zIndex:1003}}>
                    <Button color='dark-1' onClick={() => {this.props.history.push(ROUTES.USER_DASHBOARD)}}
                        style={{width: '32px', display: 'flex', alignSelf: 'flex-end'}}
                    >
                        <Box alignContent='center' align='center'>
                            <Close />
                        </Box>
                    </Button>
                </Box>
                {(!!room ?
                <Box align='center' alignContent='end' alignSelf='center' width='100%' gap='20px'
                direction='column' background='light-1' margin={{horizontal:'auto'}} flex style={{maxWidth:'900px', position:'absolute'}}>
                    <Box width='100%' border={{side:'bottom'}} gap='15px' pad='20px'>
                        <Text weight='bold' size='xlarge'>{room.name}</Text>
                        {this.okStatus()}
                        {
                            new Date() <= new Date(room.startDate) && 
                            <Box>
                                <Text size='small'>Starts in</Text>
                                <Countdown date={room.startDate} />
                            </Box>
                        }
                        {
                            new Date() >= new Date(room.startDate) && new Date(room.startDate) <= new Date(room.endDate) && 
                            <Box>
                                <Text size='small'>Closes in</Text>
                                <Countdown date={room.endDate} />
                            </Box>
                        }
                        {
                            new Date() >= new Date(room.startDate) && new Date(room.startDate) >= new Date(room.endDate) && 
                            <></>
                        }
                    </Box>
                    <Box
                        align='center' alignContent='center' alignSelf='center'
                        direction='row' flex width='100%' style={{maxWidth:'280px', margin:'0 auto', maxHeight:'fit-content'}}>
                        <Box 
                            flex align='center' alignContent='center' alignSelf='center'
                            round={{corner:'left', size:'small'}}
                            border={{color:'brand'}} 
                            background={(this.state.section === 'description' ? 'brand' : 'white')}
                            pad='10px' style={{cursor:'pointer'}}
                            onClick={() => this.setState({...this.state, section:'description'})}
                            >
                            <Text color={this.state.section === 'description' ? 'white' : 'brand' }>Description</Text>
                        </Box>
                        <Box 
                            flex align='center' alignContent='center' alignSelf='center'
                            round={{corner:'right', size:'small'}} 
                            border={{color:'brand'}}
                            background={(this.state.section === 'lots' ? 'brand' : 'white')}
                            pad='10px' style={{cursor:'pointer'}}
                            onClick={() => this.setState({...this.state, section:'lots'})}
                        >
                            <Text color={this.state.section === 'lots' ? 'white' : 'brand' }>List of lots</Text>
                        </Box>
                    </Box>
                    {this.state.section === 'description' && room &&  
                        <>
                        <Box height='200px' width='100%' round='small' overflow='hidden' style={{maxWidth:'600px', minHeight:'200px', background: 'linear-gradient(158deg, rgba(0,190,255,1) 0%, rgba(81,81,229,1) 100%)'}} >
                            <Image src={room.coverImage} fit='cover' />
                        </Box>
                        <Box margin={{top:'32px',bottom:'16px'}}>
                            <Interweave 
                                allowList={['strong', 'del', 'em', 'u', 'p', 'ul', 'ol', 'li', 'h3', 'h4', 'br']}
                                content={room.description}
                            />
                        </Box>
                            {
                                room.attatchments && room.attatchments.length >=1 && 
                                <>
                                    <Text>Attachments</Text>
                                    <Box direction='column'>
                                        {room.attatchments.map((attachmt:string, i) => 
                                            <Link to={attachmt}>{attachmt.replace(/^.*[\\/]/, '')}</Link>
                                        )}
                                    </Box>
                                </>
                            }
                            {
                                room.sponsor && room.sponsor.length >=1 && 
                                <>
                                    <Box border pad='8px' margin={{top:'16px',bottom:'16px'}}>
                                        <Text>Sponsors</Text>
                                    </Box>
                                
                                    <Box direction='row' wrap>
                                        {room.sponsor.map((s:string, i) => 
                                            <Image key={i} src={s} width='100px' height='100px' fit='contain' />
                                        )}
                                    </Box>
                                </>
                            }
                        </>
                    }
                    {this.state.section === 'lots' && room &&  
                        <>
                            <Box direction='row' gap='10px'>
                                <CheckBox label='Show images' checked={this.state.showPictures} onChange={(e:any) => this.setState({...this.state, showPictures:e.target.checked})} />
                                <CheckBox label='Show descriptions' checked={this.state.showDescriptions} onChange={(e:any) => this.setState({...this.state, showDescriptions:e.target.checked})} />
                            </Box>
                            <Box width='100%' margin={{top:'20px'}} flex wrap direction='row' align='start' alignContent='start' alignSelf='start' justify='center' >
                                <LotList status={this.auctionStatus()} showPictures={this.state.showPictures} showDescriptions={this.state.showDescriptions} lots={this.state.lots} bidSteps={room.biddingSteps} currency={room.currency}/>
                            </Box>
                        </>
                    }
                </Box>
                :
                <>
                    <Text>Loading, please wait.</Text>
                </>
                )}
            </Box>
            </>
        )
    }
}

export default withRouter(RoomPage)
