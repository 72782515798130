import React from "react";
import Lot from "./Lot";
import {Lot as LotType} from './lot.type'
import { Box, Text } from "grommet";

interface Props {
    lots: any[]
    bidSteps: number[]
    currency: string
    showPictures: boolean
    showDescriptions: boolean
    status: "ready" | "live" | "closed" | undefined
}
class LotList extends React.Component<Props> {
    lotList:any

    constructor(props:any) {
        super(props)

        this.lotList = React.createRef()
    }

    missingBlocks = (count:number) => {
        let blocks:JSX.Element[] = []
        if (count !== 3) {
            for (let i = 0; i < count ; i++) {
                blocks.push(<Box key={i} width='280px' margin='small' />)
            }
        }
        return blocks
    }

    render () {
        const { lots, bidSteps, currency, showPictures, showDescriptions, status } = this.props
        return (
            <Box ref={this.lotList} width='100%' margin={{top:'20px'}} flex wrap direction='row' align='start' alignContent='start' alignSelf='start' justify='center' >
            {lots.length === 0 && <Text>These are not the lots you are looking for...</Text>}
            {lots && lots.map((lot:LotType, i:number) => (
                <Lot 
                    key = {i}
                    bidSteps = {bidSteps}
                    currency = {currency}
                    uid = {lot.uid}
                    title = {lot.title}
                    shortDesc = {lot.shortDesc}
                    longDesc = {lot.longDesc}
                    deliveryType = {lot.deliveryType}
                    lotCategory = {lot.lotCategory}
                    startAmount = {lot.startAmount}
                    currentAmount = {lot.currentAmount}
                    lastBidder = {lot.lastBidder}
                    room = {lot.room}
                    coverImage = {lot.coverImage}
                    mediaList = {lot.mediaList}
                    active = {lot.active}
                    closed = {lot.closed}
                    attachments = {lot.attachments}
                    deliveryDate = {lot.deliveryDate}
                    hasProblem = {lot.hasProblem}
                    problemsId = {lot.problemsId}
                    isScam = {lot.isScam}
                    currentWinningUser = {lot.currentWinningUser}
                    showPictures = {showPictures}
                    showDescription = {showDescriptions}
                    status={status}
                />
            ))}
            {
                this.missingBlocks(4 - (lots.length % 4)).map((block) => {
                    return block
                })
            }
            </Box>
        )
    }
}

export default LotList