export const LANDING = '/';
export const SIGN_UP = '/signup/';
export const SIGN_UP_VALIDATE_TOKEN = '/signup/token/';
export const SIGN_IN = '/signin/';
export const ACCOUNT = '/account/';
export const ADMIN = '/admin/';
export const ROOM = '/room/';
export const PASSWORD_FORGET = '/pw-forget/';
export const VALIDATE_TOKEN = '/token/';
export const DASHBOARD = `${ADMIN}d/`;
export const USER_DASHBOARD = `/home/`;
export const USER_BIDS = `/home/my-bids`;
export const E403 = `/403`;
