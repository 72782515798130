import React, { createRef, Component } from "react";

import { Search } from "grommet-icons";
import { Box, Image, Text, TextInput } from "grommet";

interface State {
    value:string
    suggestionOpen:boolean
    suggestions:{name:string,imageUrl:string}[]
}

export default class SearchBox extends Component<any,State> {
  constructor(props:any) {
      super (props)
      this.state = { 
          value: "",
          suggestionOpen: false,
          suggestions: []
        };
  }

  boxRef:React.RefObject<any> = createRef();

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate() {
    this.props.handler(this.state)
  }

  onChange = (event:any) =>
    this.setState({ value: event.target.value }, () => {
      const { value } = this.state;
      if (!value.trim()) {
        this.setState({ suggestions: [] });
      } else {
        // simulate an async call to the backend
        setTimeout(() => this.setState({ suggestions: [] }), 300);
      }
    });

  onSelect = (event:any) => this.setState({ value: event.suggestion.value });

  renderSuggestions = () => {
    const { value, suggestions } = this.state;

    return suggestions
      .filter(
        ({ name }) => name.toLowerCase().indexOf(value.toLowerCase()) >= 0
      )
      .map(({ name, imageUrl }, index, list) => ({
        label: (
          <Box
            direction="row"
            align="center"
            gap="small"
            border={index < list.length - 1 ? "bottom" : undefined}
            pad="medium"
          >
            <Image
              width="48px"
              src={imageUrl}
              style={{ borderRadius: "100%" }}
            />
            <Text>
              <strong>{name}</strong>
            </Text>
          </Box>
        ),
        value: name
      }));
  };

  render() {
    const { suggestionOpen, value } = this.state;

    return (
        <Box
            ref={this.boxRef}
            width="280px"
            direction="row"
            align="center"
            margin={{top:'medium'}}
            pad={{ horizontal: "small", vertical: "xsmall" }}
            round="small"
            elevation={suggestionOpen ? "medium" : undefined}
            border={{
              side: "all",
              color: suggestionOpen ? "transparent" : "border"
            }}
            style={
              suggestionOpen
                ? {
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px"
                  }
                : undefined
            }
          >
            <Search color="brand" />
            <TextInput
              type="search"
              dropTarget={this.boxRef.current}
              plain
              value={value}
              onChange={this.onChange}
              onSelect={this.onSelect}
              suggestions={this.renderSuggestions()}
              placeholder="Search..."
              onSuggestionsOpen={() => this.setState({ suggestionOpen: true })}
              onSuggestionsClose={() =>
                this.setState({ suggestionOpen: false })
              }
            />
        </Box>
    );
  }
}