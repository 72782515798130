import React, { CSSProperties } from "react";
import * as ROUTES from '../../constants/routes';
import { withRouter } from "react-router-dom";
import PasswordForgetPage from "../PasswordForget";
import { Grommet, Text, TextInput, Button, Layer, Heading, Box } from "grommet";
import { connect } from "react-redux";
import { toggleLoginModal } from "../../store/actions/appActions";
import { FormPrevious } from 'grommet-icons'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import store from "../../store";
import { theme } from "../../theme";
import SignUpPage from "../SignUp";

export interface appState {
    visibility: boolean;
    email:string;
    from?:'domain'|'room'|'home';
    room?:string
    step?:number
}

// export interface OwnProps {email?:string, visibility:boolean, from?:'domain'|'room'|'home', room?:string, step?:number }
interface StateProps {email: string, password: string, error?: Error}
interface DispatchProps {toggleLoginModal: (visibility:boolean, email:string, from?:'domain'|'room'|'home', room?:string,step?:number) => void}

type Props = DispatchProps

class SignInModal extends React.Component<Props,appState> {
    unsubscribe: any;
    
    constructor(props:any) {
        super(props)

        this.state = {
            visibility:false,
            email:'',
            from:undefined,
            room:undefined,
            step:0
        }
    }

    componentDidMount() {
        this.unsubscribe = store.subscribe(this.handleStoreChange().bind(this))
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    handleStoreChange = () => () => {
        const {logInFormVisible,
            logInFormEmail,
            logInFormFrom,
            logInFormRoom,
            logInFormStep} = store.getState().app
        this.setState({
            ...this.state,
            visibility:logInFormVisible,
            email:logInFormEmail,
            from:logInFormFrom,
            room:logInFormRoom,
            step:logInFormStep
        })
    }

    //TODO: Refactor code to use memoization techniques or move to static getDerivedStateFromProps.
    // UNSAFE_componentWillReceiveProps (nextProps:any) {
    //     if (nextProps.visibility !== this.props.visibility || nextProps.visibility !== this.state.visibility ) {
    //         this.setState({...this.state, visibility:nextProps.visibility})
    //     }
    //     if (nextProps.visibility === this.props.visibility || nextProps.visibility === this.state.visibility ) {
    //         this.setState({...this.state},
    //             () => {
    //                 if (nextProps.step !== this.props.step || nextProps.step !== this.state.step) this.setState({...this.state, step:nextProps.step})
    //             })
    //     }
    // }
    
    boxVisible = (bool:boolean):CSSProperties => {
        const display:string = (bool ? 'flex' : 'none')
        return {display}
    }

    PasswordForgotten = () => <Button label='I forgot my password.' style={{textDecorationLine:'underline'}} plain onClick={()=> {this.props.toggleLoginModal(true,'',undefined,undefined,1)}} />
    SignUp = () => <Button label='I want to create an account.' style={{textDecorationLine:'underline'}} plain onClick={()=> {this.props.toggleLoginModal(true,'',undefined,undefined,2)}} />
    
    // SignUp = () => <Link style={{textDecorationLine:'underline'}} to={ROUTES.LANDING} onClick={()=> {this.props.toggleLoginModal(false,'',undefined,undefined,0)}}>I want to create an account.</Link>

    render () {
        return (
            <>
            {this.state.visibility && (
                <Grommet theme={theme}>
                    <Layer
                        style={{zIndex:1001}}
                        onEsc={() => {this.props.toggleLoginModal(false, '', undefined, undefined, 0)}}
                        onClickOutside={() => {this.props.toggleLoginModal(false, '', undefined, undefined, 0)}}
                    >
                        <Box margin='medium' width='394px' alignContent='center' align='center'>
                            <Box style={this.boxVisible(this.state.step !== 0)}>
                                <Button plain label='Back' icon={<FormPrevious />} onClick={()=> {this.setState({...this.state, step:0})}} />
                            </Box>
                            <Box margin='none' align='center' alignContent='center' style={this.boxVisible(this.state.step === 0)}>
                                <Heading textAlign='center' size='small'>Log in to your account</Heading>
                                <SignInForm email={this.state.email} from={this.state.from} room={this.state.room} />
                                <this.PasswordForgotten />
                                <this.SignUp />
                            </Box>
                            <Box margin='none' align='center' alignContent='center' style={this.boxVisible(this.state.step === 1)}>
                                <PasswordForgetPage />
                            </Box>
                            <Box margin='none' align='center' alignContent='center' style={this.boxVisible(this.state.step === 2)}>
                                <SignUpPage />
                            </Box>
                        </Box>
                    </Layer>
                </Grommet>
            )}
            </>
        );
    }
}

const INITIAL_STATE:StateProps = {
    email: '',
    password: '',
    error: new Error(),
};

export class SignInFormBase extends React.Component<any, StateProps> {
    constructor(props:any) {
        super(props);
    
        this.state = { ...INITIAL_STATE, email:this.props.email };
    }

    componentWillReceiveProps () {
        this.setState({...this.state, email:this.props.email})
    }
  
    onSubmit = (event:any) => {
        const { email, password } = this.state;
  
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.toggleLoginModal(false,'',undefined, undefined, 0)
                switch (this.props.from) {
                    case 'domain' : this.props.history.push(ROUTES.DASHBOARD); break;
                    case 'room' : this.props.history.push(this.props.room); break;
                    default: this.props.history.push(ROUTES.USER_DASHBOARD);
                }
            })
            .catch((error:any) => {
                this.setState({ error });
            });
  
        event.preventDefault();
    };
  
    onChange = (event:any) => {
      this.setState({ ...this.state, [event.target.name]: event.target.value });
    };

    onEmailChange = (event: any) => {
        this.setState({ ...this.state, email: event.target.value.toLowerCase() });
    };
  
    render() {
      const { email, password, error } = this.state;
  
      const isInvalid = password === '' || email === '';
  
      return (
        <Box alignContent='center' align='center'>
            <Text textAlign='center'>Please enter your email and password.</Text>
            <br />
            <TextInput
                style={{textAlign:'center'}}
                width='100%'
                name="email"
                value={email}
                onChange={this.onEmailChange}
                type="text"
                placeholder="Email Address"
            />
            <br />
            <TextInput
                style={{textAlign:'center'}}
                width='100%'
                name="password"
                value={password}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
            />
            <br /><br />
            <Button primary label='Log in' disabled={isInvalid} onClick={this.onSubmit}/>

            {error && <p>{error.message}</p>}
        </Box>
        );
    }
}

const mapDispatchToProps = (dispatch:any):DispatchProps => {
    return {
        toggleLoginModal: (visibility:boolean, email:string, from?:'domain'|'room'|'home', room?:string, step?:number) => dispatch(toggleLoginModal(visibility, email, from, room, step))
    }
}

export default connect<StateProps, DispatchProps>(null, mapDispatchToProps)(SignInModal)
export const SignInForm = connect(null,mapDispatchToProps)(withRouter(SignInFormBase));