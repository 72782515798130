import authReducer from './authReducer'
// import bidReducer from './bidReducer';
// import lotReducer from './lotReducer';
// import roomReducer from './roomReducer'
import { combineReducers } from 'redux'
import appReducer from './appReducer';
import dashboardReducer from './dashboardReducer';

const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    // bid: bidReducer,
    // lot: lotReducer,
    // room: roomReducer,
})

export default rootReducer