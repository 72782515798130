const initState = {
    domains:[{}],
    organizations:[{}],
    rooms:[{}],
    accessLevels: {
        domains:[{}],
        organizations:[{}],
        rooms:[{}]
    },
}

const dashboardReducer = (state = initState, action:{type:string, payload:any}) => {
    switch (action.type) {
        case 'ADD_DOMAIN':
            state.domains = [...state.domains, action.payload];
            break;
        case 'ADD_ORGANIZATION':
            state.organizations = [...state.organizations, action.payload];
            break;
        case 'ADD_ROOM':
            state.rooms = [...state.rooms, action.payload];
            break;
        case 'SET_ACCESS':
            state.accessLevels = {
                domains:[...state.accessLevels.domains, action.payload.domains],
                organizations:[...state.accessLevels.organizations, action.payload.organizations],
                rooms:[...state.accessLevels.rooms, action.payload.rooms],
            };
            break;
        default:
            break;
    }
    return state;
}

export default dashboardReducer;