import { Lot, Bid } from "../../components/Lot/lot.type";
import * as firebase from 'firebase/app'
import 'firebase/firestore'

export const createLot = ( lot:Lot ) => {
    return (dispatch: (action: { type: string; lot: Lot; }) => void, getState: any) => {
        dispatch({type: 'CREATE_LOT', lot})
    }
}
export const updateLot = ( lot:Lot ) => {
    return (dispatch: (action: { type: string; lot: Lot; }) => void, getState: any) => {
        dispatch({type: 'UPDATE_LOT', lot})
    }
}
export const deleteLot = ( lot:Lot ) => {
    return (dispatch: (action: { type: string; lot: Lot; }) => void, getState: any) => {
        dispatch({type: 'DELETE_LOT', lot})
    }
}
export const placeBid = ( bid:Bid ) => {
    return (dispatch: (action: { type: string; bid: Bid; }) => void, getState: any) => {
        firebase.firestore().collection('lots').doc(bid.lot)
            .set(
                {
                    currentWinningUser: bid.user,
                    currentAmount: firebase.firestore.FieldValue.increment(bid.bidValue),
                    bids:firebase.firestore.FieldValue.arrayUnion({user:bid.user, bidValue: bid.bidValue, bidDate: new Date().toISOString() })
                },{merge:true})
            .then(() => {
                firebase.firestore().collection('userProfiles').where('user','==',bid.user).get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((profile) => {
                            profile.ref.set({bids:firebase.firestore.FieldValue.arrayUnion(bid.lot)},{merge:true})
                                .then(() => dispatch({type: 'BID_PLACED', bid}))
                        })
                    })
            })
    }
}