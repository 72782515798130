import React from 'react';
import { User } from 'firebase'


const AuthUserContext = 
    React.createContext<{authUser:User|null, userID:string, profileID:string, accessLevels:{domains?:any[], organizations?:any[], rooms?:any[]}, roomAccess:any[]}>(
        {
            authUser:null,
            userID:'',
            profileID:'',
            accessLevels:{},
            roomAccess:[]
        }
    );

export default AuthUserContext;