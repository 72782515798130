import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Box } from 'grommet';
import { RoomCard } from '../Room';


interface Props {
    rooms:any[]
    match:any
}
interface State {
    loading:boolean
    rooms:any[]
}

class CardList extends Component<Props, State> {

    constructor(props:Props) {
        super(props)

        this.state = {
            loading:true,
            rooms:[]
        }
    }

    componentDidMount() {
        if (!!this.props.rooms) this.setState({...this.state, rooms:this.props.rooms})
        if (!!this.props.match.params.roomID) this.setState({...this.state, rooms:[this.props.match.params.roomID], loading:false})
        this.setState({...this.state, loading:false})
    }

    render() {
        return (
            <Box direction='row' flex='shrink' wrap pad='small' gap='small'>
                {
                    this.state.loading === false && this.state.rooms.map((room, index) => 
                        <RoomCard key={index} room={room} />
                    )
                }
            </Box>
        )
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {}
}

const mapStateToProps = (state:any) => {
    return { state };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardList)
